import { Button, Col, Row, Typography, Skeleton, Modal, Input } from "antd";
import { useState, useEffect } from "react";
import { toGetNotificationsList } from "./Api";
import cookie from "react-cookies";

import "../../Styles/TwoTables.scss";
import { toResultAuthorisationApi } from "../Vente/APIs";
import { errorMsg } from "../../Utils/Utils";
import DataTable from "react-data-table-component";

const Notifications = (props) => {
  const [loading, setLoading] = useState(true);
  const [visible, setIsVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [accept, setAccept] = useState(0);
  const [Notificationss, setNotificationss] = useState([]);
  const [password, setPassword] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    toGetDatas(0, 10);
  }, []);
  const toGetDatas = (page, perPage) => {
    toGetNotificationsList(page, perPage).then((res) => {
      setNotificationss(res.autorisations);
      setTotal(res.total);
      setLoading(false);
    });
  };
  const resultDemand = (clientId, userId, accept, password) => {
    toResultAuthorisationApi(clientId, userId, accept, password).then((res) => {
      if (res) {
        toGetDatas();
        setIsVisible(false);
      } else {
        errorMsg("Merci de vérifier le code");
      }
    });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    toGetDatas(page - 1, newPerPage);

    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    toGetDatas(page - 1, perPage);
  };

  const columns = [
    {
      name: "Date",
      selector: "date",
      sortable: true,
      grow: 1.5,
    },
    {
      name: "Vendeur",
      selector: "vendeur",
      sortable: true,
      grow: 1.5,
    },
    {
      name: "Client",
      selector: "client",
      sortable: true,
      grow: 1.5,
    },
    {
      name: "Validé/rejeté par",
      selector: "updated",
      sortable: true,
      grow: 1.5,
    },
    {
      name: "",
      selector: "updated",
      sortable: true,
      format: (item) => (
        <>
          {item.status === 0 && cookie.load("notification") == "true" && (
            <>
              <Button
                style={{
                  marginTop: "15px",
                  textAlign: "center",
                }}
                type="primary"
                size="small"
                onClick={() => {
                  setSelected(item);
                  setIsVisible(true);
                  setAccept(1);
                }}
              >
                Valider
              </Button>
              <Button
                style={{
                  marginLeft: "15px",
                  textAlign: "center",
                }}
                type="danger"
                size="small"
                onClick={() => {
                  setSelected(item);
                  setIsVisible(true);
                  setAccept(-1);
                }}
              >
                Refuser
              </Button>
            </>
          )}
        </>
      ),
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row.status == 1,
      style: {
        backgroundColor: "#58c5cc",
      },
    },
    {
      when: (row) => row.status == -1,
      style: {
        backgroundColor: "#ef5350",
      },
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "1rem",
      },
    },
  };

  return (
    <>
      <Modal
        title="Code"
        onCancel={() => setIsVisible(false)}
        onOk={() =>
          resultDemand(selected.clientId, selected.userId, accept, password)
        }
        visible={visible}
      >
        <Input.Password onChange={(e) => setPassword(e.target.value)} />
      </Modal>
      <>
        <Row>
          <Col span="12">
            <Typography.Title level="2">Notifications</Typography.Title>
          </Col>
        </Row>

        <Row
          style={{
            overflowY: "hidden",
          }}
        >
          <Col span="23">
            <DataTable
              conditionalRowStyles={conditionalRowStyles}
              customStyles={customStyles}
              noHeader
              striped
              fixedHeader
              persistTableHead
              noDataComponent={<span>Pas de bon de notifications trouvés</span>}
              contextMessage={{
                singular: "élément",
                plural: "éléments",
                message: "séléctionné(s)",
              }}
              columns={columns}
              data={Notificationss}
              pagination
              paginationTotalRows={total}
              paginationRowsPerPageOptions={[10, 20, 50, 100]}
              paginationServer
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </Col>
        </Row>
      </>
    </>
  );
};

export default Notifications;
