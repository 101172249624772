import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Typography,
  Skeleton,
  Select,
} from "antd";
import { useState, useEffect } from "react";
import {
  toGetCategoryList,
  toDeleteCategory,
  toAddOrUpdateCategory,
  toFusionCategory,
} from "./Api";
import { PlusOutlined } from "@ant-design/icons";

import { BiPencil, BiTrash } from "react-icons/bi";
import moment from "moment";
import { errorMsg, successMsg } from "../../Utils/Utils";
import localization from "moment/locale/fr";
import "../../Styles/TwoTables.scss";
import Rating from "../../Components/Rating";
import DataTable from "react-data-table-component";
import SearchInput from "./SearchInput";

moment.updateLocale("fr", localization);

const Category = () => {
  const [loading, setLoading] = useState(true);
  const [fusion, setFusion] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [Categorys, setCategorys] = useState([]);
  const [CategorysAll, setCategorysAll] = useState([]);
  const [category, setCategory] = useState("");
  const [titleTecdoc, setTitleTecdoc] = useState("");
  const [id, setId] = useState(-1);
  const [form] = Form.useForm();
  const [rating, setRating] = useState(3);

  const closeCreateModal = () => {
    if (category == "") {
      errorMsg("veuillez remplir tous les champs obligatoires!");
      return;
    }
    toAddOrUpdateCategory(category, titleTecdoc, id, rating).then((res) => {
      successMsg(
        id != -1
          ? "Category mis à jours avec succées!"
          : "Category ajoutée avec succées!"
      );
      toGetDatas();
    });
    setId(-1);
    setCreateModalVisible(false);
    setCategory("");
    setTitleTecdoc("");
    setRating(0);
  };

  useEffect(() => {
    toGetDatas();
  }, []);
  const toGetDatas = () => {
    toGetCategoryList().then((res) => {
      setCategorys(res);
      setCategorysAll(res);
      setLoading(false);
    });
  };
  const toDelete = (id) => {
    toDeleteCategory(id).then((res) => {
      toGetDatas();
    });
  };

  const toEdit = (id, category, titleTecdoc, rating) => {
    setId(id);
    setTitleTecdoc(titleTecdoc);
    setRating(rating);
    setCategory(category);
    form.setFieldsValue({ category: category, titleTecdoc: titleTecdoc });
    setCreateModalVisible(true);
  };
  const toAdd = () => {
    setId(-1);
    setCategory("");
    setTitleTecdoc("");
    form.setFieldsValue({ category: "", titleTecdoc: "" });

    setCreateModalVisible(true);
  };
  const exitCreateModal = () => {
    setId(-1);
    setCategory("");
    setTitleTecdoc("");
    setCreateModalVisible(false);
    setRating(0);
  };

  const columns = [
    {
      name: "Date de création",
      selector: "createdDate",
      sortable: true,
      grow: 1,
      format: (c) => moment(c.createdDate).format("D MMMM YYYY"),
    },
    {
      name: "Catégorie",
      selector: "title",
      sortable: true,
      grow: 2.5,
    },
    {
      name: "Catégorie Tecdoc",
      selector: "categoryTecdoc",
      sortable: true,
      grow: 2.5,
    },
    {
      name: "Crée par",
      selector: "createdBy",
      sortable: true,
      grow: 1,
    },
    {
      name: "",
      selector: "id",
      sortable: true,
      format: (Category) => (
        <>
          <Button
            onClick={() =>
              toEdit(
                Category.id,
                Category.title,
                Category.categoryTecdoc,
                Category.rating
              )
            }
            icon={<BiPencil />}
          />
          <Popconfirm
            title={`Voulez vous vraiment supprimer ${Category.title}?`}
            onConfirm={() => toDelete(Category.id)}
            okText="Oui"
            cancelText="Non"
          >
            <Button icon={<BiTrash />} />
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <Row className="auto--custom-header">
        <Col span="6">
          <Typography.Title level="2">Catégories</Typography.Title>
        </Col>
        <Col span={"6"}>
          <SearchInput
            list={CategorysAll}
            fields={["title", "categoryTecdoc"]}
            setData={(list) => setCategorys(list)}
          />
        </Col>
        <Col span="12" style={{ textAlign: "right" }}>
          <Button
            onClick={(e) => {
              setFusion(true);
              form.setFieldsValue({ parent: "", fils: [] });
            }}
            type="primary"
            size="large"
            style={{ marginLeft: "5px" }}
          >
            Fusionner des catégories
          </Button>
        </Col>
      </Row>

      <Row
        style={{
          overflowY: "hidden",
        }}
      >
        <Col span="24">
          <DataTable
            noHeader
            striped
            fixedHeader
            persistTableHead
            noDataComponent={<span>Pas de bon de caégories trouvés</span>}
            contextMessage={{
              singular: "élément",
              plural: "éléments",
              message: "séléctionné(s)",
            }}
            columns={columns}
            data={Categorys}
            pagination
            paginationTotalRows={Categorys?.length}
            paginationRowsPerPageOptions={[10, 20, 50, 100]}
          />
          <Skeleton active loading={loading} />
        </Col>
      </Row>

      <Modal
        title={"Fusion"}
        visible={fusion}
        onCancel={() => setFusion(false)}
        onOk={() =>
          toFusionCategory(form.getFieldsValue()).then((res) => {
            toGetDatas();
            setFusion(false);
            successMsg("Catégorie fusionnée avec succées");
          })
        }
      >
        <Form name="basic" form={form}>
          <Form.Item label="Catégorie Parent" name="parent">
            <Select showSearch>
              {null != Categorys &&
                Categorys.length > 0 &&
                Categorys.map((Category, i) => (
                  <Select.Option value={Category.title}>
                    {Category.title}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="Catégorie fils" name="fils">
            <Select mode="multiple">
              {null != Categorys &&
                Categorys.length > 0 &&
                Categorys.map((Category, i) => (
                  <Select.Option value={Category.title}>
                    {Category.title}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={
          id != -1 ? "Modifier une Category" : "Ajouter une nouvelle Category"
        }
        visible={createModalVisible}
        onOk={closeCreateModal}
        onCancel={exitCreateModal}
      >
        <Form name="basic" form={form}>
          <Form.Item
            label="Catégorie"
            name="category"
            rules={[{ required: true, message: "Champ obligatoire" }]}
          >
            <Input onChange={(e) => setCategory(e.target.value)} />
          </Form.Item>

          <Form.Item label="Catégorie tecdoc" name="titleTecdoc">
            <Input onChange={(e) => setTitleTecdoc(e.target.value)} />
          </Form.Item>
          <Form.Item label="Favoris" name="favoris">
            {rating}
            <Rating value={rating} onChange={setRating} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Category;
