import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Descriptions,
  Empty,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Skeleton,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
import localization from "moment/locale/fr";
import { useEffect, useState } from "react";
import { BiTrash } from "react-icons/bi";
import { connect } from "react-redux";
import { setItemsState } from "../../../Actions/ItemsSelectionAtions";
import Search from "../../../Utils/Search";
import {
  customFilter,
  numberFormatCurrency,
  toConvertDateWithoutTime,
} from "../../../Utils/Utils";
import { toDeleteReglement, toGetReglements, toPrintTraite } from "../APIs";

import { Badge } from "antd";
import FactureReceptionModal from "../Factures/FactureReceptionModal";

moment.updateLocale("fr", localization);

const ReglementsList = (props) => {
  const [reglements, setReglements] = useState([]);
  const [events, setEvents] = useState([]);
  const [reglementsAll, setReglementsAll] = useState([]);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [factureToInspect, setFactureToInspect] = useState(null);
  const [month, setMonth] = useState(moment().format("YYYY-MM"));
  const [filtredBanques, setFilteredBanques] = useState([]);
  const [filtredReglements, setFiltredReglements] = useState([]);
  const items = [
    "Carte bancaire",
    "Espèces",
    "Virement bancaire",
    "Retenu à la source",
  ];

  const fields = [
    { name: "Référence", field: "s__ref", type: "varchar" },
    { name: "Montant", field: "montant", type: "number" },
    { name: "Date ", field: "date", type: "date" },
  ];

  const onSearch = (e) => {
    setSearchValue(e.target.value);
    const updatedList = customFilter(e.target.value, reglementsAll, [
      "providerName",
      "montant",
      "s__ref",
      "facture",
      "date",
      "banque",
      "nom",
      "num",
    ]);
    setReglements(updatedList);
  };
  useEffect(() => {
    toGetReglements(month).then((data) => {
      const l__events = [];
      for (let index = 0; index < data.length; index++) {
        data[index]["montant"] = data[index].data.montant;
        data[index]["facture"] = data[index].ref;
        data[index]["date"] =
          null != data[index].data.date
            ? moment(data[index].data.date).format("YYYY/MM/DD")
            : moment(data[index].createdDate).format("YYYY/MM/DD");
        data[index]["banque"] =
          null != data[index].data.banque ? data[index].data.banque : "";
        data[index]["nom"] =
          null != data[index].data.nom ? data[index].data.nom : "";
        data[index]["num"] =
          null != data[index].data.num ? data[index].data.num : "";
        l__events.push({
          event: getReglementType(data[index]),
          date: new Date(data[index].date),
          bgColor: "#7DDE92",
          color: "white",
        });
      }
      console.log(
        "333",
        data.filter((el) => el.type == 3)
      );
      setEvents(l__events);
      setReglements(data);
      setReglementsAll(data);
      setLoading(false);
      const uniqueArray = [
        ...new Map(
          data
            .filter((el) => el.type == 1 || el.type == 2)
            .map((obj) => [obj.data?.banque?.trim().toUpperCase(), obj])
        ).values(),
      ];
      var allReglements = [];
      for (let index = 0; index < uniqueArray.length; index++) {
        const elemntsOfBank = data.filter(
          (el) =>
            el.data.banque?.trim().toUpperCase() ==
            uniqueArray[index].data.banque?.trim().toUpperCase()
        );
        allReglements.push(
          toFormatItemsOfSameBank(
            elemntsOfBank,
            uniqueArray[index].data.banque?.trim()
          )
        );
      }

      allReglements.push(
        toFormatItemsOfSameBank(
          data.filter((el) => el.type == 0),
          "Espèces"
        )
      );
      allReglements.push(
        toFormatItemsOfSameBank(
          data.filter((el) => el.type == 3),
          "Carte bancaire"
        )
      );
      allReglements.push(
        toFormatItemsOfSameBank(
          data.filter((el) => el.type == 4),
          "Virement bancaire"
        )
      );
      allReglements.push(
        toFormatItemsOfSameBank(
          data.filter((el) => el.type == 5),
          "Retenu à la source"
        )
      );

      setFiltredReglements(allReglements);
      setFilteredBanques(uniqueArray);
    });
  }, [month]);

  const toUpdateData = (values) => {
    setReglements(values);
    setFilterModalVisible(false);
  };

  const toFormatItemsOfSameBank = (elemntsOfBank, bank) => {
    const filtredReglements = [];
    const uniquesDates = [
      ...new Map(
        elemntsOfBank.map((obj) => [
          items.includes(bank)
            ? toConvertDateWithoutTime(obj.date)
            : toConvertDateWithoutTime(obj.data.date),
          obj,
        ])
      ).values(),
    ];

    if (items.includes(bank)) {
      console.log(bank, elemntsOfBank);

      uniquesDates.map((choosen) => {
        const list = elemntsOfBank.filter(
          (x) =>
            toConvertDateWithoutTime(x.date) ===
            toConvertDateWithoutTime(choosen.date)
        );
        var total = 0;
        let json = {
          date: toConvertDateWithoutTime(choosen.date),
          list: [],
          total: 0,
        };
        var l = [];
        list.forEach((element) => {
          l.push(element);
          total += parseFloat(element.data.montant);
        });
        json = { ...json, list: l, total: total };
        if (l.length > 0) filtredReglements.push(json);
      });
    } else {
      uniquesDates.map((choosen) => {
        const list = elemntsOfBank.filter(
          (x) =>
            toConvertDateWithoutTime(x.data.date) ===
            toConvertDateWithoutTime(choosen.data.date)
        );
        var total = 0;
        let json = {
          date: toConvertDateWithoutTime(choosen.data.date),
          list: [],
          total: 0,
        };
        var l = [];
        list.forEach((element) => {
          l.push(element);
          total += parseFloat(element.data.montant);
        });
        json = { ...json, list: l, total: total };
        filtredReglements.push(json);
      });
    }
    return { data: filtredReglements, bank };
  };

  const getReglementType = (reglement) => {
    switch (reglement.type) {
      case 0:
        return "Espèces";
        break;
      case 1:
        return "Chèques";

      case 2:
        return "Traites";

      case 3:
        return "Carte bancaire";

      case 4:
        return "Virement bancaire";
      case 5:
        return "Retenu à la source";
      case 5:
        return "Contre remboursement";

      default:
        break;
    }
  };

  const deleteReglement = (id) => {
    toDeleteReglement(id).then(() => {
      let newReglements = reglements.filter((e) => e.id != id);
      setReglements(newReglements);
    });
  };

  return (
    <div id="achat">
      <Row className="auto--custom-header">
        <Col span="8">
          <Typography.Title level={3}>Liste des reglements</Typography.Title>
        </Col>
        <Col span="7" style={{ textAlign: "right" }}>
          <DatePicker picker="month" onChange={(e, m) => setMonth(m)} />
        </Col>
        <Col span="5">
          <Form.Item label="Recherche">
            <Input.Search
              onInput={onSearch}
              value={searchValue}
              placeholder="Recherche par référence, fournisseur, total..."
              enterButton
            />
          </Form.Item>
        </Col>
        <Col span="3" style={{ textAlign: "right" }}>
          <Button
            type="link"
            onClick={(e) => setFilterModalVisible(true)}
            icon={
              <img
                src="/images/filterIconVente.svg"
                style={{ width: "3vh", height: "3vh" }}
              />
            }
            style={{ marginRight: "5px" }}
          ></Button>
        </Col>
      </Row>

      <Row>
        <Col span="24">
          <Tabs defaultActiveKey="0">
            {filtredReglements
              .sort((a, b) => a.bank - b.bank)
              .map((el, i) => (
                <Tabs.TabPane tab={el.bank !== "" ? el.bank : "Vide"} key={i}>
                  {el.data.map((y, index) => (
                    <Collapse bordered accordion>
                      <Collapse.Panel
                        key={index + " " + y}
                        header={
                          <Row>
                            <Col span={8}>
                              <b> {y.date.slice(0, -4)}</b>
                            </Col>
                            <Col span={8} style={{ textAlign: "center" }}>
                              <b> {y.list.length}</b> réglement(s)
                            </Col>
                            <Col span={8} style={{ textAlign: "right" }}>
                              <b> Total: {y.total.toFixed(3)}</b>
                            </Col>
                          </Row>
                        }
                      >
                        <div className="table-wrapper auto--overflow">
                          <table
                            className="ant-table"
                            style={{ width: "100%" }}
                          >
                            <thead className="ant-table-thead">
                              <tr>
                                <th className="ant-table-cell">
                                  Référence Facture Fournisseur
                                </th>
                                <th className="ant-table-cell">Fournisseur</th>
                                <th className="ant-table-cell">Type</th>
                                <th className="ant-table-cell">Num</th>
                                <th className="ant-table-cell">Montant</th>
                                <th className="ant-table-cell"></th>
                              </tr>
                            </thead>
                            <tbody className="ant-table-tbody">
                              {y.list &&
                                y.list.map((reglement, y) => (
                                  <tr
                                    key={i}
                                    className={
                                      y % 2 != 0 && "auto--background-grey "
                                    }
                                  >
                                    {" "}
                                    <td className="ant-table-cell">
                                      <Typography.Link
                                        onClick={() =>
                                          setFactureToInspect({
                                            id: reglement.factureId,
                                          })
                                        }
                                      >
                                        {reglement.ref}
                                      </Typography.Link>
                                    </td>
                                    <td className="ant-table-cell">
                                      {reglement.clientName}
                                    </td>
                                    <td className="ant-table-cell">
                                      <b>{getReglementType(reglement)}</b>
                                    </td>
                                    <td className="ant-table-cell">
                                      {reglement.data.num}
                                    </td>
                                    <td className="ant-table-cell">
                                      <b>
                                        {numberFormatCurrency(
                                          reglement.data.montant
                                        )}
                                      </b>
                                    </td>
                                    <td
                                      className="ant-table-cell"
                                      style={{ float: "right" }}
                                    >
                                      {reglement.type === 2 && (
                                        <Tooltip
                                          type="link"
                                          title={`Imprimer le traite`}
                                          okText="Oui"
                                          cancelText="Non"
                                        >
                                          <Button
                                            onClick={() =>
                                              toPrintTraite(reglement.id)
                                            }
                                            icon={
                                              <img
                                                src="/images/print.svg"
                                                className="auto--icon-list"
                                              ></img>
                                            }
                                          />
                                        </Tooltip>
                                      )}
                                      <Popconfirm
                                        title={`Voulez vous vraiment supprimer ce réglement?`}
                                        onConfirm={() =>
                                          deleteReglement(reglement.id)
                                        }
                                        okText="Oui"
                                        cancelText="Non"
                                      >
                                        <Button icon={<BiTrash />} />
                                      </Popconfirm>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  ))}
                </Tabs.TabPane>
              ))}
          </Tabs>
        </Col>
      </Row>
      <Skeleton active loading={loading} />
      <FactureReceptionModal
        factureReception={factureToInspect}
        onClose={() => setFactureToInspect(null)}
      />
      <Modal
        className="modal-small"
        title={"Filtrer"}
        visible={filterModalVisible}
        footer={[]}
        onCancel={(e) => setFilterModalVisible(false)}
      >
        <Search
          toUpdateData={toUpdateData}
          fields={fields}
          list={reglementsAll}
        />
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setQuoteToUpdate: (quote) => dispatch(setItemsState(quote)),
  };
};

export default connect(null, mapDispatchToProps)(ReglementsList);
