import { Col, Collapse, Typography, Row, Skeleton, Button } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  payDemande,
  refuseDemande,
  toGetEmployeeEtat,
  toGetListEmployee,
  validateDemande,
} from "./Apis";
import FicheEmployee from "./FicheEmployee";
import {
  CloseOutlined,
  FileDoneOutlined,
  DollarOutlined,
  SettingOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import printJS from "print-js";
import moment from "moment";

const { Title } = Typography;
const EmployeesCollapse = ({ employeeReducer }) => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelected] = useState(-1);
  const [totalAvance, setTotalAvance] = useState(0);
  const scrollRef = useRef(null);
  const executeScroll = () =>
    scrollRef?.current?.scrollIntoView({
      behavior: "smooth",
      inline: "nearest",
    });

  useEffect(() => {
    toGetListEmployee().then((res) => {
      setUsers(res);
      setLoading(false);
    });
  }, []);

  const getTotal = () => {
    let total = 0;
    users.forEach((element) => {
      element.demandes.forEach((demande) => {
        if (demande.type == 0 && demande.status != 2) {
          total += parseFloat(demande.data.montant);
        }
      });
      setTotalAvance(total);
    });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const total = useMemo(() => getTotal(), [users]);

  useEffect(() => {
    if (employeeReducer.reload) {
      toGetListEmployee().then((res) => {
        setUsers(res);

        setLoading(false);
      });
      employeeReducer.setReload(false);
    }
  }, [employeeReducer, employeeReducer.reload]);

  const onChange = (key) => {
    if (key == selectedUser) {
      setSelected(-1);
    } else {
      setSelected(key);
      setTimeout(() => {
        executeScroll();
      }, 100);
    }
  };

  const toRefuseDemande = (i__id) => {
    refuseDemande(i__id).then((res) => {
      employeeReducer.setReload(true);
    });
  };

  const toValidateDemande = (i__id) => {
    validateDemande(i__id).then((res) => {
      employeeReducer.setReload(true);
    });
  };

  const toPayDemande = (i__id) => {
    payDemande(i__id).then((res) => {
      employeeReducer.setReload(true);
    });
  };

  const getJsonToPrint = () => {
    const res = [];
    users.forEach((element) => {
      element.demandes.forEach((demande) => {
        if (demande.type == 0 && demande.status != 2) {
          res.push({
            employé: element.prenom + " " + element.nom,
            valeur: demande.data.montant,
          });
        }
      });
    });

    res.push({
      employé: "Total:",
      valeur: totalAvance,
    });
    return res;
  };

  const getBonus = (user) => {
    const id = user.id;
    if (id === 60) return 500;
    if (id === 69) return 350;
    if (id === 112) return 250;
    if (id === 97) return 180;
    if (id === 106) return 100;
    if (id === 107) return 120;
    if (id === 132) return 50;

    return user.salary;
  };

  const getJsonEtat = () => {
    const res = [];
    var totalAvance = 0;
    users.forEach((user) => {
      if (user.id !== 1) {
        var bonus = getBonus(user);
        if (user.solde > 0) {
          totalAvance += user.solde + bonus;
        }
        res.push({
          employé: user.prenom + " " + user.nom,
          "13 éme": bonus,
          penalités: user.penality,
          solde: Math.round(user.solde),
          total: Math.round(user.solde) + bonus,
        });
      }
    });

    res.push({
      employé: "",
      "13 éme": "",
      absences: "",
      solde: "Total:",
      total: Math.round(totalAvance),
    });

    return res;
  };

  const getEtatEmployee = (res) => {
    const list = [];
    res.map((el) => {
      list.push({
        mois: el.month,
        avances: el.avances,
        absences: el.absences,
        penalités: el.penality,
        solde: Math.round(el.solde),
      });
    });

    return list;
  };

  const genExtra = (id) => (
    <PrinterOutlined
      onClick={(event) => {
        toGetEmployeeEtat(id).then((res) => {
          printJS({
            style:
              " body { margin: 0;  } .date{float: right;} .title{text-align : center;}  th {text-align: center} td {text-align: center; padding: 30px}",
            printable: getEtatEmployee(res),
            properties: ["mois", "avances", "absences", "penalités", "solde"],
            type: "json",
            header: "Etat employee: " + res[0].nom,
          });
        });
        event.stopPropagation();
      }}
    />
  );

  return (
    <>
      <Button
        onClick={() =>
          printJS({
            style:
              " body { margin: 0;  } .date{float: right;} .title{text-align : center;}  th {text-align: center} td {text-align: center; padding: 30px}",
            printable: getJsonEtat(),
            properties: ["employé", "solde", "13 éme", "total"],
            type: "json",
            header: "Etat salaire " + moment().format("YYYY/MM/DD"),
          })
        }
        icon={<PrinterOutlined />}
      >
        Imprimer un Etat
      </Button>
      <Row>
        <Col span="22" justify="end">
          <Title level={4} style={{ float: "right" }}>
            Total des avances en attente:{" "}
            <u>
              <b>{totalAvance}</b>
            </u>
          </Title>
        </Col>
        <Col span="2" style={{ textAlign: "center" }}>
          <Button
            type="link"
            onClick={() =>
              printJS({
                style:
                  " body { margin: 0;  } .date{float: right;} .title{text-align : center;}  th {text-align: center} td {text-align: center; padding: 10px}",
                printable: getJsonToPrint(),
                properties: ["employé", "valeur"],
                type: "json",
                header:
                  "Liste des avances cette semaine " +
                  moment().format("YYYY/MM/DD"),
              })
            }
            icon={
              <img src="/images/print.svg" className="auto--icon-list"></img>
            }
          ></Button>
        </Col>
      </Row>

      <Row style={{ padding: "12px 25px", marginTop: "2vh" }}>
        <Col span={4}>
          <b>Employé</b>
        </Col>
        <Col span={3}>
          <b>Salaire</b>
        </Col>
        <Col span={2}>
          <b>Solde Mois Précédent</b>
        </Col>
        <Col span={2}>
          <b>Solde</b>
        </Col>
        <Col span={5}>
          <b>Demande en cours</b>
        </Col>
        <Col span={2}>
          <b>Avances </b>
        </Col>
        <Col span={2}>
          <b>Absences</b>
        </Col>
        <Col span={2}>
          <b>Penalités</b>
        </Col>
        <Col span={2}>
          <b>Taux horaire</b>
        </Col>
      </Row>
      <Skeleton active loading={loading} />
      <Collapse accordion onChange={onChange}>
        {users &&
          users
            .filter((el) => el.salary != 0)
            .sort((a, b) =>
              a.demandeInProgress > b.demandeInProgress
                ? -1
                : b.demandeInProgress > a.demandeInProgress
                ? 1
                : 0
            )
            .map((user, i) => (
              <Collapse.Panel
                extra={genExtra(user.id)}
                style={{ backgroundColor: i % 2 === 0 ? "#fff" : "#FAE6DD" }}
                header={
                  <Row>
                    <Col span={4}>
                      <span
                        style={{
                          color: i % 2 === 0 ? "#E66223" : "black",
                          fontWeight: "bolder",
                        }}
                      >
                        {user.prenom + " " + user.nom}
                      </span>
                    </Col>
                    <Col span={3}>
                      <b>{user.salary}</b>
                    </Col>
                    <Col span={2}>{<span>{user.oldSolde}</span>}</Col>
                    <Col span={2}>
                      {
                        <span
                          className={
                            user.solde < 0
                              ? "auto--color-danger"
                              : "auto--color-success"
                          }
                        >
                          {user.solde.toFixed(3)}
                        </span>
                      }
                    </Col>
                    <Col span={5}>
                      <span style={{ color: "blue", fontWeight: "bold" }}>
                        {user.demandeInProgress}
                      </span>
                      {user.demandes
                        .filter((el) => el.status == 0 || el.status == 1)
                        .map((element) => (
                          <>
                            <Row>
                              <Col span="18">
                                {element.type == 0 && (
                                  <div>
                                    <span>
                                      Avance - Montant:{" "}
                                      <b>{element.data.montant}</b>{" "}
                                    </span>
                                  </div>
                                )}
                                {element.type == 1 && (
                                  <div>
                                    <span>Congés</span>
                                    <br />
                                    <span>
                                      Début: <b>{element.data.dateStart}</b>
                                    </span>
                                    <br />
                                    <span>
                                      Durée: <b>{element.data.duration}</b>
                                    </span>
                                  </div>
                                )}
                                {element.type == 2 && (
                                  <div>
                                    <span>Crédit</span>
                                    <br />
                                    <span>
                                      {"Montant: " + element.data.montant}
                                    </span>
                                    <br />
                                    <span>
                                      {"Période: " + element.data.period}
                                    </span>
                                  </div>
                                )}
                              </Col>
                              <Col span="3">
                                {element.status == 0 && (
                                  <>
                                    <Button
                                      type="primary"
                                      onClick={(e) => {
                                        toValidateDemande(element.demandeId);
                                        e.stopPropagation();
                                      }}
                                      icon={
                                        <FileDoneOutlined
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        />
                                      }
                                    />
                                  </>
                                )}
                                {element.status == 1 && (
                                  <Button
                                    type="primary"
                                    onClick={(e) => {
                                      toPayDemande(element.demandeId);
                                      e.stopPropagation();
                                    }}
                                    icon={
                                      <DollarOutlined
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      />
                                    }
                                  />
                                )}
                              </Col>
                              <Col span="3">
                                {element.status == 0 && (
                                  <Button
                                    type="danger"
                                    onClick={(e) => {
                                      toRefuseDemande(element.demandeId);
                                      e.stopPropagation();
                                    }}
                                    icon={
                                      <CloseOutlined
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      />
                                    }
                                  />
                                )}
                              </Col>
                            </Row>
                            <hr />
                          </>
                        ))}
                    </Col>
                    <Col span={2}>
                      <b>{user.avances}</b>
                    </Col>
                    <Col span={2}>
                      <b>{user.nbAbsences}</b>
                    </Col>
                    <Col span={2}>
                      <b>{user.penality}</b>
                    </Col>
                    <Col span={2}>{`${user.taux_h.toFixed(3)}/h`}</Col>
                  </Row>
                }
                key={user.id}
              >
                <div ref={scrollRef}>
                  {selectedUser == user.id && (
                    <FicheEmployee
                      employeeReducer={employeeReducer}
                      id={user.id}
                      user={user}
                    />
                  )}
                </div>
              </Collapse.Panel>
            ))}
      </Collapse>
    </>
    // <div className='table-wrapper auto--overflow'>
    //   <table className='ant-table' style={{ width: "100%" }}>
    //     <thead className='ant-table-thead'>
    //       <tr>
    //         <th className='ant-table-cell'>Username</th>
    //         <th className='ant-table-cell'>Salaire</th>
    //         <th className='ant-table-cell'>Solde</th>
    //         <th className='ant-table-cell'>Demande en cours</th>
    //         <th className='ant-table-cell'>Nombre d'absences</th>
    //         <th className='ant-table-cell'>Penalité</th>
    //         <th className='ant-table-cell'></th>
    //       </tr>
    //     </thead>
    //     <Skeleton active loading={loading} />

    //     <tbody className='ant-table-tbody'>
    //       {users &&
    //         users.map((user, i) => (
    //           <tr key={i} className={i % 2 != 0 && "auto--background-grey"}>
    //             <td className='ant-table-cell'>{user.username}</td>
    //             <td className='ant-table-cell'>{user.salary}</td>
    //             <td className='ant-table-cell'>{user.solde}</td>
    //             <td className='ant-table-cell'>{user.demandeInProgress}</td>
    //             <td className='ant-table-cell'>{user.nbAbsences}</td>
    //             <td className='ant-table-cell'>{user.penality}</td>
    //           </tr>
    //         ))}
    //     </tbody>
    //   </table>
    // </div>
  );
};

export default EmployeesCollapse;
