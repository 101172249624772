import firebase from "firebase/app";
import "firebase/messaging";
import cookie from "react-cookies";

var firebaseConfig = {
  apiKey: "AIzaSyBUXdz-wBoMhrp26vls36OmCgQDUPNaQwk",
  authDomain: "cardoc-39be8.firebaseapp.com",
  projectId: "cardoc-39be8",
  storageBucket: "cardoc-39be8.appspot.com",
  messagingSenderId: "976101817160",
  appId: "1:976101817160:web:b5fb4e4d64dc2ebe24117d",
  measurementId: "G-EVL6GQ7QQB",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

export const getToken = (setTokenFound) => {
  return messaging
    .getToken({
      vapidKey:
        "BIF1-LE5ooprIDC9D8N7ITFD5bWquRWGGkv_I3WSlpX8LHUqy-rvrcECoquIbAvmYo1fglNUT9QYmLE-DrVAZV4",
    })
    .then((currentToken) => {
      if (currentToken) {
        cookie.save("fcmtoken", currentToken, { path: "/" });
        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
