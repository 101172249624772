import {
  Button,
  message,
  Steps,
  theme,
  Form,
  Modal,
  Row,
  Col,
  Input,
  Select,
  Skeleton,
  Descriptions,
  Typography,
} from "antd";
import { SelectOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { errorMsg } from "../../Utils/Utils";
import {
  toGetArticlesList,
  toGetArticlesName,
  toGetSearchArticlesList,
  toMergeArticles,
} from "./Api";
const Option = { Select };
const ArticleFusion = ({ visible, setFusionVisible }) => {
  const [form] = Form.useForm();
  const [articlesNamesAll, setArticleNamesAll] = useState([]);
  const [articles, setArticles] = useState([]);
  const [articlesAll, setArticlesAll] = useState([]);
  const [loading, setLoading] = useState(false);
  const [articlesNames, setArticleNames] = useState([]);
  const [fils, setFils] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);
  useEffect(() => {
    toGetArticlesName().then((res) => {
      setArticleNames(res);
      setArticleNamesAll(res);
    });
  }, []);

  const contentStyle = {
    lineHeight: "260px",
    textAlign: "center",
    color: "#ccc",
    backgroundColor: "#00000005",
    borderRadius: "8px",
    border: `1px dashed rgb(217, 217, 217)`,
    marginTop: 16,
  };

  const sortInputFirst = (input, data) => {
    var first = [];
    var others = [];
    for (var i = 0; i < data.length; i++) {
      if (data[i].toUpperCase().indexOf(input.toUpperCase()) >= 0) {
        if (data[i].toUpperCase().indexOf(input.toUpperCase()) == 0) {
          first.push(data[i]);
        } else {
          others.push(data[i]);
        }
      }
    }
    first.sort();
    others.sort();
    setArticleNames(first.concat(others));
  };

  const updateFlis = (article) => {
    if (fils.filter((e) => e.id == article.id).length > 0) {
      setFils(fils.filter((e) => e.id != article.id));
    } else {
      setFils([...fils, article]);
    }
  };

  const steps = [
    {
      title: "Article père",
      content: (
        <>
          <h3>Article père</h3>{" "}
          <Form
            form={form}
            name="basic"
            style={{ padding: 10 }}
            initialValues={{ remember: true }}
          >
            <Row gutter={24}>
              <Col md={8}>
                <Form.Item style={{ marginBottom: 0 }} name="searchWord">
                  <Select
                    dropdownMatchSelectWidth={600}
                    showSearch
                    style={{ width: "100%" }}
                    dropdownStyle={{ width: "100%" }}
                    onChange={(e) => onSearch(e, false)}
                    placeholder="Recherche par désignation..."
                  >
                    <Option value="Tout">Tout</Option>

                    {articlesNames.map((name, i) => (
                      <Option value={name}>{name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={7}>
                <Form.Item style={{ marginBottom: 0 }} name="searchAvancée">
                  <Input.Search
                    onSearch={(e) => onSearch(e, true)}
                    placeholder="Rec référence..."
                    enterButton
                  />
                </Form.Item>
              </Col>
            </Row>
            <>
              <div className="table-wrapper" style={{ marginTop: "20px" }}>
                <table className="ant-table" style={{ width: "100%" }}>
                  <thead className="ant-table-thead">
                    <tr>
                      <th className="ant-table-cell">Titre</th>
                      <th className="ant-table-cell">Caractéristiques</th>
                      <th className="ant-table-cell">P U TTC</th>
                      <th className="ant-table-cell">Stock</th>
                      <th className="ant-table-cell"></th>
                    </tr>
                  </thead>
                  <Skeleton active loading={loading} />
                  <tbody className="ant-table-tbody">
                    {!loading &&
                      articles.map((article, i) => (
                        <tr
                          key={i}
                          className={i % 2 != 0 && "auto--background-grey"}
                        >
                          <td className="ant-table-cell">
                            <b style={{ fontSize: "1rem" }}>
                              {article.s__secondTitle}
                            </b>
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ padding: "10px" }}
                          >
                            <span>REF</span> :{" "}
                            <b
                              style={{ color: "#2696d3", fontSize: "0.75rem" }}
                            >
                              {article.s__reference}
                            </b>
                            <br />
                            <span>Mar</span> :{" "}
                            <b
                              style={{ color: "#846eb1", fontSize: "0.75rem" }}
                            >
                              {article.marqueTitle}
                            </b>
                            <br />
                            <span>Emp</span> :{" "}
                            <b style={{ fontSize: "0.8rem" }}>
                              {article.emplacement.map((e) => (
                                <>
                                  <div>{e.stock + " => " + e.emp}</div>
                                </>
                              ))}
                            </b>
                          </td>
                          <td className="ant-table-cell">
                            <b>
                              {article.l__prixVenteTTC.toFixed(3)}{" "}
                              <span
                                style={{
                                  fontWeight: "normal",
                                  verticalAlign: "text-top",
                                }}
                              >
                                DT
                              </span>
                            </b>
                          </td>
                          <td className="ant-table-cell">
                            <span
                              className={
                                article.stock <= 0
                                  ? "auto--color-danger"
                                  : article.stock < article.l__seuil
                                  ? "auto--color-warning"
                                  : "auto--color-success"
                              }
                            >
                              {article.stock}
                            </span>
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ display: "inline-flex" }}
                          >
                            <Button
                              type="link"
                              onClick={(e) => setSelectedArticle(article)}
                              icon={<SelectOutlined />}
                            ></Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          </Form>
        </>
      ),
    },
    {
      title: "Article enfanats",
      content: (
        <>
          {" "}
          <h3>Article(s) Enfant(s)</h3>
          <Form
            form={form}
            name="basic"
            style={{ padding: 10 }}
            initialValues={{ remember: true }}
          >
            <Row gutter={24}>
              <Col md={8}>
                <Form.Item style={{ marginBottom: 0 }} name="searchWord">
                  <Select
                    dropdownMatchSelectWidth={600}
                    showSearch
                    style={{ width: "100%" }}
                    dropdownStyle={{ width: "100%" }}
                    onChange={(e) => onSearch(e, false)}
                    placeholder="Recherche par désignation..."
                  >
                    <Option value="Tout">Tout</Option>

                    {articlesNames.map((name) => (
                      <Option value={name}>{name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={7}>
                <Form.Item style={{ marginBottom: 0 }} name="searchAvancée">
                  <Input.Search
                    onSearch={(e) => onSearch(e, true)}
                    placeholder="Rec référence..."
                    enterButton
                  />
                </Form.Item>
              </Col>
            </Row>
            <>
              <div className="table-wrapper" style={{ marginTop: "20px" }}>
                <table className="ant-table" style={{ width: "100%" }}>
                  <thead className="ant-table-thead">
                    <tr>
                      <th className="ant-table-cell">Titre</th>
                      <th className="ant-table-cell">Caractéristiques</th>
                      <th className="ant-table-cell">P U TTC</th>
                      <th className="ant-table-cell">Stock</th>
                      <th className="ant-table-cell"></th>
                    </tr>
                  </thead>
                  <Skeleton active loading={loading} />
                  <tbody className="ant-table-tbody">
                    {!loading &&
                      articles.map((article, i) => (
                        <tr
                          key={i}
                          className={i % 2 != 0 && "auto--background-grey"}
                        >
                          <td className="ant-table-cell">
                            <b style={{ fontSize: "1rem" }}>
                              {article.s__secondTitle}
                            </b>
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ padding: "10px" }}
                          >
                            <span>REF</span> :{" "}
                            <b
                              style={{ color: "#2696d3", fontSize: "0.75rem" }}
                            >
                              {article.s__reference}
                            </b>
                            <br />
                            <span>Mar</span> :{" "}
                            <b
                              style={{ color: "#846eb1", fontSize: "0.75rem" }}
                            >
                              {article.marqueTitle}
                            </b>
                            <br />
                            <span>Emp</span> :{" "}
                            <b style={{ fontSize: "0.8rem" }}>
                              {article.emplacement.map((e) => (
                                <>
                                  <div>{e.stock + " => " + e.emp}</div>
                                </>
                              ))}
                            </b>
                          </td>
                          <td className="ant-table-cell">
                            <b>
                              {article.l__prixVenteTTC.toFixed(3)}{" "}
                              <span
                                style={{
                                  fontWeight: "normal",
                                  verticalAlign: "text-top",
                                }}
                              >
                                DT
                              </span>
                            </b>
                          </td>
                          <td className="ant-table-cell">
                            <span
                              className={
                                article.stock <= 0
                                  ? "auto--color-danger"
                                  : article.stock < article.l__seuil
                                  ? "auto--color-warning"
                                  : "auto--color-success"
                              }
                            >
                              {article.stock}
                            </span>
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ display: "inline-flex" }}
                          >
                            <Button
                              type="link"
                              onClick={(e) => updateFlis(article)}
                              icon={<SelectOutlined />}
                            ></Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          </Form>
        </>
      ),
    },
    {
      title: "Last",
      content: <></>,
    },
  ];

  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const onSearch = (value, ref) => {
    if (ref) {
      form.setFieldsValue({ searchWord: "" });
    } else {
      form.setFieldsValue({ searchAvancée: "" });
    }
    if (form.getFieldValue("searchWord") == "Tout") {
      toGetArticlesList(0).then((data) => {
        setArticles(data);
        setLoading(false);
      });
      return;
    }
    form.setFieldsValue({ searchWordTecdoc: "" });
    if (value.length == 1) {
      errorMsg("Veuillez entrer 2 caractères merci!");
    } else {
      setLoading(true);
      toGetArticles(value, ref);
    }
  };

  const toGetArticles = async (search, ref) => {
    if (search == "") {
      //todo change this in react
      toGetArticlesList(0).then((data) => {
        setArticles(data);
        setArticlesAll(data);

        setLoading(false);
      });
    } else {
      toGetSearchArticlesList(search, ref).then((res) => {
        setArticles(res);
        setArticlesAll(res);
        setLoading(false);
      });
    }
  };

  return (
    <Modal
      className="smtv-modal modal-large"
      title="Fusion article"
      visible={visible}
      footer={[]}
      onCancel={() => setFusionVisible(false)}
    >
      <Descriptions bordered size="big">
        <Descriptions.Item label="Article père">
          {null != selectedArticle &&
            selectedArticle.s__secondTitle +
              "   " +
              selectedArticle.s__reference}
        </Descriptions.Item>
        <Descriptions.Item label="Article(s) enfant(s)">
          {fils.map((el) => (
            <p>{el.s__secondTitle + "   " + el.s__reference}</p>
          ))}
        </Descriptions.Item>
      </Descriptions>
      <Steps current={current} items={items} />
      <div style={{ float: "right" }}>
        {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => toMergeArticles(selectedArticle, fils)}
          >
            Valider
          </Button>
        )}
        {current > 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => prev()}
          >
            Précédent
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button
            type="primary"
            disabled={
              (current == 0 && null == selectedArticle) ||
              (current == 1 && fils.length == 0)
            }
            onClick={() => next()}
          >
            Suivant
          </Button>
        )}
      </div>

      <div style={contentStyle}>{steps[current].content}</div>
    </Modal>
  );
};

export default ArticleFusion;
