import axios from "axios";
import cookie from "react-cookies";
import CAxios from "./CAxios";

export const toLogin = (a, b) => {
  return axios
    .post(process.env.REACT_APP_API_URL + "/authenticate", {
      username: a,
      password: b,
      token: cookie.load("fcmtoken"),
    })

    .then(function (response) {
      if (response.data.success.active === 0) {
        return 0;
      }
      cookie.save("token", response.data.success.token, {
        path: "/",
        maxAge: 10 * 60 * 60,
      });
      cookie.save("id", response.data.success.id, { path: "/" });
      cookie.save("options", response.data.success.ja_options, { path: "/" });
      cookie.save("role", response.data.success.s__role, { path: "/" });
      cookie.save("nom", response.data.success.nom, { path: "/" });
      cookie.save("username", a, { path: "/" });
      cookie.save("notification", response.data.success.notification, {
        path: "/",
      });

      cookie.save("changeDate", response.data.success.changeDate, {
        path: "/",
      });
      cookie.save("createClient", response.data.success.createClient, {
        path: "/",
      });
      cookie.save("caisse", response.data.success.caisse, {
        path: "/",
      });
      cookie.save("removeDuplicata", response.data.success.removeDuplicata, {
        path: "/",
      });
      cookie.save("removeReglement", response.data.success.removeReglement, {
        path: "/",
      });
      return 1;
    })
    .catch(function (error) {
      return -1;
    });
};

export const toLoginId = (a, b) => {
  return axios
    .post(process.env.REACT_APP_API_URL + "/authenticate", {
      username: a,
      password: b,
      token: cookie.load("fcmtoken"),
    })
    .then(function (response) {
      if (response.data.success.active === 0) {
        return 0;
      }
      return response.data.success.id;
    })
    .catch(function (error) {
      return -1;
    });
};

export const toGetAudit = async (json) => {
  const { data } = await CAxios.post(`/api/history`, json);
  return data.success && data.success.data ? data.success.data : null;
};

//delivery apis
export const toGetAllDelivery = async (date) => {
  const { data } = await CAxios.post(`/api/mydelivery/all`, { date });
  return data.success && data.success.list ? data.success.list : [];
};
export const toAddDelivery = async (json) => {
  const { data } = await CAxios.post(`/api/mydelivery/add`, json);
  return data.success ? data.success : null;
};
export const toGetAdresses = async () => {
  const { data } = await CAxios.post(`/api/mydelivery/adress`);
  return data.success && data.success.list ? data.success.list : [];
};
