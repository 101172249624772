import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  notification as notifAntd,
  Select,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import cookie from "react-cookies";
import { connect } from "react-redux";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { loadUser, logout } from "./Actions/AuthActions";
import { loadUIConfig } from "./Actions/UIActions";
import TopNavLayout from "./Layouts/TopNavigationLayout";
import Admin from "./Pages/Admin";
import AlertCaisse from "./Pages/Compte/AlertCaisse";
import {
  toGetNotifications,
  toGetUsers,
  updateCaisseNotifByCode,
} from "./Pages/Compte/Api";
import Login from "./Pages/Login";
import { toUpdateCaisseValue } from "./Pages/Users/APIs";
import {
  payFactureUpdate,
  toGetBL,
  toGetFactureWithReglements,
  toResultAuthorisationApi,
  toSendNotification,
  toValidateReglement,
  updatePayBl,
} from "./Pages/Vente/APIs";
import BonLivraisonModal from "./Pages/Vente/BL/BonLivraisonModal";
import ModalCode from "./Pages/Vente/ModalCode";
import QuoteEnligne from "./Pages/Vente/Quotes/QuoteEnligne";
import ReglementBLList from "./Pages/Vente/Reglement/ReglementBLList";
import ReglementFacture from "./Pages/Vente/Reglement/ReglementFacture";
import AppStore from "./Stores/AppStore";
import "./Styles/App.scss";
import history from "./Utils/History";
import { errorMsg, successMsg } from "./Utils/Utils";
import { onMessageListener } from "./Utils/firebase";

function App(props) {
  var warningModal = false;

  const [text, settext] = useState("");
  const [isAdminModalVisible, setAdminModalVisible] = useState(false);

  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [blToInspect, setBlToInspect] = useState(null);
  const [id, setId] = useState(-1);
  const [openModal, setOpenModal] = useState(false);
  const [notifText, setNotifText] = useState("");
  const [selectedReglement, setSelectedReglement] = useState(-1);
  const [isBLModalVisible, setisBLModalVisible] = useState(false);
  const [isFactureModalVisible, setisFactureModalVisible] = useState(false);
  const [facture, setFacture] = useState(null);
  const [blToPay, setBlToPay] = useState(true);
  const [remiseChecked, setRemiseChecked] = useState(false);
  const [users, setUsers] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [isModalCodeOpen, setModalCodeOpen] = useState(false);
  const [caisseNotifId, setCaisseNotifId] = useState(-1);
  const [selectedCaisse, setSelectedCaisse] = useState(null);
  const [value, setValue] = useState(-1);
  onMessageListener()
    .then((payload) => {
      if (payload.data.type == "caisse") {
        // setData(payload.data);
        // setSelectedReglement(payload.notification.body);

        // openCaisseNotification(payload);
        form.setFieldsValue({
          value: payload.caisseValue,
          date: moment(payload.date),
        });

        getNotifications();
      } else if (payload.data.type == "authorisation") {
        // setData(payload.data);
        // setSelectedReglement(payload.notification.body);

        // openCaisseNotification(payload);
        openAuthoriationNotif(payload);
      } else {
        setShow(true);
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
        });

        openNotification(payload);
      }
    })
    .catch((err) => console.log("failed: ", err));

  const close = (id) => {
    notifAntd.close(id);
    setBlToInspect(id);
  };

  const toUpdateCaisseNotif = () => {
    updateCaisseNotifByCode(caisseNotifId).then((res) => {
      getNotifications();
      setCaisseNotifId(-1);
      setModalCodeOpen(false);
    });
  };

  const toOpenModal = (id) => {
    notifAntd.close(id);

    setOpenModal(true);
    setId(id);
  };

  const openNotification = (payload) => {
    const key = `open${Date.now()}`;
    const btn = (
      <>
        <Button
          type="primary"
          size="small"
          onClick={() => close(payload.data.id)}
        >
          Voir détail
        </Button>

        <Button
          type="primary"
          size="small"
          style={{ marginLeft: "2vh" }}
          disabled={payload.data.username != cookie.load("username")}
          onClick={() => toOpenModal(payload.data.id)}
        >
          Répondre
        </Button>
      </>
    );

    const args = {
      key: payload.data.id,
      message: payload.notification.title,
      description: payload.notification.body,
      duration: 0,
      btn,
      className:
        payload.data.username == cookie.load("username") && "auto--border-red",
    };
    notifAntd.error(args);
  };

  const updateCaisseValue = () => {
    toUpdateCaisseValue(selectedCaisse.entityId, value).then((res) => {
      successMsg("Valeur de caisse modifié avec succés!");
      setSelectedCaisse(null);
    });
  };

  const resultDemand = (clientId, userId, accept, key, password) => {
    toResultAuthorisationApi(clientId, userId, accept, password).then((res) => {
      if (res) {
        notifAntd.close(key);
      } else {
        errorMsg("Merci de vérifier le code");
      }
    });
  };
  const openAuthoriationNotif = (payload) => {
    const key = `open${Date.now()}`;
    var password = "";
    const btn = (
      <>
        {payload.data.result == 0 && (
          <>
            <Input.Password onChange={(e) => (password = e.target.value)} />
            <Button
              style={{ marginTop: "15px", textAlign: "center" }}
              type="primary"
              size="small"
              onClick={() =>
                resultDemand(
                  payload.data.clientId,
                  payload.data.userId,
                  1,
                  key,
                  password
                )
              }
            >
              Valider
            </Button>
            <Button
              style={{ marginLeft: "15px", textAlign: "center" }}
              type="danger"
              size="small"
              onClick={() =>
                resultDemand(
                  payload.data.clientId,
                  payload.data.userId,
                  -1,
                  key,
                  password
                )
              }
            >
              Refuser
            </Button>
          </>
        )}
      </>
    );

    const args = {
      key,
      message: payload.notification.title,
      description: payload.notification.body,
      duration: 0,
      btn,
    };
    notifAntd.warn(args);
  };

  const closeCaisseNotf = (id) => {
    notifAntd.close(id);
    setSelectedReglement(-1);
    setIsOpen(false);
  };
  const openCaisseNotification = (payload) => {
    const key = `open${Date.now()}`;
    const btn = (
      <>
        <Button
          size="small"
          style={{ marginLeft: "2vh" }}
          onClick={() => {
            closeCaisseNotf(key);
          }}
        >
          Non
        </Button>
        <Button
          type="primary"
          size="small"
          style={{ marginLeft: "2vh" }}
          onClick={() => {
            notifAntd.destroy(key);
            setIsOpen(true);
          }}
        >
          Oui
        </Button>
      </>
    );

    const args = {
      key: key,
      message: payload.notification.title,
      placement: "topLeft",
      className: "smtv--caisse-popup",
      btn,
    };
    notifAntd.warning(args);
  };

  useEffect(() => {
    props.loadUIConfig();
    props.loadUser();
    toGetUsers().then((users) => {
      setUsers(users);
    });
  }, []);

  useEffect(() => {
    getNotifications();
  }, [window.location]);

  const getNotifications = () => {
    toGetNotifications().then((res) => {
      if (res.length > 0) {
        setNotifications([res[0]]);
        form.setFieldsValue({
          value: res[0].caisseValue,
          date: moment(res[0].date),
        });
      } else {
        setNotifications([]);
      }
    });
  };

  const sendNotification = () => {
    var bls = [];

    bls.push(id);

    toSendNotification(notifText, bls).then(() => {
      successMsg("Notification envoyée avec succés");

      setOpenModal(false);
      setNotifText("");
      setId(-1);
    });
  };

  const validateCaisse = (idReglement) => {
    form.validateFields().then((values) => {
      const l__id =
        users.findIndex((e) => e.username == values.driver) !== -1
          ? users[users.findIndex((e) => e.username == values.driver)].id
          : -1;
      toValidateReglement(
        values.value,
        values.date.format("YYYY-MM-DD"),
        idReglement,
        l__id
      ).then((res) => {
        setIsOpen(false);
        getNotifications();
      });
    });
  };

  const openModalPay = (data) => {
    setIsOpen(false);
    var el = data.blId;
    if (el == -1) {
      toGetFactureWithReglements(data.factureId).then((res) => {
        setFacture(res);
        setisFactureModalVisible(true);
      });
      return;
    }
    toGetBL(el).then((data) => {
      var bl = JSON.parse(data.bonlivraison);
      var regl = [];
      data.reglements.forEach((element) => {
        regl.push(element.data);
      });
      const blToUpdate = {
        id: bl.id,
        total: bl.d__total,
        discount: bl.d__discount,
        items: [],
        client: bl.client,
        reglements: regl,
        ref: bl.s__ref,
      };
      for (let item of bl.items) {
        blToUpdate.items.push({
          ...item,
          ref: item.article.s__reference,
          titre: item.article.s__secondTitle,
          quantity: item.d__quantity,
          uPriceHt: item.d__unitPriceHT,
          uPriceTTC: item.d__unitPriceTTC,
          total: item.d__total,
          discount: item.d__discount,
          prix_achat: item.article.l__prixAchatTTC,
          prix_unitaire: item.article.l__prixVenteHT,
          prix_unitaireTTC: item.article.l__prixVenteTTC,
          prix_unitaire_gros: item.article.l__prixVenteGrosHT,
          prix_unitaire_grosTTC: item.article.l__prixVenteGrosTTC,
          tva: item.article.tva.title,
        });
      }
      setBlToPay(blToUpdate);

      setisBLModalVisible(true);
    });
  };

  const toUpdate = (reglements) => {
    validate(-1, reglements);
  };

  const toUpdateTotal = (id) => {
    const bl = { ...blToPay };
    var totalPayé = bl.reglements.reduce((a, b) => b.montant + a, 0);
    bl.discount = (bl.total - totalPayé).toFixed(3);
    setBlToPay(bl);
    validate(id, bl.reglements);
  };

  const validate = (id, reglements) => {
    const bl = { ...blToPay };
    bl.reglements = reglements;
    setBlToPay(bl);
    setAdminModalVisible(false);

    var totalPayé = bl.reglements.reduce((a, b) => b.montant + a, 0);
    if (
      parseFloat(totalPayé).toFixed(3) < parseFloat(bl.total).toFixed(3) &&
      !isAdminModalVisible &&
      !remiseChecked &&
      bl.client.d__threshold == 0
    ) {
      settext("Le BL n'est pas totalement payé!");

      setAdminModalVisible(true);
      return;
    }

    if (
      parseFloat(totalPayé).toFixed(3) < parseFloat(bl.total).toFixed(3) &&
      remiseChecked &&
      !warningModal
    ) {
      warningModal = true;
      Modal.warning({
        okButtonProps: { style: { display: "none" } },

        content: (
          <div>
            <p>
              Voulez vous vraiment ajouter le reste(
              {(bl.total - totalPayé).toFixed(3)} )comme étant une remise!
            </p>
            <div style={{ textAlign: "center", marginTop: "2vh" }}>
              <Button type="primary" onClick={() => toUpdateTotal(id)}>
                Valider
              </Button>
              <Button
                style={{ marginLeft: "2vh" }}
                danger
                onClick={() => {
                  Modal.destroyAll();
                  warningModal = false;
                }}
              >
                Annuler
              </Button>
            </div>
          </div>
        ),
      });
      return;
    }
    Modal.destroyAll();
    warningModal = false;
    updatePayBl(blToPay.id, remiseChecked, bl.reglements, id).then((res) => {
      setisBLModalVisible(false);
    });
  };

  const toPayeFacture = (reglements) => {
    payFactureUpdate(facture.id, reglements).then(() => {
      setisFactureModalVisible(false);
    });
  };

  return props.loaded ? (
    <Router history={history}>
      <Switch>
        <Route path="/login">
          {null == props.user ? <Login></Login> : <Redirect to="/" />}
        </Route>
        <Route path="/devis-en-ligne">{<QuoteEnligne />}</Route>
        <Route
          path=""
          exact={true}
          render={({ location }) => (
            <TopNavLayout user={props.user} AppStore={AppStore}>
              <Admin AppStore={AppStore} location={location} />
            </TopNavLayout>
          )}
        ></Route>
        <Route path="*">
          <Redirect to="/login" />
        </Route>
      </Switch>
      <Modal visible={openModal} title="Réponse" footer={[]}>
        <Form.Item>
          <Input
            value={notifText}
            onChange={(e) => setNotifText(e.target.value)}
          />
        </Form.Item>
        <div style={{ textAlign: "center" }}>
          <Button type="primary" onClick={() => sendNotification()}>
            Envoyer
          </Button>
        </div>
      </Modal>
      <BonLivraisonModal
        entete={false}
        id={blToInspect}
        onClose={() => setBlToInspect(null)}
      />

      <Modal
        title={"Caisse"}
        visible={isOpen}
        onOk={() => validateCaisse()}
        footer={[
          <Button type="primary" onClick={() => validateCaisse()}>
            Valider
          </Button>,
          <Button
            type="dashed"
            onClick={() => {
              openModalPay();
            }}
          >
            Modifier
          </Button>,
          <Button type="default" onClick={() => setIsOpen(false)}>
            Annuler
          </Button>,
        ]}
        onCancel={(e) => setIsOpen(false)}
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            name="value"
            label="Combien vous avez reçu pour ce règlement?"
            rules={[{ required: true, message: "Champ obligatoire" }]}
          >
            <Input type="number" style={{ width: "100%" }}></Input>
          </Form.Item>
          <Form.Item
            name="date"
            label="Date"
            rules={[{ required: true, message: "Champ obligatoire" }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item label="Reçu par" name="driver">
            <Select showSearch>
              {users
                .filter((x) => x.role === "chauffeur" && x.active)
                .map((user) => (
                  <Select.Option value={user.username}>
                    {user.username}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        className="modal-large"
        visible={isBLModalVisible}
        onCancel={() => setisBLModalVisible(false)}
        footer={[]}
      >
        <ReglementBLList
          bl={blToPay}
          validate={toUpdate}
          setRemiseChecked={(checked) => setRemiseChecked(checked)}
        />
      </Modal>
      <Modal
        className="modal-large"
        visible={isFactureModalVisible}
        onCancel={() => setisFactureModalVisible(false)}
      >
        <ReglementFacture toPayeFacture={toPayeFacture} facture={facture} />
      </Modal>

      {selectedCaisse && (
        <Modal
          onOk={() => updateCaisseValue()}
          onCancel={() => setSelectedCaisse(null)}
          visible={selectedCaisse != null}
          title="Modifier la valeur de caisse"
        >
          <b>{selectedCaisse.type}</b> N°<b>{selectedCaisse.entityRef}</b>
          <br />
          <InputNumber
            onChange={(e) => setValue(e)}
            defaultValue={selectedCaisse.caisseValue}
          />
        </Modal>
      )}
      <ModalCode
        isAdminModalVisible={isModalCodeOpen}
        setAdminModalVisible={() => {
          setModalCodeOpen(false);
        }}
        onOk={(id) => toUpdateCaisseNotif()}
        text={"Validation de la caisse par code"}
      />
    </Router>
  ) : (
    <></>
  );
}

const mapStateToProps = ({ authState }) => {
  return {
    loaded: authState.loaded,
    user: authState.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUIConfig: (config) => dispatch(loadUIConfig(config)),
    loadUser: () => dispatch(loadUser()),
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
