import { Input } from "antd";
import { useEffect, useState } from "react";
import { customFilter } from "../../Utils/Utils";

const SearchInput = ({ list, setData, fields }) => {
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (search.length > 2) {
      setData(customFilter(search, list, fields));
    } else {
      setData(list);
    }
  }, [search]);

  return (
    <Input.Search placeholder={""} onInput={(e) => setSearch(e.target.value)} />
  );
};
export default SearchInput;
