import { Col, Row, Tag, Typography } from "antd";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { colors, CustomBarLabel, CustomTooltip } from "../StatsByVendeur/utils";
import { useEffect } from "react";

const CAComponent = ({ data, selected, Entitys, entityIds, type, title }) => {
  const getUsername = (el) => {
    return Entitys.filter((e) => e.id == el)[0].title;
  };
  const toGetTotal = (id) => {
    let total = 0;
    data.forEach((el) => {
      total += parseFloat(el[getUsername(id)]);
    });
    if (type == "2") {
      return Math.round(total / data.length);
    }
    return total.toFixed(3).replace(/\d(?=(\d{3})+\.)/g, "$& ");
  };

  return (
    <>
      {data?.length > 0 && (
        <>
          <Row className="auto--custom-header" style={{ marginTop: "1rem" }}>
            <Col span="24">
              <Typography.Title level={3}>{title}</Typography.Title>
              <div style={{ height: 600, marginTop: "4vh" }}>
                <ResponsiveContainer width="100%" height="100%">
                  <ComposedChart
                    data={data}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 50,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

                    <XAxis dataKey="date" />
                    <YAxis
                      type="number"
                      dataKey={(v) => parseInt(v.valeur)}
                      domain={[0, "dataMax + 100"]}
                    />

                    <Tooltip content={<CustomTooltip />} />

                    {selected.length === 0 &&
                      Entitys.filter((el) => el.id !== 5).map((el, i) => (
                        <Bar
                          type="monotone"
                          dataKey={el.title}
                          stroke={colors[i]}
                          fill={colors[i]}
                          stackId={i}
                          label={<CustomBarLabel />}
                        ></Bar>
                      ))}
                    {selected.length === 0 &&
                      Entitys.filter((el) => el.id !== 5).map((el, i) => (
                        <Line
                          type="monotone"
                          dataKey={el.title}
                          stroke={colors[i]}
                          fill={colors[i]}
                          stackId={i}
                        ></Line>
                      ))}
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            {selected.length === 0 &&
              entityIds.map((el, i) => (
                <Tag
                  color={
                    colors[
                      Entitys.filter((y) => y.id !== 5).findIndex(
                        (x) => el === x.id
                      )
                    ]
                  }
                >
                  {getUsername(el) + ": " + toGetTotal(el)}
                </Tag>
              ))}
          </Row>
        </>
      )}
    </>
  );
};
export default CAComponent;
