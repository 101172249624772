import {
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Skeleton,
  Tabs,
  Tag,
  Typography,
} from "antd";
import { useEffect, useRef, useState } from "react";
import Loading from "react-loading-bar";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { toGetQuotesBLEntities, toGetTotalEntities } from "./APIs";

import { toGetUsers } from "../Compte/Api";
import YearStat from "./StatsByVendeur/YearStat";
import {
  colors,
  CustomBarLabel,
  CustomTooltip,
  getUsername,
  toGetTotal,
} from "./StatsByVendeur/utils";
import QuoteBLStats from "./StatsByVendeur/QuoteBLStats";
import AbsenceStats from "./StatsByVendeur/AbsenceStats";
import { useReactToPrint } from "react-to-print";
import CAEntity from "./StatsEntity/CAEntity";
const { RangePicker } = DatePicker;

const { Option } = Select;

const StatsVendeur = ({ AppStore, ...props }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [all, setAll] = useState([]);
  const [selected, setSelectedUser] = useState([]);

  const [entityIds, setEntityIds] = useState([]);
  const componentRef = useRef < HTMLDivElement > null;

  useEffect(() => {
    AppStore.setFirst(2);
    AppStore.setSecond(22);

    toGetUsers().then((users) => {
      setUsers(users.filter((e) => e.active));

      getDataEntities(2, [users.filter((e) => e.active)[0].id]);
    });
  }, []);

  const getDataEntities = (date, usersIds) => {
    setLoading(true);
    toGetTotalEntities(date, usersIds, false).then((res) => {
      setData(res);
      setAll(res);
      setLoading(false);
    });
  };
  const onChangeUsers = (option) => {
    setData([]);
    var ids = [];
    option.forEach((element) => {
      if (element != "0") {
        ids.push(users.filter((el) => el.username == element)[0].id);
      }
    });
    setSelectedUser(ids);
    getDataEntities(2, ids);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "My Document",
  });

  return (
    <>
      <Tabs defaultActiveKey="2">
        <Tabs.TabPane tab="Magasins" key={"1"}>
          <CAEntity />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Vendeurs" key={"2"}>
          <Loading show={loading} color="red" showSpinner={true} />
          {/* <button onClick={handlePrint}>Print to PDF</button> */}
          <div>
            <Row className="auto--custom-header">
              <Col span="5">
                <Typography.Title level={3}>
                  Statistiques Vendeur
                </Typography.Title>
              </Col>
              <Col span="5">
                <Form.Item label="Vendeur">
                  {users.length > 0 && (
                    <Select
                      showSearch
                      mode="multiple"
                      onChange={onChangeUsers}
                      style={{ width: "80%" }}
                    >
                      {users
                        .filter((a) => a.active)
                        .map((u) => (
                          <Option value={u.username}>{u.username}</Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Skeleton active loading={loading} />

            {data.length > 0 && (
              <>
                <Row className="auto--custom-header">
                  <Col span="24">
                    <Typography.Title level={3}>
                      Chiffres annuelle cette année
                    </Typography.Title>

                    <div style={{ height: 600, marginTop: "4vh" }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                          data={data}
                          margin={{
                            top: 10,
                            right: 30,
                            left: 50,
                            bottom: 0,
                          }}
                        >
                          <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

                          <XAxis dataKey="date" />
                          <YAxis
                            type="number"
                            dataKey={(v) => parseInt(v.valeur)}
                            domain={[0, "dataMax + 100"]}
                          />

                          <Tooltip content={<CustomTooltip />} />
                          {selected.length > 0 &&
                            selected.map((el, i) => (
                              <Bar
                                type="monotone"
                                dataKey={getUsername(el, users)}
                                stroke={colors[i]}
                                fill={colors[i]}
                                stackId={i}
                                label={<CustomBarLabel />}
                              ></Bar>
                            ))}
                          {selected.length > 0 &&
                            selected.map((el, i) => (
                              <Line
                                type="monotone"
                                dataKey={getUsername(el, users)}
                                stroke={colors[i]}
                                fill={colors[i]}
                                stackId={i}
                              ></Line>
                            ))}
                        </ComposedChart>
                      </ResponsiveContainer>
                    </div>
                  </Col>
                </Row>
                <Row justify="center">
                  {selected.length > 0 &&
                    selected.map((el, i) => (
                      <Tag color={colors[i]}>
                        {getUsername(el, users) +
                          ": " +
                          toGetTotal(el, data, users)}
                      </Tag>
                    ))}
                </Row>
              </>
            )}
            <YearStat selected={selected} users={users} />
            <QuoteBLStats selected={selected} users={users} />
            <AbsenceStats selected={selected} users={users} />
          </div>
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default StatsVendeur;
