import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  PageHeader,
  Input,
  Form,
  InputNumber,
  Divider,
  Typography,
  DatePicker,
  Switch,
  Alert,
  Tooltip,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import localization from "moment/locale/fr";
import { errorMsg } from "../../../Utils/Utils";

moment.updateLocale("fr", localization);

const ReglementBLList = ({ bls, validate, setRemiseChecked }) => {
  const [form] = Form.useForm();
  const [selectedPayment, setSelectedPayment] = useState([]);
  const [reste, setReste] = useState(0);
  const [valideClicked, setValidateClicked] = useState(false);
  const [checked, setChecked] = useState(false);
  const espece = { montant: 0, type: 0 };
  const cheque = {
    banque: "",
    date: 0,
    num: "",
    montant: 0,
    type: 1,
    chequeValue: 0,
  };
  const traite = { banque: "", date: 0, num: "", montant: reste, type: 2 };

  useEffect(() => {
    var reglementsPaied = 0;
    var value = 0;
    var array = [];
    var total = getTotalBls();
    bls.forEach((bl) => {
      reglementsPaied = bl.reglements.reduce((a, b) => b.montant + a, 0);
      value += (bl.total - reglementsPaied).toFixed(3);
      array = [...array, ...bl.reglements];
      array.forEach((e) => {
        if (e.date) e.date = moment(e.date);
      });
    });

    form.setFieldsValue({
      reste: value < 0 ? 0 : value,
      montant: total.toFixed(3),
    });
    setReste(value);
    setSelectedPayment(array);
    setValidateClicked(false);
  }, [bls]);

  const deleteElement = (i) => {
    let items = [...selectedPayment];
    items[i]["montant"] = 0;
    items.splice(i, 1);
    setSelectedPayment(items);
    setReste(getReste());
  };

  const getTotalBls = () => {
    var value = 0;
    bls.forEach((bl) => {
      value += bl.total;
    });
    return value;
  };

  const getReste = () => {
    if (!bls) return 0;
    var total = getTotalBls();
    var value = (
      total - selectedPayment.reduce((a, b) => b.montant + a, 0)
    ).toFixed(3);
    form.setFieldsValue({
      reste: value < 0 ? 0 : value,
      montant: total.toFixed(3),
    });
    return value < 0 ? 0 : value;
  };

  const onChange = (i, attribut, value) => {
    let items = [...selectedPayment];
    items[i][attribut] = value;
    setSelectedPayment(items);
    setReste(getReste());
  };

  const validatePaiement = () => {
    if (verifyFields()) {
      setValidateClicked(true);
      validate(selectedPayment.filter((el) => el.caisseValue == null));
    } else {
      errorMsg("Merci de vérifier que tout les champs sont remplis!");
    }
  };

  const verifyFields = () => {
    var res = true;
    selectedPayment.map((element) => {
      switch (element.type) {
        case 0:
        case 3:
        case 4:
        case 5:
        case 6:
          if (element.montant == 0) {
            res = false;
          }
          break;
        case 1:
        case 2:
          if (
            element.montant == 0 ||
            element.banque == "" ||
            element.num == 0 ||
            element.date == "" ||
            element.nom == ""
          ) {
            res = false;
          }
          break;

        default:
          break;
      }
    });
    return res;
  };

  return (
    <div
      style={{
        marginLeft: "1vh",
        flex: 1,
        backgroundColor: "1px solid ",
      }}
    >
      <PageHeader>
        <Form form={form} layout="vertical">
          <Row justify="center">
            <Col span={6}>
              <Form.Item label="Montant total" name="montant">
                <Input type="" readOnly={true} addonAfter="DT" />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item label="Reste" name="reste" value={reste}>
                <Input
                  readOnly={true}
                  defaultValue={reste}
                  addonAfter="DT"
                  value="0"
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              {getReste() > 0 && valideClicked && (
                <Form.Item
                  label="Remise"
                  name="remise"
                  style={{ textAlign: "center" }}
                >
                  <Tooltip
                    title={
                      "Pour mettre le reste(" +
                      getReste() +
                      " DT) en tant que remise"
                    }
                    color="#58c5cc"
                    placement="leftTop"
                  >
                    <Switch
                      checked={checked}
                      onChange={() => {
                        setChecked(!checked);
                        setRemiseChecked(!checked);
                      }}
                    />
                  </Tooltip>
                </Form.Item>
              )}
            </Col>
          </Row>
        </Form>
      </PageHeader>
      <Row>
        <Col span={6}>
          <Card style={{ border: "0px solid transparent" }}>
            <Button
              style={{
                marginBottom: "5px",
                width: "100%",
                textAlign: "left",
              }}
              onClick={() => {
                setSelectedPayment([
                  ...selectedPayment,
                  { ...espece, montant: getReste() },
                ]);
                setReste(getReste());
              }}
            >
              Espèces
            </Button>

            <Button
              style={{
                marginBottom: "5px",
                width: "100%",
                textAlign: "left",
              }}
              onClick={() =>
                setSelectedPayment([
                  ...selectedPayment,
                  { ...cheque, montant: getReste() },
                ])
              }
            >
              Chèque
            </Button>

            <Button
              style={{
                marginBottom: "5px",
                width: "100%",
                textAlign: "left",
              }}
              onClick={() =>
                setSelectedPayment([
                  ...selectedPayment,
                  { ...traite, montant: getReste() },
                ])
              }
            >
              Traites
            </Button>

            <Button
              style={{
                marginBottom: "5px",
                width: "100%",
                textAlign: "left",
              }}
              onClick={() =>
                setSelectedPayment([
                  ...selectedPayment,
                  { montant: getReste(), type: 3 },
                ])
              }
            >
              Carte bancaire
            </Button>

            <Button
              style={{
                marginBottom: "5px",
                width: "100%",
                textAlign: "left",
              }}
              onClick={() =>
                setSelectedPayment([
                  ...selectedPayment,
                  { montant: getReste(), type: 4 },
                ])
              }
            >
              Virement bancaire
            </Button>

            <Button
              style={{
                marginBottom: "5px",
                width: "100%",
                textAlign: "left",
              }}
              disabled={getTotalBls() < 1000}
              onClick={() =>
                setSelectedPayment([
                  ...selectedPayment,
                  {
                    montant: (getTotalBls() * 99) / 100,
                    type: 5,
                  },
                ])
              }
            >
              Retenu à la source
            </Button>

            <Button
              onClick={() =>
                setSelectedPayment([
                  ...selectedPayment,
                  { montant: getReste(), type: 6 },
                ])
              }
              style={{
                marginBottom: "5px",
                width: "100%",
                textAlign: "left",
              }}
            >
              Contre remboursement
            </Button>

            <Button
              //  disabled={bls[0].client && !bls[0].client.b__billPerMonth}
              onClick={() =>
                setSelectedPayment([
                  ...selectedPayment,
                  { montant: 0, type: 7 },
                ])
              }
              style={{
                marginBottom: "5px",
                width: "100%",
                textAlign: "left",
              }}
            >
              Caisse
            </Button>
          </Card>
        </Col>
        <Col span={16} style={{ overflowX: "auto", marginLeft: "2vh" }}>
          {!valideClicked ? (
            <Alert
              message="Paiement non encore validée, merci de le valider!"
              type="error"
              style={{ marginBottom: 10 }}
            />
          ) : (
            <Alert
              message="Paiement validée!"
              type="success"
              style={{ marginBottom: 10 }}
            />
          )}
          {selectedPayment.filter((e) => e.type === 0).length > 0 && (
            <>
              <Typography.Title level={5}> Espèces</Typography.Title>
              <table className="ant-table" style={{ width: "100%" }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell"></th>
                    <th className="ant-table-cell">Montant</th>
                  </tr>
                </thead>
                {selectedPayment.map((element, i) => (
                  <>
                    {element.type == 0 && (
                      <tr>
                        <td
                          className="ant-table-cell"
                          style={{ width: "40px" }}
                        >
                          {(element.caisseValue == null ||
                            element.caisseValue == 0) && (
                            <Button
                              icon={<DeleteOutlined />}
                              onClick={() => deleteElement(i)}
                            />
                          )}
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            onChange={(e) => onChange(i, "montant", e)}
                            value={element.montant}
                            decimalSeparator={","}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </table>
              <Divider />
            </>
          )}
          {selectedPayment.filter((e) => e.type === 1).length > 0 && (
            <>
              <Typography.Title level={5}> Chèquess</Typography.Title>
              <table className="ant-table" style={{ width: "100%" }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell"></th>
                    <th className="ant-table-cell">Nom</th>
                    <th className="ant-table-cell">Montant</th>
                    <th className="ant-table-cell">Valeur de cheque</th>
                    <th className="ant-table-cell">Num</th>
                    <th className="ant-table-cell">Banque</th>
                    <th className="ant-table-cell">Date</th>
                  </tr>
                </thead>
                {selectedPayment.map((element, i) => (
                  <>
                    {element.type == 1 && (
                      <tr>
                        <td className="ant-table-cell">
                          {(element.caisseValue == null ||
                            element.caisseValue == 0) && (
                            <Button
                              icon={<DeleteOutlined />}
                              onClick={() => deleteElement(i)}
                            />
                          )}
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            value={element.nom}
                            onChange={(e) => onChange(i, "nom", e.target.value)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            decimalSeparator={","}
                            value={element.montant}
                            onChange={(e) => onChange(i, "montant", e)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            decimalSeparator={","}
                            value={element.chequeValue}
                            onChange={(e) => onChange(i, "chequeValue", e)}
                          />
                        </td>

                        <td className="ant-table-cell">
                          <InputNumber
                            value={element.num}
                            onChange={(e) => onChange(i, "num", e)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            value={element.banque}
                            onChange={(e) =>
                              onChange(i, "banque", e.target.value)
                            }
                          />
                        </td>
                        <td className="ant-table-cell">
                          <DatePicker
                            value={element.date}
                            onChange={(e) => onChange(i, "date", e)}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </table>
              <Divider />
            </>
          )}
          {selectedPayment.filter((e) => e.type === 2).length > 0 && (
            <>
              <Typography.Title level={5}> Traites</Typography.Title>
              <table className="ant-table" style={{ width: "100%" }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell"></th>
                    <th className="ant-table-cell">Nom</th>
                    <th className="ant-table-cell">Montant</th>
                    <th className="ant-table-cell">Num</th>
                    <th className="ant-table-cell">Banque</th>
                    <th className="ant-table-cell">Date</th>
                  </tr>
                </thead>
                {selectedPayment.map((element, i) => (
                  <>
                    {element.type == 2 && (
                      <tr>
                        <td className="ant-table-cell">
                          {(element.caisseValue == null ||
                            element.caisseValue == 0) && (
                            <Button
                              icon={<DeleteOutlined />}
                              onClick={() => deleteElement(i)}
                            />
                          )}
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            value={element.nom}
                            onChange={(e) => onChange(i, "nom", e.target.value)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            decimalSeparator={","}
                            value={element.montant}
                            onChange={(e) => onChange(i, "montant", e)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            value={element.num}
                            onChange={(e) => onChange(i, "num", e)}
                          />
                        </td>
                        <td className="ant-table-cell">
                          <Input
                            value={element.banque}
                            onChange={(e) =>
                              onChange(i, "banque", e.target.value)
                            }
                          />
                        </td>
                        <td className="ant-table-cell">
                          <DatePicker
                            value={element.date}
                            onChange={(e) => onChange(i, "date", e)}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </table>
              <Divider />
            </>
          )}
          {selectedPayment.filter((e) => e.type === 3).length > 0 && (
            <>
              <Typography.Title level={5}> Carte bancaire</Typography.Title>
              <table className="ant-table" style={{ width: "100%" }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell"></th>
                    <th className="ant-table-cell">Montant</th>
                  </tr>
                </thead>
                {selectedPayment.map((element, i) => (
                  <>
                    {element.type == 3 && (
                      <tr>
                        <td
                          className="ant-table-cell"
                          style={{ width: "40px" }}
                        >
                          {(element.caisseValue == null ||
                            element.caisseValue == 0) && (
                            <Button
                              icon={<DeleteOutlined />}
                              onClick={() => deleteElement(i)}
                            />
                          )}
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            onChange={(e) => onChange(i, "montant", e)}
                            value={element.montant}
                            decimalSeparator={","}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </table>
              <Divider />
            </>
          )}
          {selectedPayment.filter((e) => e.type === 4).length > 0 && (
            <>
              <Typography.Title level={4}> Virement bancaire</Typography.Title>
              <table className="ant-table" style={{ width: "100%" }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell"></th>
                    <th className="ant-table-cell">Montant</th>
                  </tr>
                </thead>
                {selectedPayment.map((element, i) => (
                  <>
                    {element.type == 4 && (
                      <tr>
                        <td
                          className="ant-table-cell"
                          style={{ width: "40px" }}
                        >
                          {(element.caisseValue == null ||
                            element.caisseValue == 0) && (
                            <Button
                              icon={<DeleteOutlined />}
                              onClick={() => deleteElement(i)}
                            />
                          )}
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            onChange={(e) => onChange(i, "montant", e)}
                            value={element.montant}
                            decimalSeparator={","}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </table>
              <Divider />
            </>
          )}
          {selectedPayment.filter((e) => e.type === 5).length > 0 && (
            <>
              <Typography.Title level={5}>Retenu à la source</Typography.Title>
              <table className="ant-table" style={{ width: "100%" }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell"></th>
                    <th className="ant-table-cell">Montant</th>
                  </tr>
                </thead>
                {selectedPayment.map((element, i) => (
                  <>
                    {element.type == 5 && (
                      <tr>
                        <td
                          className="ant-table-cell"
                          style={{ width: "40px" }}
                        >
                          {(element.caisseValue == null ||
                            element.caisseValue == 0) && (
                            <Button
                              icon={<DeleteOutlined />}
                              onClick={() => deleteElement(i)}
                            />
                          )}
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            onChange={(e) => onChange(i, "montant", e)}
                            value={element.montant}
                            decimalSeparator={","}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </table>
              <Divider />
            </>
          )}
          {selectedPayment.filter((e) => e.type === 6).length > 0 && (
            <>
              <Typography.Title level={5}>
                Contre remboursement
              </Typography.Title>
              <table className="ant-table" style={{ width: "100%" }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell"></th>
                    <th className="ant-table-cell">Montant</th>
                  </tr>
                </thead>
                {selectedPayment.map((element, i) => (
                  <>
                    {element.type == 6 && (
                      <tr>
                        <td
                          className="ant-table-cell"
                          style={{ width: "40px" }}
                        >
                          {(element.caisseValue == null ||
                            element.caisseValue == 0) && (
                            <Button
                              icon={<DeleteOutlined />}
                              onClick={() => deleteElement(i)}
                            />
                          )}
                        </td>
                        <td className="ant-table-cell">
                          <InputNumber
                            onChange={(e) => onChange(i, "montant", e)}
                            value={element.montant}
                            decimalSeparator={","}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </table>
              <Divider />
            </>
          )}
          {selectedPayment.length > 0 && (
            <Button
              disabled={valideClicked}
              type="primary"
              style={{ marginLeft: "1vh" }}
              onClick={() => validatePaiement()}
            >
              valider
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ReglementBLList;
