import { Layout } from "antd";
import React, { useEffect } from "react";
import CustomMenu from "../Components/CustomMenu";
import Navigation from "../Components/Navigation";
import StockTicker from "./StockTicker";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const { Header, Content } = Layout;

const TopNavLayout = ({ children, AppStore, props, user }) => {
  useEffect(() => {}, []);

  return (
    <>
      {user == null ? (
        <Redirect to="/login" />
      ) : (
        <Layout style={{ height: "100vh" }}>
          <Header style={{ padding: 0 }}>
            <Navigation AppStore={AppStore} mode="horizontal" />
          </Header>
          <Layout style={{ flex: 1 }}>
            <CustomMenu AppStore={AppStore} />
            <StockTicker />
            <Content
              style={{
                padding: 24,
                overflowY: "auto",
              }}
            >
              {children}
            </Content>
          </Layout>
        </Layout>
      )}
    </>
  );
};

export default TopNavLayout;
