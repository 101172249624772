import {
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import { toValidateReglement } from "../../Vente/APIs";
import cookie from "react-cookies";
import ModalCode from "../../Vente/ModalCode";
import { DeleteOutlined } from "@ant-design/icons";
import { toUpdateCaisseValue } from "../../Users/APIs";
import {
  toGetNotificationItem,
  toGetNotifications,
  toGetUsers,
  updateCaisseNotifByCode,
} from "../../Compte/Api";
import { successMsg } from "../../../Utils/Utils";

const AlertCaisseValidate = ({ id, open, close }) => {
  const [form] = Form.useForm();
  const [notifications, setNotifications] = useState([]);
  const [users, setUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [isModalCodeOpen, setModalCodeOpen] = useState(false);
  const [caisseNotifId, setCaisseNotifId] = useState(-1);
  const [selectedCaisse, setSelectedCaisse] = useState(null);
  const [value, setValue] = useState(-1);
  const [deletedItems, setDeletedItems] = useState([]);
  const espece = { montant: 0, type: 0 };
  const cheque = {
    banque: "",
    date: 0,
    num: "",
    montant: 0,
    type: 1,
    chequeValue: 0,
  };
  const traite = { banque: "", date: 0, num: "", montant: 0, type: 2 };
  const [selectedPayment, setSelectedPayment] = useState([]);

  useEffect(() => {
    toGetUsers().then((users) => {
      setUsers(users);
    });
  }, []);

  const getReste = (notification) => {
    return (
      parseFloat(notification.total) -
      selectedPayment.reduce((a, b) => a + parseFloat(b.montant), 0)
    ).toFixed(3);
  };

  const toUpdateCaisseNotif = () => {
    updateCaisseNotifByCode(caisseNotifId).then((res) => {
      getNotifications();
      setCaisseNotifId(-1);
      setModalCodeOpen(false);
    });
  };

  useEffect(() => {
    console.log({ id, open });
    if (id && open) getNotifications(id);
  }, [id, open]);
  const getNotifications = (id) => {
    toGetNotificationItem(id).then((res) => {
      if (res.length > 0) {
        setNotifications([res[0]]);
        var list = [];
        res[0].reglements.map((reglement) =>
          list.push({
            ...reglement.data,
            vendeur: true,
            id: reglement.id,
            caisseValue: reglement.caisseValue,
          })
        );

        setSelectedPayment(list);
        form.setFieldsValue({
          value: res[0].caisseValue,
          //date: moment(res[0].date),
        });
      } else {
        setNotifications([]);
      }
    });
  };

  const validateCaisse = (blId, type) => {
    setClicked(true);

    form.validateFields().then((values) => {
      const l__id =
        users.findIndex((e) => e.username == values.driver) !== -1
          ? users[users.findIndex((e) => e.username == values.driver)].id
          : -1;
      try {
        setIsOpen(true);

        toValidateReglement(
          selectedPayment,
          deletedItems,
          l__id,
          blId,
          type
        ).then((res) => {
          setIsOpen(false);
          close();
          setClicked(false);
        });
      } catch (error) {
        setClicked(false);
      }
    });
  };
  const onChange = (i, attribut, value) => {
    let items = [...selectedPayment];
    items[i][attribut] = value;
    setSelectedPayment(items);
  };

  const deleteElement = (i) => {
    let items = [...selectedPayment];
    items[i]["montant"] = 0;
    if (items[i].id) setDeletedItems([...deletedItems, items[i]]);
    items.splice(i, 1);
    setSelectedPayment(items);
  };

  const updateCaisseValue = () => {
    toUpdateCaisseValue(selectedCaisse.entityId, value).then((res) => {
      successMsg("Valeur de caisse modifié avec succés!");
      setSelectedCaisse(null);
    });
  };

  return (
    <>
      {notifications.map((n) => (
        <Modal
          visible={open}
          className="smtv-modal modal-large "
          title="Alert Caisse!"
          onCancel={() => close()}
          footer={
            n.responsibleId == cookie.load("id")
              ? [
                  <Button
                    type="primary"
                    disabled={clicked}
                    onClick={() => validateCaisse(n.entityId, n.type)}
                  >
                    {clicked ? (
                      <Spin size="small" style={{ marginLeft: 10 }} />
                    ) : (
                      <>Valider</>
                    )}
                  </Button>,
                  <Button
                    type="danger"
                    onClick={() => {
                      setModalCodeOpen(true);
                      setCaisseNotifId(n.id);
                    }}
                  >
                    Valider par code
                  </Button>,
                  <Button type="ghost" onClick={() => getNotifications()}>
                    Actualiser
                  </Button>,
                ]
              : [
                  <Button
                    type="danger"
                    disabled={clicked}
                    onClick={() => {
                      setModalCodeOpen(true);
                      setCaisseNotifId(n.id);
                    }}
                  >
                    Valider par code
                  </Button>,
                  <Button
                    type="dashed"
                    onClick={() => {
                      setValue(n.caisseValue);
                      setSelectedCaisse(n);
                    }}
                  >
                    Modifier valeur de caisse
                  </Button>,
                  <Button type="ghost" onClick={() => getNotifications()}>
                    Actualiser
                  </Button>,
                ]
          }
        >
          {n.responsibleId == cookie.load("id") ? (
            <>
              <Descriptions layout="vertical" bordered size={2}>
                <Descriptions.Item label="Total BL">
                  {n?.total}
                </Descriptions.Item>
                <Descriptions.Item label="Valeur de Caisse">
                  {n?.caisseValue}
                </Descriptions.Item>
                <Descriptions.Item label="Reste">
                  {getReste(n)}
                </Descriptions.Item>
              </Descriptions>
              <p>
                Est ce vous avez reçu le réglement du <b>{n.type}</b> N°
                <b>{n.entityRef}</b> du vendeur
                <b> {n.vendeur}</b>
              </p>
              <Row>
                <Col span={6}>
                  <Card style={{ border: "0px solid transparent" }}>
                    <Button
                      style={{
                        marginBottom: "5px",
                        width: "100%",
                        textAlign: "left",
                      }}
                      onClick={() => {
                        setSelectedPayment([
                          ...selectedPayment,
                          { ...espece, montant: getReste(n) },
                        ]);
                      }}
                    >
                      Espèces
                    </Button>

                    <Button
                      style={{
                        marginBottom: "5px",
                        width: "100%",
                        textAlign: "left",
                      }}
                      onClick={() =>
                        setSelectedPayment([
                          ...selectedPayment,
                          { ...cheque, montant: getReste(n) },
                        ])
                      }
                    >
                      Chèque
                    </Button>

                    <Button
                      style={{
                        marginBottom: "5px",
                        width: "100%",
                        textAlign: "left",
                      }}
                      onClick={() =>
                        setSelectedPayment([
                          ...selectedPayment,
                          { ...traite, montant: getReste(n) },
                        ])
                      }
                    >
                      Traites
                    </Button>

                    <Button
                      style={{
                        marginBottom: "5px",
                        width: "100%",
                        textAlign: "left",
                      }}
                      onClick={() =>
                        setSelectedPayment([
                          ...selectedPayment,
                          { montant: getReste(n), type: 3 },
                        ])
                      }
                    >
                      Carte bancaire
                    </Button>

                    <Button
                      style={{
                        marginBottom: "5px",
                        width: "100%",
                        textAlign: "left",
                      }}
                      onClick={() =>
                        setSelectedPayment([
                          ...selectedPayment,
                          { montant: getReste(n), type: 4 },
                        ])
                      }
                    >
                      Virement bancaire
                    </Button>

                    <Button
                      style={{
                        marginBottom: "5px",
                        width: "100%",
                        textAlign: "left",
                      }}
                      disabled={n.caisseValue < 1000}
                      onClick={() =>
                        setSelectedPayment([
                          ...selectedPayment,
                          {
                            montant: (n.caisseValue * 99) / 100,
                            type: 5,
                          },
                        ])
                      }
                    >
                      Retenu à la source
                    </Button>

                    <Button
                      onClick={() =>
                        setSelectedPayment([
                          ...selectedPayment,
                          { montant: getReste(n), type: 6 },
                        ])
                      }
                      style={{
                        marginBottom: "5px",
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      Contre remboursement
                    </Button>

                    <Button
                      onClick={() =>
                        setSelectedPayment([
                          ...selectedPayment,
                          { montant: 0, type: 7 },
                        ])
                      }
                      style={{
                        marginBottom: "5px",
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      Caisse
                    </Button>
                    <Button
                      onClick={() =>
                        setSelectedPayment([
                          ...selectedPayment,
                          { montant: getReste(n), type: 99 },
                        ])
                      }
                      style={{
                        marginBottom: "5px",
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      Remise
                    </Button>
                  </Card>
                </Col>
                <Col span={18}>
                  {selectedPayment.filter((e) => e.type === 0).length > 0 && (
                    <>
                      <Typography.Title level={5}> Espèces</Typography.Title>
                      <table className="ant-table" style={{ width: "100%" }}>
                        <thead className="ant-table-thead">
                          <tr>
                            <th className="ant-table-cell"></th>
                            <th className="ant-table-cell">Montant</th>
                          </tr>
                        </thead>
                        {selectedPayment.map((element, i) => (
                          <>
                            {element.type == 0 && (
                              <tr>
                                <td
                                  className="ant-table-cell"
                                  style={{ width: "40px" }}
                                >
                                  {(element.caisseValue == null ||
                                    element.caisseValue == 0) && (
                                    <Button
                                      icon={<DeleteOutlined />}
                                      onClick={() => deleteElement(i)}
                                    />
                                  )}
                                </td>
                                <td className="ant-table-cell">
                                  <InputNumber
                                    disabled={element.vendeur}
                                    onChange={(e) => onChange(i, "montant", e)}
                                    value={element.montant}
                                    decimalSeparator={","}
                                  />
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                      </table>
                      <Divider />
                    </>
                  )}
                  {selectedPayment.filter((e) => e.type === 99).length > 0 && (
                    <>
                      <Typography.Title level={5}> Remise</Typography.Title>
                      <table className="ant-table" style={{ width: "100%" }}>
                        <thead className="ant-table-thead">
                          <tr>
                            <th className="ant-table-cell"></th>
                            <th className="ant-table-cell">Montant</th>
                          </tr>
                        </thead>
                        {selectedPayment.map((element, i) => (
                          <>
                            {element.type == 99 && (
                              <tr>
                                <td
                                  className="ant-table-cell"
                                  style={{ width: "40px" }}
                                >
                                  {(element.caisseValue == null ||
                                    element.caisseValue == 0) && (
                                    <Button
                                      icon={<DeleteOutlined />}
                                      onClick={() => deleteElement(i)}
                                    />
                                  )}
                                </td>
                                <td className="ant-table-cell">
                                  <InputNumber
                                    disabled={element.vendeur}
                                    onChange={(e) => onChange(i, "montant", e)}
                                    value={element.montant}
                                    decimalSeparator={","}
                                  />
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                      </table>
                      <Divider />
                    </>
                  )}
                  {selectedPayment.filter((e) => e.type === 1).length > 0 && (
                    <>
                      <Typography.Title level={5}> Chèques</Typography.Title>
                      <table className="ant-table" style={{ width: "100%" }}>
                        <thead className="ant-table-thead">
                          <tr>
                            <th className="ant-table-cell"></th>
                            <th className="ant-table-cell">Nom</th>
                            <th className="ant-table-cell">Montant</th>
                            <th className="ant-table-cell">Valeur de cheque</th>
                            <th className="ant-table-cell">Num</th>
                            <th className="ant-table-cell">Banque</th>
                            <th className="ant-table-cell">Date</th>
                          </tr>
                        </thead>
                        {selectedPayment.map((element, i) => (
                          <>
                            {element.type == 1 && (
                              <tr>
                                <td className="ant-table-cell">
                                  {(element.caisseValue == null ||
                                    element.caisseValue == 0) && (
                                    <Button
                                      icon={<DeleteOutlined />}
                                      onClick={() => deleteElement(i)}
                                    />
                                  )}
                                </td>
                                <td className="ant-table-cell">
                                  <Input
                                    value={element.nom}
                                    onChange={(e) =>
                                      onChange(i, "nom", e.target.value)
                                    }
                                    disabled={element.vendeur}
                                  />
                                </td>
                                <td className="ant-table-cell">
                                  <InputNumber
                                    disabled={element.vendeur}
                                    decimalSeparator={","}
                                    value={element.montant}
                                    onChange={(e) => onChange(i, "montant", e)}
                                  />
                                </td>
                                <td className="ant-table-cell">
                                  <InputNumber
                                    disabled={element.vendeur}
                                    decimalSeparator={","}
                                    value={element.chequeValue}
                                    onChange={(e) =>
                                      onChange(i, "chequeValue", e)
                                    }
                                  />
                                </td>

                                <td className="ant-table-cell">
                                  <InputNumber
                                    disabled={element.vendeur}
                                    value={element.num}
                                    onChange={(e) => onChange(i, "num", e)}
                                  />
                                </td>
                                <td className="ant-table-cell">
                                  <Input
                                    disabled={element.vendeur}
                                    value={element.banque}
                                    onChange={(e) =>
                                      onChange(i, "banque", e.target.value)
                                    }
                                  />
                                </td>
                                <td className="ant-table-cell">
                                  <DatePicker
                                    // value={element.date}
                                    disabled={element.vendeur}
                                    onChange={(e) => onChange(i, "date", e)}
                                  />
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                      </table>
                      <Divider />
                    </>
                  )}
                  {selectedPayment.filter((e) => e.type === 2).length > 0 && (
                    <>
                      <Typography.Title level={5}> Traites</Typography.Title>
                      <table className="ant-table" style={{ width: "100%" }}>
                        <thead className="ant-table-thead">
                          <tr>
                            <th className="ant-table-cell"></th>
                            <th className="ant-table-cell">Nom</th>
                            <th className="ant-table-cell">Montant</th>
                            <th className="ant-table-cell">Num</th>
                            <th className="ant-table-cell">Banque</th>
                            <th className="ant-table-cell">Date</th>
                          </tr>
                        </thead>
                        {selectedPayment.map((element, i) => (
                          <>
                            {element.type == 2 && (
                              <tr>
                                <td className="ant-table-cell">
                                  {(element.caisseValue == null ||
                                    element.caisseValue == 0) && (
                                    <Button
                                      icon={<DeleteOutlined />}
                                      onClick={() => deleteElement(i)}
                                    />
                                  )}
                                </td>
                                <td className="ant-table-cell">
                                  <Input
                                    disabled={element.vendeur}
                                    value={element.nom}
                                    onChange={(e) =>
                                      onChange(i, "nom", e.target.value)
                                    }
                                  />
                                </td>
                                <td className="ant-table-cell">
                                  <InputNumber
                                    disabled={element.vendeur}
                                    decimalSeparator={","}
                                    value={element.montant}
                                    onChange={(e) => onChange(i, "montant", e)}
                                  />
                                </td>
                                <td className="ant-table-cell">
                                  <InputNumber
                                    disabled={element.vendeur}
                                    value={element.num}
                                    onChange={(e) => onChange(i, "num", e)}
                                  />
                                </td>
                                <td className="ant-table-cell">
                                  <Input
                                    disabled={element.vendeur}
                                    value={element.banque}
                                    onChange={(e) =>
                                      onChange(i, "banque", e.target.value)
                                    }
                                  />
                                </td>
                                <td className="ant-table-cell">
                                  <DatePicker
                                    disabled={element.vendeur}
                                    value={moment(element.date)}
                                    onChange={(e) => onChange(i, "date", e)}
                                  />
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                      </table>
                      <Divider />
                    </>
                  )}
                  {selectedPayment.filter((e) => e.type === 3).length > 0 && (
                    <>
                      <Typography.Title level={5}>
                        Carte bancaire
                      </Typography.Title>
                      <table className="ant-table" style={{ width: "100%" }}>
                        <thead className="ant-table-thead">
                          <tr>
                            <th className="ant-table-cell"></th>
                            <th className="ant-table-cell">Montant</th>
                          </tr>
                        </thead>
                        {selectedPayment.map((element, i) => (
                          <>
                            {element.type == 3 && (
                              <tr>
                                <td
                                  className="ant-table-cell"
                                  style={{ width: "40px" }}
                                >
                                  {(element.caisseValue == null ||
                                    element.caisseValue == 0) && (
                                    <Button
                                      icon={<DeleteOutlined />}
                                      onClick={() => deleteElement(i)}
                                    />
                                  )}
                                </td>
                                <td className="ant-table-cell">
                                  <InputNumber
                                    disabled={element.vendeur}
                                    onChange={(e) => onChange(i, "montant", e)}
                                    value={element.montant}
                                    decimalSeparator={","}
                                  />
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                      </table>
                      <Divider />
                    </>
                  )}
                  {selectedPayment.filter((e) => e.type === 4).length > 0 && (
                    <>
                      <Typography.Title level={4}>
                        Virement bancaire
                      </Typography.Title>
                      <table className="ant-table" style={{ width: "100%" }}>
                        <thead className="ant-table-thead">
                          <tr>
                            <th className="ant-table-cell"></th>
                            <th className="ant-table-cell">Montant</th>
                          </tr>
                        </thead>
                        {selectedPayment.map((element, i) => (
                          <>
                            {element.type == 4 && (
                              <tr>
                                <td
                                  className="ant-table-cell"
                                  style={{ width: "40px" }}
                                >
                                  {(element.caisseValue == null ||
                                    element.caisseValue == 0) && (
                                    <Button
                                      icon={<DeleteOutlined />}
                                      onClick={() => deleteElement(i)}
                                    />
                                  )}
                                </td>
                                <td className="ant-table-cell">
                                  <InputNumber
                                    disabled={element.vendeur}
                                    onChange={(e) => onChange(i, "montant", e)}
                                    value={element.montant}
                                    decimalSeparator={","}
                                  />
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                      </table>
                      <Divider />
                    </>
                  )}
                  {selectedPayment.filter((e) => e.type === 5).length > 0 && (
                    <>
                      <Typography.Title level={5}>
                        Retenu à la source
                      </Typography.Title>
                      <table className="ant-table" style={{ width: "100%" }}>
                        <thead className="ant-table-thead">
                          <tr>
                            <th className="ant-table-cell"></th>
                            <th className="ant-table-cell">Montant</th>
                          </tr>
                        </thead>
                        {selectedPayment.map((element, i) => (
                          <>
                            {element.type == 5 && (
                              <tr>
                                <td
                                  className="ant-table-cell"
                                  style={{ width: "40px" }}
                                >
                                  {(element.caisseValue == null ||
                                    element.caisseValue == 0) && (
                                    <Button
                                      icon={<DeleteOutlined />}
                                      onClick={() => deleteElement(i)}
                                    />
                                  )}
                                </td>
                                <td className="ant-table-cell">
                                  <InputNumber
                                    disabled={element.vendeur}
                                    onChange={(e) => onChange(i, "montant", e)}
                                    value={element.montant}
                                    decimalSeparator={","}
                                  />
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                      </table>
                      <Divider />
                    </>
                  )}
                  {selectedPayment.filter((e) => e.type === 6).length > 0 && (
                    <>
                      <Typography.Title level={5}>
                        Contre remboursement
                      </Typography.Title>
                      <table className="ant-table" style={{ width: "100%" }}>
                        <thead className="ant-table-thead">
                          <tr>
                            <th className="ant-table-cell"></th>
                            <th className="ant-table-cell">Montant</th>
                          </tr>
                        </thead>
                        {selectedPayment.map((element, i) => (
                          <>
                            {element.type == 6 && (
                              <tr>
                                <td
                                  className="ant-table-cell"
                                  style={{ width: "40px" }}
                                >
                                  {(element.caisseValue == null ||
                                    element.caisseValue == 0) && (
                                    <Button
                                      icon={<DeleteOutlined />}
                                      onClick={() => deleteElement(i)}
                                    />
                                  )}
                                </td>
                                <td className="ant-table-cell">
                                  <InputNumber
                                    disabled={element.vendeur}
                                    onChange={(e) => onChange(i, "montant", e)}
                                    value={element.montant}
                                    decimalSeparator={","}
                                  />
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                      </table>
                      <Divider />
                    </>
                  )}
                  <Form layout="vertical" form={form}>
                    <Form.Item label="Reçu par" name="driver">
                      <Select showSearch>
                        {users
                          .filter((x) => x.role === "chauffeur" && x.active)
                          .map((user) => (
                            <Select.Option value={user.username}>
                              {user.username}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <p>Attendez SVP...</p>
              <p>La validation est encours à la caisse</p>
              <b>{n.type}</b> N°<b>{n.entityRef}</b> montant <b>{n.montant}</b>
              <br />
              Valeur de caisse <b>{n.caisseValue}</b>
              <div style={{ textAlign: "center" }}>
                <img src="/images/ball.gif" />
              </div>
            </>
          )}
        </Modal>
      ))}
      <ModalCode
        isAdminModalVisible={isModalCodeOpen}
        setAdminModalVisible={() => {
          setModalCodeOpen(false);
        }}
        onOk={(id) => toUpdateCaisseNotif()}
        text={"Validation de la caisse par code"}
      />
      {selectedCaisse && (
        <Modal
          onOk={() => updateCaisseValue()}
          onCancel={() => setSelectedCaisse(null)}
          visible={selectedCaisse != null}
          title="Modifier la valeur de caisse"
        >
          <b>{selectedCaisse.type}</b> N°<b>{selectedCaisse.entityRef}</b>
          <br />
          <InputNumber
            onChange={(e) => setValue(e)}
            defaultValue={selectedCaisse.caisseValue}
          />
        </Modal>
      )}
    </>
  );
};

export default AlertCaisseValidate;
