import CAxios from "../../Utils/CAxios";
import printJS from "print-js";

export const toGetTotalHT = async (date) => {
  const { data } = await CAxios.post(`/api/user/bl`, { date: date });
  return data.success && data.success.list ? data.success.list : null;
};

export const toGetCAYear = async (year) => {
  const { data } = await CAxios.post(`/api/ca/year`, { year });
  return data.success && data.success.stats ? data.success.stats : [];
};

export const toGetCAYearAchat = async (year) => {
  const { data } = await CAxios.post(`/api/achat/year`, { year });
  return data.success && data.success.stats ? data.success.stats : [];
};

export const toGetTotalClients = async (selectedDate, entityIds, entity) => {
  const { data } = await CAxios.post(`/api/clients/stat`, {
    selectedDate,
    entityIds,
    entity,
  });
  return data.success && data.success.list ? data.success.list : null;
};

export const toGetQuotesBLClients = async (selectedDate, entityIds, entity) => {
  const { data } = await CAxios.post(`/api/clients/blquote/stat`, {
    selectedDate,
    entityIds,
    entity,
  });
  return data.success && data.success.list ? data.success.list : null;
};

export const toGetCAMonth = async (year, month, users) => {
  const { data } = await CAxios.post(`/api/ca/month`, { year, month, users });
  return data.success && data.success.stats ? data.success.stats : [];
};

export const toGetCAMonthAchat = async (year, month) => {
  const { data } = await CAxios.post(`/api/achat/month`, { year, month });
  return data.success && data.success.stats ? data.success.stats : [];
};
export const toGetCAPerYear = async (years) => {
  const { data } = await CAxios.post(`/api/ca/years`, { years });
  return data.success && data.success.stats ? data.success.stats : [];
};
export const toGetCAAchatPerYear = async (years) => {
  const { data } = await CAxios.post(`/api/ca_achat/years`, { years });
  return data.success && data.success.stats ? data.success.stats : [];
};

export const toGetTotalTTC = async (date, entityId) => {
  const { data } = await CAxios.post(`/api/user/blttc`, {
    date: date,
    entityId: entityId,
  });
  return data.success && data.success.list ? data.success.list : null;
};

export const toGetTotalUser = async (start, end, ids) => {
  const { data } = await CAxios.post(`/api/user/total`, {
    start: start,
    end: end,
    users: ids,
  });
  return data.success && data.success.list ? data.success.list : null;
};

export const toGetTotalEntities = async (selectedDate, entityIds, entity) => {
  const { data } = await CAxios.post(`/api/entities/stat`, {
    selectedDate,
    entityIds,
    entity,
  });
  return data.success && data.success.list ? data.success.list : null;
};

export const toGetQuotesBLEntities = async (
  selectedDate,
  entityIds,
  entity
) => {
  const { data } = await CAxios.post(`/api/entities/blquote/stat`, {
    selectedDate,
    entityIds,
    entity,
  });
  return data.success && data.success.list ? data.success.list : null;
};

export const toGetAbsences = async (entityIds) => {
  const { data } = await CAxios.post(`/api/absence/stat`, {
    entityIds,
  });
  return data.success && data.success.list ? data.success.list : null;
};

export const toGetCaisseData = async (caisseId) => {
  const { data } = await CAxios.post(`/api/caisse/all`, {
    caisseId,
  });
  return data.success && data.success.list ? data.success.list : null;
};

export const toGetCaisseUser = async (date, id) => {
  const { data } = await CAxios.post(`/api/caisse/user`, { id, date });
  return data.success && data.success.caisses ? data.success.caisses : [];
};

export const toPrintFiche = async (caisseId) => {
  try {
    const { data } = await CAxios.get(`/api/fiche/print/${caisseId}`, {
      responseType: "blob",
      timeout: 300000,
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    console.log(e);
    return null;
  }
};
export const toPrintExercice = async (year, id) => {
  try {
    const { data } = await CAxios.get(`/api/exercice/print/${year}/${id}`, {
      responseType: "blob",
      timeout: 300000,
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    console.log(e);
    return null;
  }
};
export const toAddDivers = async (datas) => {
  const { data } = await CAxios.post(`/api/divers/add`, datas);
  return data.success && data.success.divers ? data.success.divers : null;
};

export const toRemoveDivers = async (id) => {
  const { data } = await CAxios.post(`/api/divers/remove`, { id });
  return data.success && data.success.divers ? data.success.divers : null;
};

export const toGetDivers = async (caisseId) => {
  const { data } = await CAxios.post(`/api/divers/list`, {
    caisseId,
  });
  return data.success && data.success.divers ? data.success.divers : [];
};
export const toGetAccomptes = async (caisseId) => {
  const { data } = await CAxios.post(`/api/advance/list`, {
    caisseId,
  });
  return data.success && data.success.advance ? data.success.advance : [];
};

export const toGetListDelivery = async (start) => {
  const { data } = await CAxios.post(`/api/delivery/list`, {
    date: start,
  });
  return data.success && data.success.list
    ? {
        list: data.success.list,
        caisses: data.success.caisses,
      }
    : {
        list: [],
        caisses: [],
      };
};

export const toRemoveDelivery = async (id) => {
  const { data } = await CAxios.post(`/api/delivery/remove`, { id });
  return data.success && data.success.delivery ? data.success.delivery : null;
};

export const toUpdateCaisseValue = async (id, value) => {
  const { data } = await CAxios.post(`/api/caisse/update`, { id, value });
  return data.success && data.success.caisse ? data.success.caisse : null;
};

export const toGetNewAuthorizations = async () => {
  const { data } = await CAxios.post(`/api/authorizations/nb`);
  return data.success && data.success.nb ? data.success.nb : 0;
};

export const toGetNewQuotes = async () => {
  const { data } = await CAxios.post(`/api/quotes/nb`);
  return data.success && data.success.nb ? data.success.nb : 0;
};

export const getListFreeHoliday = async () => {
  const { data } = await CAxios.post(`/api/freeholiday/list`);
  return data.success && data.success.freeholidays
    ? data.success.freeholidays
    : [];
};

export const saveFreeHoliday = async (date, name) => {
  const { data } = await CAxios.post(`/api/freeholiday/add`, { date, name });
  return data.success && data.success.freeholidays
    ? data.success.freeholidays
    : null;
};
