export const getUsername = (el, users) => {
  return users?.filter((e) => e.id == el)[0].username;
};

export const toGetTotal = (id, data, users) => {
  let total = 0;
  data.forEach((el) => {
    total += parseFloat(el[getUsername(id, users)]);
  });

  return total.toFixed(3).replace(/\d(?=(\d{3})+\.)/g, "$& ");
};

export const colors = [
  "#e6194b",
  "#3cb44b",
  "#ffe119",
  "#4363d8",
  "#f58231",
  "#911eb4",
  "#46f0f0",
  "#f032e6",
  "#bcf60c",
  "#fabebe",
  "#008080",
  "#e6beff",
  "#9a6324",
  "#fffac8",
  "#800000",
  "#aaffc3",
  "#808000",
  "#ffd8b1",
  "#000075",
  "#808080",
  "#ffffff",
  "#000000",
];
const formatNumber = (num) => {
  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M"; // 1M, 2.5M
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1).replace(/\.0$/, "") + "K"; // 1K, 300K
  } else {
    return num.toString(); // Pour les nombres < 1,000
  }
};

export const CustomBarLabel = ({ x, y, width, value }) => {
  const formattedAmount = formatNumber(Math.round(value));

  return (
    <text
      x={x + width / 2}
      y={y + 20} // Adjust position as needed
      fill="black"
      textAnchor="middle"
      dy={20}
      fontSize={14}
      //style={{ transform: "rotate(180deg)" }}
    >
      {formattedAmount}
    </text>
  );
};

export const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const halfLength = Math.ceil(payload.length / 2);

    // Obtenir la première moitié
    const firstHalf = payload.slice(0, halfLength);

    return (
      <div
        style={{ background: "#fff", border: "1px solid #ccc", padding: 10 }}
      >
        <p>
          <b>{label}</b>
        </p>
        {firstHalf.map((el) => (
          <p style={{ color: el.fill }}>
            <strong>{el.name}</strong> {el.value}
          </p>
        ))}
      </div>
    );
  }

  return null;
};
