import React, { useEffect, useState } from "react";
import Ticker from "react-ticker";
import { toGetUnPaiedBL } from "../Pages/Vente/APIs";
import Parser from "html-react-parser";
import BonLivraisonModal from "../Pages/Vente/BL/BonLivraisonModal";
import { ReactTicker } from "@guna81/react-ticker";
import ClientDetail from "../Pages/Vente/Clients/ClientDetail";

function StockTicker() {
  const [rates, setRates] = useState([]);
  const [blToInspect, setBlToInspect] = useState(null);
  const renderItem = (el) => {
    return (
      <p
        style={{
          whiteSpace: "nowrap",
        }}
      >
        Le client
        <span
          style={{ fontWeight: "bold", color: "red" }}
          onClick={() => setDetailState({ client: el.clientId, visible: true })}
        >
          {" " + el.clientName}
        </span>{" "}
        a dépassé le délai de paiement pour le bl N°:{" "}
        <b
          onClick={() => setBlToInspect(el.blId)}
          style={{ cursor: "pointer", textDecoration: "underline" }}
        >
          {" "}
          {el.ref}{" "}
        </b>{" "}
        chargé Client: {el.vendeur} ***
      </p>
    );
  };

  const [detailState, setDetailState] = useState({
    client: null,
    visible: false,
  });

  useEffect(() => {
    async function fetchData() {
      const ratesFromAPI = await toGetUnPaiedBL();
      setRates(ratesFromAPI);
    }
    fetchData();
  }, []);
  return (
    <>
      <ReactTicker
        data={rates}
        component={renderItem}
        speed={40}
        keyName="_id"
        tickerStyle={{
          width: "100%",
          height: "40px",
          backgroundColor: "#fff",
          zIndex: 99,
          borderTop: "1px solid #e0e0e0",
        }}
        tickerClassName="news-ticker"
      />
      {/* {detailState.client && (
        <ClientDetail
          client={detailState.client}
          visible={detailState.visible}
          dismiss={() => setDetailState({ client: null, visible: false })}
        />
      )} */}

      <BonLivraisonModal
        entete={false}
        id={blToInspect}
        onClose={() => setBlToInspect(null)}
      />
    </>
  );
}

export default StockTicker;
