import Axios from "axios";
import { DataExistsException } from "../../DataExitsException";
import CAxios from "../../Utils/CAxios";
const ls = require("localstorage-ttl");
let ajaxRequest = null;

//Emplacement's api
export const toGetEmplacementList = async () => {
  const { data } = await CAxios.get(`/api/emplacement/list`);
  return data.success && data.success.emplacements
    ? data.success.emplacements
    : [];
};

export const toDeleteEmplacement = async (id) => {
  await CAxios.post(`/api/emplacement/delete`, { id: id });
};

export const toAddOrUpdateEmplacement = async (title, id) => {
  var url = id != -1 ? "/api/emplacement/update" : "/api/emplacement/add";
  const { data } = await CAxios.post(url, { title: title, id: id });
  return data.success && data.success.emplacement
    ? data.success.emplacement
    : null;
};

export const toGetNotificationsList = async (page, perPage) => {
  const { data } = await CAxios.post(`/api/authorisations/list`, {
    page,
    perPage,
  });
  return data.success && data.success
    ? data.success
    : { autorisations: [], total: 0 };
};

export const toGetStockValue = async (values, page) => {
  values.page = page;
  try {
    const { data } = await CAxios.post(`/api/articles/stock`, {
      data: values,
    });
    return data.success;
  } catch (e) {
    return null;
  }
};

//tva's api
export const toGetTVAList = async () => {
  const { data } = await CAxios.get(`/api/TVA/list`);
  return data.success && data.success.TVAs ? data.success.TVAs : [];
};

export const toDeleteTVA = async (id) => {
  await CAxios.post(`/api/TVA/delete`, { id: id });
};

export const toAddOrUpdateTVA = async (title, id) => {
  var url = id != -1 ? "/api/TVA/update" : "/api/TVA/add";
  const { data } = await CAxios.post(url, { title: title, id: id });
  return data.success && data.success.TVA ? data.success.TVA : null;
};

//unite's api
export const toGetUniteList = async () => {
  const { data } = await CAxios.get(`/api/unite/list`);
  return data.success && data.success.Unites ? data.success.Unites : [];
};

export const toDeleteUnite = async (id) => {
  await CAxios.post(`/api/unite/delete`, { id: id });
};

export const toAddOrUpdateUnite = async (title, id) => {
  var url = id != -1 ? "/api/unite/update" : "/api/unite/add";
  const { data } = await CAxios.post(url, { title: title, id: id });
  return data.success && data.success.unite ? data.success.unite : null;
};

//marque's api
export const toGetMarqueList = async () => {
  const { data } = await CAxios.get(`/api/marque/list`);
  return data.success && data.success.Marques ? data.success.Marques : [];
};

export const toDeleteMarque = async (id) => {
  await CAxios.post(`/api/marque/delete`, { id: id });
};

export const toAddOrUpdateMarque = async (category, categoryTecdoc, id) => {
  var url = id != -1 ? "/api/marque/update" : "/api/marque/add";
  const { data } = await CAxios.post(url, {
    category: category,
    categoryTecdoc: categoryTecdoc,
    id: id,
  });
  return data.success && data.success.marque ? data.success.marque : null;
};
//category's api
export const toGetCategoryList = async () => {
  const { data } = await CAxios.get(`/api/category/list`);
  return data.success && data.success.Categorys ? data.success.Categorys : [];
};

export const toDeleteCategory = async (id) => {
  await CAxios.post(`/api/category/delete`, { id: id });
};

export const toFusionCategory = async (data) => {
  await CAxios.post(`/api/category/fusion`, data);
};

export const toAddOrUpdateCategory = async (
  category,
  categoryTecdoc,
  id,
  rating
) => {
  var url = id != -1 ? "/api/category/update" : "/api/category/add";
  const { data } = await CAxios.post(url, {
    title: category,
    titleTecdoc: categoryTecdoc,
    id: id,
    rating,
  });
  return data.success && data.success.category ? data.success.category : null;
};

//article's api
export const toGetArticlesList = async (page) => {
  try {
    const { data } = await CAxios.post(`/api/articles/list`, { page: page });
    ls.set(`articles`, data.success.articles);
    return data.success.articles;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const toMergeArticles = async (master, slaves) => {
  try {
    const { data } = await CAxios.post(`/api/articles/merge`, {
      master,
      slaves,
    });
    return data.success.articles;
  } catch (e) {
    return null;
  }
};

export const toGetArticlesName = async () => {
  try {
    const { data } = await CAxios.post(`/api/articles/names`);
    //ls.set(`articles_name`, data.success.articles);
    return data.success.articles;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const toGetSearchArticlesList = async (search, ref) => {
  if (ajaxRequest) {
    ajaxRequest.cancel();
  }

  // creates a new token for upcomming ajax (overwrite the previous one)
  ajaxRequest = Axios.CancelToken.source();

  try {
    const { data } = await CAxios.post(
      `/api/articles/search`,
      {
        search: search,
        ref: ref,
      },
      {
        cancelToken: ajaxRequest.token,
      }
    );
    return data.success.articles;
  } catch (e) {
    return [];
  }
};

export const toFiltersArticlesList = async (values, page) => {
  values.page = page;
  try {
    const { data } = await CAxios.post(`/api/articles/filter`, {
      data: values,
    });
    return data.success.articles;
  } catch (e) {
    return [];
  }
};

export const toChangeStatusArticle = async (id, actif) => {
  const { data } = await CAxios.post("/api/article/change", {
    id: id,
    actif: actif,
  });
  return data.success && data.success.article ? data.success.article : null;
};

export const toAddOrUpdateArticle = async (id, article, automaticCode) => {
  var url = id != -1 ? "/api/articles/update" : "/api/articles/add";
  article.id = id;
  article.automaticCode = automaticCode;
  const response = await CAxios.post(url, JSON.stringify(article));
  if (response.status === 303) {
    throw new DataExistsException();
  }
  const { data } = response;
  return data.success && data.success.article ? data.success.article : null;
};

export const toUpdateArticleName = async (value, id) => {
  const { data } = await CAxios.post("/api/article_name/update", { id, value });
  return data.success && data.success.article ? data.success.article : null;
};

export const toGetArticleDetail = async (id) => {
  const { data } = await CAxios.post("/api/articles/detail", { id: id });
  return data.success && data.success.article ? data.success.article : null;
};

export const toEditArticle = async (article) => {
  await CAxios.post(`/api/articles/edit`, article);
};

export const toDeleteArticle = async (id) => {
  await CAxios.post(`/api/articles/delete`, { id: id });
};

export const toGetArticleHistory = async (id) => {
  const { data } = await CAxios.post(`/api/article/history`, { id: id });
  return data.success && data.success.list ? data.success.list : [];
};

export const toGetArticleStat = async (id, start, end) => {
  const { data } = await CAxios.post(`/api/article/stat`, {
    id: id,
    start,
    end,
  });
  return data.success && data.success.stats ? data.success.stats : [];
};

export const toGetAlertsReglementClients = async (start, end) => {
  const { data } = await CAxios.post(`/api/alerts/regCl`, {
    start,
    end,
  });
  return data.success && data.success.alerts ? data.success.alerts : [];
};

export const toFetchAlertsStocks = async (start, end) => {
  const { data } = await CAxios.post(`/api/alerts/stock`, {
    start,
    end,
  });
  return data.success && data.success.alerts ? data.success.alerts : [];
};

export const tofetchClients = async (start, end) => {
  const { data } = await CAxios.post(`/api/alerts/clients`, {
    start,
    end,
  });
  return data.success && data.success.alerts
    ? JSON.parse(data.success.alerts)
    : [];
};

export const tofetchClientsSeuil = async () => {
  const { data } = await CAxios.post(`/api/alerts/seuil`);
  return data.success && data.success.alerts
    ? JSON.parse(data.success.alerts)
    : [];
};
