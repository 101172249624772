import { Col, DatePicker, Form, Row, Select, Skeleton, Typography } from "antd";
import { useEffect, useState } from "react";
import Loading from "react-loading-bar";

import { toGetQuotesBLEntities, toGetTotalEntities } from "../APIs";

import { toGetEntityList } from "../../Compte/Api";
import CAComponent from "./CAComponent";

const CAEntity = ({ AppStore, ...props }) => {
  const [loading, setLoading] = useState(true);
  const [all, setAll] = useState([]);
  const [selected, setSelectedUser] = useState([]);
  const [Entitys, setEntitys] = useState([]);
  const [entityIds, setEntityIds] = useState([]);

  const [caThisYear, setCAThisYear] = useState([]);
  const [caPerYear, setCAPerYear] = useState([]);

  const [quoteThisYear, setQuoteThisYear] = useState([]);
  const [quotePerYear, setQuotePerYear] = useState([]);

  useEffect(() => {
    toGetEntityList().then((res) => {
      setEntitys(res);
      var l__ids = [];
      res
        .filter((el) => el.id !== 5)
        .forEach((entity) => l__ids.push(entity.id));
      setEntityIds(l__ids);
      getDataEntities(2, l__ids, 1, selected);
    });
  }, []);

  const getDataEntities = (date, entityIds, type, usersIds) => {
    setLoading(true);
    const entity = usersIds.length === 0;
    switch (type) {
      case "1": //chiffre d'affaires
        toGetTotalEntities(date, entity ? entityIds : usersIds, entity).then(
          (res) => {
            setCAThisYear(res);
            setAll(res);
            setLoading(false);
          }
        );
        break;

      case "2": //devis convertis en bl
        toGetQuotesBLEntities(date, entity ? entityIds : usersIds, entity).then(
          (res) => {
            setQuoteThisYear(res);
            setAll(res);
            setLoading(false);
          }
        );
        break;

      default:
        break;
    }
  };

  const getDataEntitiesPerYear = (date, entityIds, type, usersIds) => {
    setLoading(true);
    const entity = usersIds.length === 0;
    switch (type) {
      case "1": //chiffre d'affaires
        toGetTotalEntities(date, entity ? entityIds : usersIds, entity).then(
          (res) => {
            setCAPerYear(res);
            setLoading(false);
          }
        );
        break;

      case "2": //devis convertis en bl
        toGetQuotesBLEntities(date, entity ? entityIds : usersIds, entity).then(
          (res) => {
            setQuotePerYear(res);
            setAll(res);
            setLoading(false);
          }
        );
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    getDataEntities(2, entityIds, "1", []);
    getDataEntitiesPerYear(3, entityIds, "1", []);

    getDataEntities(2, entityIds, "2", []);
    getDataEntitiesPerYear(3, entityIds, "2", []);
  }, [entityIds]);
  return (
    <>
      <Loading show={loading} color="red" showSpinner={true} />

      <Row className="auto--custom-header">
        <Col span="6">
          <Typography.Title level={3}>Statistiques </Typography.Title>
        </Col>

        <Col span="8">
          <Form.Item label="">
            {entityIds.length > 0 && (
              <Select
                disabled={selected.length > 0}
                onChange={(e) => {
                  setEntityIds(e);
                  //getDataEntities(selectedDate, e, type, selected);
                }}
                style={{ width: "100%" }}
                placeholder="Séléctionner l'entité"
                defaultValue={entityIds}
                mode="multiple"
              >
                {Entitys.filter((e) => e.id != 5).map((e) => (
                  <Select.Option value={e.id}>{e.title}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Skeleton active loading={loading} />

      <CAComponent
        data={caThisYear}
        selected={selected}
        entityIds={entityIds}
        type={1}
        Entitys={Entitys}
        title={"CA: Cette année"}
      />
      <CAComponent
        data={caPerYear}
        selected={selected}
        entityIds={entityIds}
        type={1}
        Entitys={Entitys}
        title={"CA: Par Année"}
      />
      <CAComponent
        data={quoteThisYear}
        selected={selected}
        entityIds={entityIds}
        type={1}
        Entitys={Entitys}
        title={"Devis convertis en BL: Cette Année"}
      />
      <CAComponent
        data={quotePerYear}
        selected={selected}
        entityIds={entityIds}
        type={1}
        Entitys={Entitys}
        title={"Devis convertis en BL: Par Année"}
      />
    </>
  );
};

export default CAEntity;
