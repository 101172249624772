import { useEffect, useState } from "react";
import "../../../Styles/ClientModal.scss";
import {
  Tabs,
  Col,
  Descriptions,
  Modal,
  Row,
  Empty,
  Typography,
  Button,
  Input,
  Divider,
  Switch,
  Form,
  DatePicker,
  Select,
} from "antd";
import { toGetUsers } from "../../Compte/Api";

const { Option } = Select;

const ClientHistorique = ({ visible, dismiss, clients }) => {
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    var l__users = [];
    toGetUsers().then((users) => {
      users.forEach((user) => {
        l__users.push(user.username);
      });
      setUsers(l__users);
      form.setFieldsValue({
        paied: "0",
        client: "0",
        date: "0",
        user: "0",
        note: "",
        region: "",
      });
    });
  }, [visible]);

  const onSearch = () => {};

  return (
    <Modal
      className="smtv-modal modal-large"
      visible={visible}
      onCancel={dismiss}
      title="Historique des clients"
    >
      <Form layout="vertical" form={form}>
        <Row className="auto--custom-header">
          <Col span="4">
            <Form.Item label="Crédit" name="paied">
              <Select defaultValue="0">
                <Option value="0">Tout</Option>
                <Option value="1">Non payé</Option>
                <Option value="2">Payé</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item label="Client" name="client">
              <Select showSearch defaultValue="0">
                <Option value="0">Tout</Option>

                {clients.map((c) => (
                  <Option value={c.s__name}>{c.s__name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item label="Date" name="date">
              <Select showSearch style={{ width: "100%" }} defaultValue="0">
                <Option value="0">Tout</Option>

                <Option value="1">Hier</Option>
                <Option value="2">Aujourd'hui</Option>
                <Option value="3">Ce mois</Option>
                <Option value="4">Cette année</Option>
                {/* <Option value="5">Date Spécifique</Option>
                <Option value="6">Période Spécifique</Option> */}
              </Select>
            </Form.Item>
            {/* {displayDate && <DatePicker style={{ width: "100%" }} />}
            {displayDateRange && <DatePicker.RangePicker />} */}
          </Col>

          <Col span="4">
            <Form.Item label="Utilisateurs" name="user">
              <Select showSearch style={{ width: "80%" }} defaultValue="0">
                <Option value="0">Tout</Option>

                {users
                  .filter((a) => a.active)
                  .map((u) => (
                    <Option value={u}>{u}</Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item label="Note" name="note">
              <Input />
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item label="Région" name="region">
              <Input />
            </Form.Item>
          </Col>
          <Col span="4" style={{ float: "right" }}>
            <Form.Item label="">
              <Button type="primary" onClick={() => onSearch()}>
                Rechercher
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default ClientHistorique;
