import {
  LeftOutlined,
  PlusSquareOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Typography,
  notification,
} from "antd";

import ls from "localstorage-ttl";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Article from "../../../Models/Article";
import Client from "../../../Models/Client";
import "../../../Styles/TwoTables.scss";
import history from "../../../Utils/History";
import Tecdoc from "../../../Utils/Tecdoc";
import {
  errorMsg,
  successMsg,
  toFormatArticleClassifications,
} from "../../../Utils/Utils";
import { toAddOrUpdateArticle } from "../../Gestions/Api";
import ArticleModal from "../../Gestions/ArticleModal";
import ClientModal from "../../Vente/Clients/ClientModal";

import localization from "moment/locale/fr";
import cookie from "react-cookies";
import GaugeChart from "react-gauge-chart";
import { useSpeechSynthesis } from "react-speech-kit";
import {
  addItem,
  clearItems,
  setAchat,
  setClient,
  setItemsState,
  setReglements,
} from "../../../Actions/ItemsSelectionAtions";
import ItemsSelection from "../../../Components/ItemsSelection";
import { DataExistsException } from "../../../DataExitsException";
import { toGetCode } from "../../Compte/Api";
import {
  toCreateBL,
  toGetBL,
  toGetClientsList,
  toPrintBL,
  toUpdateBl,
  toVerifyAuthorizationApi,
} from "../APIs";
import ModalCode from "../ModalCode";
import ReglementBL from "../Reglement/ReglementBL";

moment.updateLocale("fr", localization);

var warningModal = false;
const { Option } = Select;

const BonLivraison = ({ AppStore, bl, ...props }) => {
  const { speak } = useSpeechSynthesis();

  const [form] = Form.useForm();
  const [date, setDate] = useState("");
  const [client, setClient] = useState(null);
  const { id } = useParams();
  const [note, setNote] = useState("");
  const [displayableName, setDisplaybleName] = useState("");
  const [displayableMF, setDisplaybleMF] = useState("");
  const [caisseValue, setCaisseValue] = useState(0);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [text, settext] = useState("");
  const [remiseChecked, setRemiseChecked] = useState(false);
  const [margeModal, setMargeModal] = useState(false);
  const [articleDetails, setArticleDetails] = useState({
    title: "",
    reference: "",
    marque: "",
    type: "Origine",
    categorie: "",
  });

  const [activeTable, setActiveTable] = useState(1);
  const [reference, setReference] = useState("");
  const [clients, setClients] = useState(null);
  const [clientModalState, setClientModalState] = useState({
    visible: false,
    client: new Client(),
  });
  const [marge, setMarge] = useState(-1);
  const [isAdminModalVisible, setAdminModalVisible] = useState(false);
  const [isCaisseModalVisible, setIsCaisseModalVisible] = useState(false);
  const [negatifVisible, setNegatifIsVisible] = useState(false);
  const [depassDelaiVisible, setDepassDelaiVisible] = useState(false);

  const [paiementActive, setPaiementActive] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [choosenClient, setChoosenClient] = useState(null);
  const [savingState, setSavingState] = useState({
    saving: false,
    print: false,
  });
  const [state, setState] = useState({
    createModalVisible: false,
    clientForm: new Client(),
    readonly: false,
  });

  useEffect(() => {
    AppStore.setFirst(1);
    AppStore.setSecond(8);

    props.clearItems();
    toGetCode().then((res) => {
      setPercentage(res.percentage);
    });
    props.setAchat(false);

    if (null != ls.get("clients")) {
      setClients(ls.get("clients"));
      if (id == null) {
        setClient(null);
        props.setClient(null);
      }
    }
    toGetClientsList().then((data) => setClients(data));

    if (null != id) {
      toGetBL(id).then((data) => {
        var bl = JSON.parse(data.bonlivraison);
        if (bl.json.factureId != null) {
          errorMsg("Vous ne pouvez pas modifier ce BL car il est facturé!");
          history.push("/vente/bl/list");

          return;
        }
        setNote(bl.s__note);
        setDisplaybleMF(bl.s__displayableMF);
        setDisplaybleName(bl.s__displayableClientName);
        const blToUpdate = {
          id: bl.id,
          total: bl.d__total,
          discount: bl.d__discount,
          items: [],
          client: bl.client,
          reglements: data.reglements,
          ref: bl.s__ref,
        };
        for (let item of bl.items) {
          blToUpdate.items.push({
            ...item,
            ref: item.article.s__reference,
            titre: item.article.s__secondTitle,
            quantity: item.d__quantity,
            uPriceHt: item.d__unitPriceHT,
            uPriceTTC: item.d__unitPriceTTC,
            total: item.d__total,
            discount: item.d__discount,
            prix_achat: item.article.l__prixAchatTTC,
            prix_unitaire: item.article.l__prixVenteHT,
            prix_unitaireTTC: item.article.l__prixVenteTTC,
            prix_unitaire_gros: item.article.l__prixVenteGrosHT,
            prix_unitaire_grosTTC: item.article.l__prixVenteGrosTTC,
            tva: item.article.tva.title,
            s__articleTitle: item.titre,
          });
        }

        props.setBlToUpdate(blToUpdate);
      });
    }
  }, [id]);

  const saveClient = (client) => {
    setClients(clients.concat(client));
    chooseClient(client.s__name, { data: client });
    setClientModalState({ visible: false, client: null });
  };

  const toUpdateTotal = (id) => {
    var totalPayé = bl.reglements.reduce((a, b) => b.montant + a, 0);
    bl.discount = (bl.total - totalPayé).toFixed(3);
    saveBL(id);
  };

  const verifyMarge = () => {
    if (marge < percentage && !isAdminModalVisible) {
      settext("Le prix est inférieur à la marge minimum!");
      setAdminModalVisible(true);
      setMargeModal(true);
      return;
    }
    setAdminModalVisible(false);
    setMargeModal(false);
    setPaiementActive(!paiementActive);
  };

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "Erreur",
      description:
        "Pour le client SMTV, il faut impérativement ajouter le paiement!!",
    });
  };

  const openNotificationWithIcon2 = (type) => {
    notification[type]({
      message: "Erreur",
      description: "Ce client bloque le bonus tant que le BL n'est pas payé",
    });
  };

  const toVerifyDisount = () => {
    let response = false;

    bl.items.forEach((el) => {
      if (parseFloat(el.discount) == 0) {
        response = true;
      }
    });
    return response;
  };

  const verifyDiscountValue = () => {
    let response = false;

    bl.items.forEach((el) => {
      if (parseFloat(el.discount) < 3 || parseFloat(el.discount) > 15) {
        response = true;
      }
    });
    return response;
  };

  const saveBL = async (i__id, print = false) => {
    setMargeModal(false);
    if (bl.client.b__discount && toVerifyDisount() == true) {
      errorMsg("il faut ajouter la remise!");
      return;
    }
    if (bl.client.b__caisse) {
      if (parseFloat(caisseValue) === 0) {
        errorMsg("il faut ajouter la valeur de caisse!");
        return;
      }
      if (parseFloat(caisseValue) < 0.8 * bl.total && !isCaisseModalVisible) {
        settext(
          "Il y'a une grande différence entre la valeur de caisse et le total du BL"
        );

        setIsCaisseModalVisible(true);
        return;
      }
    }

    if (bl.client.b__note && note.trimLeft() == "") {
      errorMsg("Pour ce client, il faut ajouter le modèle de la voiture!");
      return;
    }

    if (
      bl.client.b__discount &&
      verifyDiscountValue() == true &&
      !isAdminModalVisible
    ) {
      settext("La remise doit être entre 3 et 15");

      setAdminModalVisible(true);
      return;
    }

    if (bl.client == null) {
      errorMsg("Selectionnez un client");
      return;
    }
    var totalPayé = bl.reglements.reduce((a, b) => b.montant + a, 0);

    if (
      parseFloat(totalPayé).toFixed(3) < parseFloat(bl.total).toFixed(3) &&
      !remiseChecked &&
      bl.client.id == 24
    ) {
      openNotificationWithIcon("error");
      return;
    }

    if (
      bl.client.d__threshold == 0 &&
      bl.reglements.length == 0 &&
      !isAdminModalVisible
    ) {
      settext("Il faut ajouter le mode de paiement pour le client passager!");

      setAdminModalVisible(true);
      return;
    }

    if (
      parseFloat(totalPayé).toFixed(3) < parseFloat(bl.total).toFixed(3) &&
      !isAdminModalVisible &&
      !remiseChecked &&
      bl.client.d__threshold == 0
    ) {
      settext("Le BL n'est pas totalement payé!");

      setAdminModalVisible(true);
      return;
    }

    if (
      parseFloat(totalPayé).toFixed(3) < parseFloat(bl.total).toFixed(3) &&
      remiseChecked &&
      !warningModal
    ) {
      warningModal = true;
      Modal.warning({
        okButtonProps: { style: { display: "none" } },

        content: (
          <div>
            <p>
              Voulez vous vraiment ajouter le reste(
              {(bl.total - totalPayé).toFixed(3)} )comme étant une remise!
            </p>
            <div style={{ textAlign: "center", marginTop: "2vh" }}>
              <Button type="primary" onClick={() => toUpdateTotal(id)}>
                Valider
              </Button>
              <Button
                style={{ marginLeft: "2vh" }}
                danger
                onClick={() => {
                  Modal.destroyAll();
                  warningModal = false;
                }}
              >
                Annuler
              </Button>
            </div>
          </div>
        ),
      });

      return;
    }
    Modal.destroyAll();
    warningModal = false;
    if (bl.client.d__threshold > 0 && !isAdminModalVisible) {
      var totalUnpaied =
        parseFloat(bl.client.unpaied) +
        (parseFloat(bl.total) -
          bl.reglements.reduce((a, b) => parseFloat(b.montant) + a, 0));
      if (totalUnpaied > bl.client.d__threshold && id == null) {
        toVerifyAuthorizationApi(bl.client.id).then((response) => {
          if (response) {
            setSavingState({ saving: true });
            if (bl.client.id === 718 && displayableName === "") {
              errorMsg(
                "Il faut ajouter le client affiché pour 'CLIENT CONTRE REMBOURSEMENT'"
              );
              setSavingState({ saving: false });
              return;
            }
            if (null != id) {
              toUpdateBl(
                date,
                id,
                bl.client,
                bl.items,
                note,
                remiseChecked,
                bl.discount,
                bl.total,
                bl.reglements,
                i__id,
                displayableName,
                displayableMF,
                caisseValue
              )
                .then((res) => {
                  if (!res.bonlivraison) {
                    setSavingState({ saving: false });
                    errorMsg("Stock Insuffisant " + res.stock.join("\n"));
                    return;
                  }

                  history.push("/vente/bl/list");
                  window.location.reload();
                })
                .catch(() => {
                  setSavingState({ saving: false });
                });
            } else {
              toCreateBL(
                date,
                bl.client,
                bl.items,
                note,
                remiseChecked,
                bl.discount,
                bl.total,
                bl.reglements,
                i__id,
                displayableName,
                displayableMF,
                caisseValue
              )
                .then((res) => {
                  if (!res.bonlivraison) {
                    setSavingState({ saving: false });
                    errorMsg("Stock Insuffisant " + res.stock.join("\n"));
                    return;
                  }
                  history.push("/vente/bl/list");
                  window.location.reload();
                  if (print) {
                    toPrintBL(
                      JSON.parse(res.data.success.bonlivraison).id,
                      true,
                      true
                    );
                  }
                })
                .catch(() => {
                  setSavingState({ saving: false });
                });
            }
          } else if (!depassDelaiVisible) {
            settext(
              `Le client ${bl.client.s__name} a dépassé son seuil de ${(
                totalUnpaied - bl.client.d__threshold
              ).toFixed(3)} dinars!`
            );
            speak({
              text: `Le client ${bl.client.s__name} a dépassé son seuil de ${(
                totalUnpaied - bl.client.d__threshold
              ).toFixed(3)} dinars!`,
            });
            setAdminModalVisible(true);
            return;
          }
        });
      } else {
        setSavingState({ saving: true });
        if (bl.client.id === 718 && displayableName === "") {
          errorMsg(
            "Il faut ajouter le client affiché pour 'CLIENT CONTRE REMBOURSEMENT'"
          );
          setSavingState({ saving: false });
          return;
        }
        if (null != id) {
          toUpdateBl(
            date,
            id,
            bl.client,
            bl.items,
            note,
            remiseChecked,
            bl.discount,
            bl.total,
            bl.reglements,
            i__id,
            displayableName,
            displayableMF,
            caisseValue
          )
            .then((res) => {
              if (!res.bonlivraison) {
                setSavingState({ saving: false });
                errorMsg("Stock Insuffisant " + res.stock.join("\n"));
                return;
              }

              history.push("/vente/bl/list");
              window.location.reload();
            })
            .catch(() => {
              setSavingState({ saving: false });
            });
        } else {
          toCreateBL(
            date,
            bl.client,
            bl.items,
            note,
            remiseChecked,
            bl.discount,
            bl.total,
            bl.reglements,
            i__id,
            displayableName,
            displayableMF,
            caisseValue
          )
            .then((res) => {
              if (!res.bonlivraison) {
                setSavingState({ saving: false });
                errorMsg("Stock Insuffisant " + res.stock.join("\n"));
                return;
              }
              history.push("/vente/bl/list");
              window.location.reload();
              if (print) {
                toPrintBL(
                  JSON.parse(res.data.success.bonlivraison).id,
                  true,
                  true
                );
              }
            })
            .catch(() => {
              setSavingState({ saving: false });
            });
        }
      }
    } else {
      setSavingState({ saving: true });
      if (bl.client.id === 718 && displayableName === "") {
        errorMsg(
          "Il faut ajouter le client affiché pour 'CLIENT CONTRE REMBOURSEMENT'"
        );
        setSavingState({ saving: false });
        return;
      }
      if (null != id) {
        toUpdateBl(
          date,
          id,
          bl.client,
          bl.items,
          note,
          remiseChecked,
          bl.discount,
          bl.total,
          bl.reglements,
          i__id,
          displayableName,
          displayableMF,
          caisseValue
        )
          .then(() => {
            history.push("/vente/bl/list");
            window.location.reload();
          })
          .catch(() => {
            setSavingState({ saving: false });
          });
      } else {
        toCreateBL(
          date,
          bl.client,
          bl.items,
          note,
          remiseChecked,
          bl.discount,
          bl.total,
          bl.reglements,
          i__id,
          displayableName,
          displayableMF,
          caisseValue
        )
          .then((res) => {
            if (!res.bonlivraison) {
              setSavingState({ saving: false });
              errorMsg("Stock Insuffisant " + res.stock.join("\n"));
              return;
            }
            history.push("/vente/bl/list");
            window.location.reload();
            if (print) {
              toPrintBL(
                JSON.parse(res.data.success.bonlivraison).id,
                true,
                true
              );
            }
          })
          .catch(() => {
            setSavingState({ saving: false });
          });
      }
    }
  };

  const switchTable = () => {
    activeTable === 1 ? setActiveTable(2) : setActiveTable(1);
  };

  const addFromTecdoc = async (article) => {
    var a = new Article();
    a.titre = article.fullName;
    a.reference = article.ref;
    a.marque = article.brand.name;
    a.type = "Adaptable";
    a.categorie = article.fullName;
    a.data = toFormatArticleClassifications(article);
    a.referenceTecdoc = article.ref;
    a.fromTecdoc = true;

    setArticleDetails(a);

    setCreateModalVisible(true);
  };

  const saveArticle = async (id, article) => {
    try {
      var newArticle = await toAddOrUpdateArticle(id, article);
      if (id === -1) {
        setReference(newArticle.s__reference);
        successMsg("Article créé avec succés");
      }

      setCreateModalVisible(false);
    } catch (e) {
      if (e instanceof DataExistsException) {
        errorMsg("L'article existe déja");
      } else {
        errorMsg("Une erreur est survenue");
      }
    }
  };

  const chooseClient = (clientName, option) => {
    if (clientName === "0")
      setClientModalState({ visible: true, client: new Client() });
    else {
      if (option.data.b__locked) {
        const args = {
          key: new Date(),
          message: "Ce client a été désactivé",
          description: "merci de vérifier avec l'administration!!",
          duration: 3,
        };
        speak({
          text:
            "Le client" +
            option.data.s__name +
            " a été désactivé,merci de vérifier avec l'administration!!",
        });

        notification.error(args);
        return;
      }
      var diff = moment(new Date()).diff(
        moment(option.data.first__unpaiedBL).add(
          option.data.d__paymentDelay,
          "days"
        ),
        "days"
      );

      if (diff > 0 && option.data.d__paymentDelay != 0) {
        toVerifyAuthorizationApi(option.data.id).then((response) => {
          if (response) {
            props.setClient(option.data);
            setClient(option.data);
            return;
          } else if (!depassDelaiVisible) {
            settext(
              "Le client " +
                option.data.s__name +
                " a dépassé le délai de paiement autorisé!!!"
            );
            speak({
              text:
                "Le client" +
                option.data.s__name +
                " a dépassé le délai de paiement autorisé!!!",
            });
            setChoosenClient(option.data);
            setDepassDelaiVisible(true);
            return;
          }
        });
      } else {
        if (option.data.b__blockBonus) {
          openNotificationWithIcon2("error");
          // errorMsg("Ce client bloque le bonus tant que le BL n'est pas payé");
        }
        props.setClient(option.data);
        setClient(option.data);
      }
    }
  };

  const validatepaiement = (reglements) => {
    props.setReglements(reglements);
  };

  const exitCreateModal = () => {
    setCreateModalVisible(false);
  };
  const openReadOnlyModal = () =>
    setState({ clientForm: client, createModalVisible: true, readonly: true });

  const closeClientModal = () => {
    setClientModalState({ createModalVisible: false, clientForm: null });
  };

  const updateMarge = (value) => {
    setMarge(value);
  };

  const ModalClient = (
    <Modal footer={null} visible={null == bl.client}>
      <Row gutter={8} style={{ marginTop: "4vh" }}>
        <Col span={8}>
          <Typography.Title level={4}>Client :</Typography.Title>
        </Col>
        <Col span={2}>
          <Button
            type={"link"}
            icon={<img src="/images/clientVert.svg" />}
            onClick={() => openReadOnlyModal()}
          />
        </Col>
        <Col span={14} style={{ textAlign: "left" }}>
          <Select
            showSearch
            value={bl.client != null ? bl.client.s__name : null}
            onSelect={chooseClient}
            style={{ width: "100%" }}
            placeholder="Cherecher un client"
            filterOption={(input, option) =>
              option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.key.indexOf(input.toLowerCase()) >= 0
            }
          >
            {cookie.load("role") == "Super Admin" && (
              <Option key="-1" value="0">
                <PlusSquareOutlined /> Nouveau client
              </Option>
            )}
            {clients &&
              clients
                .filter((el) => !el.archivated)
                .map((client) => (
                  <Option
                    style={{
                      backgroundColor:
                        client.d__threshold > 0 &&
                        client.d__threshold < client.unpaied
                          ? "red"
                          : client.b__billPerMonth
                          ? "#69c0ff"
                          : client.b__billPerWeek
                          ? "#846eb1"
                          : "white",
                    }}
                    key={client.s__code}
                    value={client.s__name}
                    data={client}
                  >
                    {client.s__name}
                  </Option>
                ))}
          </Select>
        </Col>
      </Row>
    </Modal>
  );

  const rightColHeader = (
    <>
      <Row justify="space-between">
        <Col md={10}>
          <Row gutter={8}>
            <Col span={12}>
              <Typography.Title style={{ marginLeft: "10px" }} level={5}>
                Bon de livraison
                <br />
                {bl.ref && bl.ref}
              </Typography.Title>
            </Col>
            <Col span={12}>
              <div style={{}}>
                {marge != -1 && (
                  <>
                    <span style={{ marginLeft: "10%" }}>{marge + " %"}</span>
                    <br />
                    <GaugeChart
                      id="gauge-chart5"
                      nrOfLevels={100}
                      arcsLength={[0.1, 0.25, 0.65]}
                      colors={["#EA4228", "#F5CD19", "#5BE12C"]}
                      percent={marge / 100}
                      textColor={"transparent"}
                      arcPadding={0.01}
                      style={{ height: "50%", width: "50%" }}
                    ></GaugeChart>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={14} style={{ textAlign: "right" }}>
          <Row gutter={8}>
            <Col span={8}>
              <Typography.Title level={4}>Client :</Typography.Title>
            </Col>
            <Col span={2}>
              <Button
                type={"link"}
                icon={<img src="/images/clientVert.svg" />}
                onClick={() => openReadOnlyModal()}
              />
            </Col>
            <Col span={14} style={{ textAlign: "left" }}>
              <Select
                showSearch
                value={bl.client != null ? bl.client.s__name : null}
                onSelect={chooseClient}
                style={{ width: "100%" }}
                placeholder="Cherecher un client"
                filterOption={(input, option) =>
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 || option.key.indexOf(input.toLowerCase()) >= 0
                }
              >
                {cookie.load("role") == "Super Admin" && (
                  <Option key="-1" value="0">
                    <PlusSquareOutlined /> Nouveau client
                  </Option>
                )}
                {clients &&
                  clients
                    .filter((el) => !el.archivated)
                    .map((client) => (
                      <Option
                        style={{
                          backgroundColor:
                            client.d__threshold > 0 &&
                            client.d__threshold < client.unpaied
                              ? "red"
                              : client.b__billPerMonth
                              ? "#69c0ff"
                              : client.b__billPerWeek
                              ? "#846eb1"
                              : "white",
                        }}
                        key={client.s__code}
                        value={client.s__name}
                        data={client}
                      >
                        {client.s__name}
                      </Option>
                    ))}
              </Select>
            </Col>
          </Row>
        </Col>

        {state.clientForm && (
          <ClientModal
            clientForm={state.clientForm}
            visible={state.createModalVisible}
            onCancel={() =>
              setState({
                createModalVisible: false,
                clientForm: null,
                readonly: false,
              })
            }
            onClientSaved={null}
            readonly={state.readonly}
          />
        )}
      </Row>
      <Row justify={"end"}>
        {cookie.load("changeDate") == "true" && (
          <Col span={12} style={{ textAlign: "left" }}>
            Date:
            <DatePicker onChange={(e, date) => setDate(date)} />
          </Col>
        )}

        <Col span={12}>
          Note:{" "}
          <Input
            name="s__note"
            value={note}
            style={{ width: "90%" }}
            onChange={(e) => setNote(e.target.value)}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col span={8}>
          Client affiché:{" "}
          <Input
            name="s__displayName"
            value={displayableName}
            onChange={(e) => setDisplaybleName(e.target.value)}
          />
        </Col>
        <Col span={4} />
        <Col span={8}>
          MF affiché:{" "}
          <Input
            name="s__displayableMF"
            value={displayableMF}
            onChange={(e) => setDisplaybleMF(e.target.value)}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col span={8}>
          Valeur Caisse:{" "}
          <Input
            name="d__caisseValue"
            value={caisseValue}
            onChange={(e) => setCaisseValue(e.target.value)}
          />
        </Col>
      </Row>
    </>
  );

  const validate = (reglements) => {
    props.setReglements(reglements);
  };
  return (
    <>
      {ModalClient}
      <Row style={{ height: "100%" }} className="two-tables-row">
        <Col lg={12} md={24} className={"articles-list"}>
          <Card>
            <Tecdoc
              addFromTecdoc={addFromTecdoc}
              displayTitle={true}
              addItem={props.addItem}
              reference={reference}
              achat={false}
            />
          </Card>
        </Col>
        <Col
          lg={12}
          md={24}
          className={"selection-list" + (activeTable === 2 ? " active" : "")}
        >
          <Card title={rightColHeader} style={{ minHeight: "100%" }}>
            {paiementActive ? (
              <>
                <ReglementBL
                  bl={bl}
                  validate={validate}
                  setRemiseChecked={(checked) => setRemiseChecked(checked)}
                />
              </>
            ) : (
              <ItemsSelection client={client} getMarge={updateMarge} />
            )}
            {bl.items && bl.items.length > 0 && (
              <div className="selection-footer">
                <Row justify="end" gutter={24}>
                  <Col span={4}>
                    <Button
                      onClick={() => verifyMarge()}
                      block
                      type={"primary"}
                      style={{ marginRight: "10px" }}
                      disabled={savingState.saving}
                    >
                      <span
                        style={{
                          marginRight: 10,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          verticalAlign: !savingState.print
                            ? "text-top"
                            : "baseline",
                        }}
                      >
                        {paiementActive ? "Details" : "Paiement"}
                      </span>
                      {savingState.saving && !savingState.print && (
                        <Spin size="small" style={{ marginLeft: 10 }} />
                      )}
                    </Button>
                  </Col>
                  <Col span={4}>
                    <Button
                      onClick={() => saveBL(-1, false)}
                      block
                      type={"primary"}
                      style={{ marginRight: "10px" }}
                      disabled={savingState.saving}
                    >
                      <span
                        style={{
                          marginRight: 10,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          verticalAlign: !savingState.print
                            ? "text-top"
                            : "baseline",
                        }}
                      >
                        Enregister
                      </span>
                      {savingState.saving && !savingState.print && (
                        <Spin size="small" style={{ marginLeft: 10 }} />
                      )}
                    </Button>
                  </Col>
                  <Col span={4}>
                    <Button
                      block
                      onClick={() => saveBL(-1, true)}
                      disabled={savingState.saving}
                      maxW
                      style={{ marginRight: 10 }}
                    >
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          maxWidth: "calc(100% - 20px)",
                          textOverflow: "ellipsis",
                          verticalAlign: savingState.print
                            ? "text-top"
                            : "baseline",
                        }}
                      >
                        Imprimer
                      </span>
                      {savingState.print && (
                        <Spin size="small" style={{ marginLeft: 10 }} />
                      )}
                    </Button>
                  </Col>
                  <Col span={4}>
                    <Button block danger onClick={props.clearItems}>
                      <Link to="/vente/bl">Annuler</Link>
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          </Card>
        </Col>
      </Row>

      <Button
        className={"table-switch" + (activeTable === 2 ? " left" : "")}
        onClick={switchTable}
      >
        {activeTable === 1 ? <RightOutlined /> : <LeftOutlined />}
      </Button>
      {clientModalState.client && (
        <ClientModal
          visible={clientModalState.visible}
          clientForm={clientModalState.client}
          onClientSaved={saveClient}
          onCancel={closeClientModal}
        />
      )}

      <ArticleModal
        saveArticle={saveArticle}
        details={articleDetails}
        createModalVisible={createModalVisible}
        closeCreateModal={exitCreateModal}
      />
      <ModalCode
        isAdminModalVisible={isAdminModalVisible}
        setAdminModalVisible={() => {
          setAdminModalVisible(false);
          setNegatifIsVisible(false);
          setDepassDelaiVisible(false);
        }}
        onOk={(id) => (margeModal ? verifyMarge() : saveBL(id, false))}
        text={text}
      />

      <ModalCode
        isAdminModalVisible={isCaisseModalVisible}
        setAdminModalVisible={() => {
          setIsCaisseModalVisible(false);
        }}
        onOk={(id) => saveBL(id, false)}
        text={text}
      />

      <ModalCode
        isAdminModalVisible={depassDelaiVisible}
        setAdminModalVisible={() => {
          setDepassDelaiVisible(false);
        }}
        type={1}
        client={choosenClient}
        onOk={() => {
          setClient(choosenClient);
          props.setClient(choosenClient);
          setDepassDelaiVisible(false);
        }}
        text={text}
      />

      <Modal
        title="Operation non autorisée"
        visible={negatifVisible}
        onOk={() => setNegatifIsVisible(false)}
        onCancel={() => setNegatifIsVisible(false)}
      >
        <p>Le stock négatif n'est pas autorisé!</p>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ itemSelectionState }) => {
  return {
    bl: itemSelectionState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addItem: (item) => dispatch(addItem(item)),
    setClient: (client) => dispatch(setClient(client)),
    setAchat: (value) => dispatch(setAchat(value)),
    clearItems: () => dispatch(clearItems()),
    setBlToUpdate: (quote) => dispatch(setItemsState(quote)),
    setReglements: (reglements) => dispatch(setReglements(reglements)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BonLivraison);
