import {
  Button,
  Typography,
  Switch,
  Col,
  Row,
  Input,
  Skeleton,
  Tooltip,
  Popconfirm,
  Modal,
  Select,
  Form,
  Descriptions,
  DatePicker,
  Tag,
} from "antd";

import {
  SaveOutlined,
  NotificationTwoTone,
  MailOutlined,
  PercentageOutlined,
  TeamOutlined,
  WhatsAppOutlined,
  PlusOutlined,
  PrinterTwoTone,
  PrinterOutlined,
  PrinterFilled,
} from "@ant-design/icons";
import { useEffect, useMemo, useRef, useState } from "react";
import { TweenOneGroup } from "rc-tween-one";
import TotalBl from "./TotalBl";

import {
  toDeleteBL,
  toGetBonsLivraison,
  toPrintBL,
  toUpdateBlData,
  toPrintBLs,
  toSendNotification,
  toGetClientsListIds,
  toGetTotal,
  toUnlockBL,
  printAllBls,
  toGetBL,
  payBl,
  toUpdateDiscount,
  saveClientUpdateApi,
  toSearcheMultiplesBls,
  validateDelivery,
  toSaveBLPDF,
  toPrintMultipleBLs,
  payBls,
} from "../APIs";

import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import BonLivraisonModal from "./BonLivraisonModal";
import {
  toConvertDate,
  customFilter,
  successMsg,
  numberFormatCurrency,
  errorMsg,
} from "../../../Utils/Utils";
import cookie from "react-cookies";

import { setItemsState } from "../../../Actions/ItemsSelectionAtions";
import { connect } from "react-redux";
import FactureModal from "../Factures/FactureModal";
import Search from "../../../Utils/Search";
import { toGetCode, toGetUsers } from "../../Compte/Api";
import Loading from "react-loading-bar";
import "react-loading-bar/dist/index.css";
import BlToFacture from "./BlToFacture";
import ClientDetail from "../Clients/ClientDetail";
import Client from "../../../Models/Client";
import ModalCode from "../ModalCode";
import ReglementBLList from "../Reglement/ReglementBLList";
import FactureAutomatique from "./FactureAutomatique";
import Email from "../Email";
import { GrDeliver } from "react-icons/gr";
import Delivery from "../Delivery";
const { Option } = Select;

const fields = [
  { name: "Référence", field: "s__ref", type: "varchar" },
  { name: "Total", field: "total", type: "number" },
  { name: "Client", field: "clientName", type: "varchar" },
  {
    name: "Client Affiché",
    field: "displayableName",
    type: "varchar",
  },

  { name: "Matricule Fiscale", field: "mf", type: "varchar" },
  { name: "Remise", field: "discount", type: "number" },
  { name: "Nombre d'articles", field: "nb", type: "number" },
  { name: "Payé", field: "paied", type: "boolean" },
  { name: "Date de création", field: "createdDate", type: "date" },
  { name: "Région", field: "region", type: "varchar" },
];

const ListeBonLivraison = ({ AppStore, clientId, ...props }) => {
  var warningModal = false;
  const [remise, setRemise] = useState({
    batterie: 0,
    adaptale: 0,
    origine: 0,
  });
  const [remiseVisible, setRemiseModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [deliveryModal, setDeliveryModal] = useState(false);
  const [modalClient, setModalClient] = useState(false);
  const [whatsappModal, setWhatsappModal] = useState(false);
  const [phone, setPhone] = useState("");
  const [isBLModalVisible, setisBLModalVisible] = useState(false);
  const [remiseChecked, setRemiseChecked] = useState(false);
  const [bonsLivraison, setBonsLivraison] = useState([]);
  const [bonsLivraisonALL, setBonsLivraisonAll] = useState([]);
  const [selectedMethode, setSelectedMethode] = useState(2);
  const [selectedClient, setSelectedClient] = useState(clientId ?? "0");
  const [clientToSet, setClientToSet] = useState(-1);
  const [selectedDate, setSelectedDate] = useState(
    cookie.load("role") == "Super Admin" ? "0" : "2"
  );
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(
    cookie.load("role") == "Super Admin" ? "0" : cookie.load("id")
  );
  const [blToInspect, setBlToInspect] = useState(null);
  const [factureToInspect, setFactureToInspect] = useState(null);
  const [entete, setEntete] = useState(true);
  const [blToPay, setBlToPay] = useState([]);
  const [displayDate, setDisplayDate] = useState(false);
  const [displayDateRange, setDisplayDateRange] = useState(false);
  const [blsToFacture, setBlsToFacture] = useState([]);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [totalTVA, setTotalTVA] = useState(0);
  const [total, setTotal] = useState(0);
  const [clients, setClients] = useState([]);
  const [totalUnpaied, setTotalUnpaied] = useState(0);
  const [totalPaied, setTotalPaied] = useState(0);
  const [loading, setLoading] = useState(true);
  const [notificationModal, setNotificationModal] = useState(false);
  const [nb, setNb] = useState(1);
  const [allSlected, setAllSelected] = useState(false);

  const [createFactureModalVisible, setCreateFactureModalVisible] = useState(
    false
  );
  const [searchValue, setSearchValue] = useState("");
  const [notifText, setNotifText] = useState(
    "Merci de vérifier les bls suivants"
  );
  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);
  const [selectedDateValue, setSelectedDateValue] = useState("0");
  const [show, setShow] = useState(true);
  const [filtredData, setFiltredData] = useState([]);
  const [isAdminModalVisible, setAdminModalVisible] = useState(false);
  const [text, settext] = useState("");

  const [detailState, setDetailState] = useState({
    client: new Client(),
    visible: false,
  });

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [automatique, setAutomatique] = useState(false);

  const contextActions = useMemo(() => {
    return (
      <Button key="delete" style={{ backgroundColor: "red" }} icon>
        Delete
      </Button>
    );
  }, [blsToFacture]);

  const fetchBLS = async (page, options) => {
    setLoading(true);
    setShow(true);
    setTotalRows(0);
    setTotalUnpaied(0);
    setTotalPaied(0);
    setTotal(0);
    toGetBonsLivraison(page, perPage, options, 1).then((data) => {
      setBonsLivraison(data.res);
      setBonsLivraisonAll(data.res);
      setLoading(false);
      setShow(false);
    });
    toGetTotal(options, 1).then((data) => {
      setTotalRows(data.nb);
      setTotalUnpaied(data.totalUnpaied);
      setTotalPaied(data.totalPaied);
      setTotal(data.total);
    });
  };

  const toUpdateDiscountForBl = () => {
    toUpdateDiscount(remise, blsToFacture).then((res) => {
      setRemiseModal(false);
      fetchBLS(0, {
        paied: selectedMethode,
        client: selectedClient,
        user: selectedUser,
        date: selectedDate,
        dateValue: selectedDateValue,
        ref: searchValue,
      });
    });
  };

  const openModalPay = async () => {
    const processBLs = async () => {
      const bls: any[] = [];

      await Promise.all(
        blsToFacture.map(async (element) => {
          const data = await toGetBL(element.id);
          const bl = JSON.parse(data.bonlivraison);

          if (bl.json.factureId != null) {
            errorMsg(
              "Le Bl a été facturé, merci d'ajouter le règlement sur la facture!"
            );
            return;
          }

          const regl = data.reglements.map((element) => {
            var json = element.data;
            json.caisseValue = element.caisseValue;
            return json;
          });

          const blToUpdate = {
            id: bl.id,
            total: bl.d__total,
            discount: bl.d__discount,
            items: [],
            client: bl.client,
            reglements: regl,
            ref: bl.s__ref,
          };

          bls.push(blToUpdate);
        })
      );

      setBlToPay(bls);
    };

    processBLs();

    //setisBLModalVisible(true);
  };

  const handlePageChange = (page) => {
    fetchBLS(page - 1, {
      paied: selectedMethode,
      client: selectedClient,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setShow(true);

    toGetBonsLivraison(
      page - 1,
      newPerPage,
      {
        paied: selectedMethode,
        client: selectedClient,
        user: selectedUser,
        date: selectedDate,
        dateValue: selectedDateValue,
        ref: searchValue,
      },
      1
    ).then((data) => {
      setBonsLivraison(data.res);
      setBonsLivraisonAll(data.res);
      setLoading(false);
      setShow(false);
    });

    setPerPage(newPerPage);
    setLoading(false);
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "1rem",
      },
    },
  };
  const columns = [
    {
      name: "Client",
      selector: "clientName",
      sortable: true,
      grow: 1.5,
    },

    {
      name: "Crée par",
      selector: "createdBy",
      sortable: true,
      grow: 0.75,
    },
    {
      name: "Date",
      selector: "createdDate",
      sortable: true,
      grow: 1.5,

      format: (row) => `${toConvertDate(row.createdDate)}`,
    },
    {
      name: "Référence",
      selector: "ref",
      sortable: true,
      format: (bl) => (
        <Typography.Link
          level={5}
          style={{ cursor: "pointer" }}
          onClick={() => setBlToInspect(bl.id)}
        >
          {bl.ref}
        </Typography.Link>
      ),
    },
    {
      name: "Client affiché",
      selector: "displayableName",
      sortable: true,
      grow: 1,
      format: (bl) => (
        <Row gutter={24}>
          <Col span={24}>
            <Input
              onBlur={(e) => toUpdateClientName(bl, e.target.value)}
              defaultValue={bl.displayableName}
            />
          </Col>
        </Row>
      ),
    },
    {
      name: "MF affiché",
      selector: "displayableMF",
      sortable: true,
      grow: 1.5,

      format: (bl) => (
        <Row gutter={24}>
          <Col span={18}>
            <Input
              onBlur={(e) => toUpdateMf(bl, e.target.value)}
              defaultValue={bl.displayableMF}
            />
          </Col>
          <Col span={4} style={{ float: "left" }}>
            <Button
              type="link"
              onClick={() => toUpdateClientAndMF(bl)}
              icon={<SaveOutlined />}
            ></Button>
          </Col>
        </Row>
      ),
    },
    {
      name: "Total",
      selector: "total",
      sortable: true,
      grow: 0.75,

      format: (row) => `${numberFormatCurrency(row.total)}`,
    },
    {
      name: "Reste",
      selector: "reste",
      sortable: true,
      grow: 0.75,

      format: (row) => `${numberFormatCurrency(row.reste)}`,
    },
    {
      name: "Payé",
      selector: "paied",
      sortable: true,
      grow: 0.5,

      format: (row) =>
        row.paied ? (
          row.validated ? (
            <span className={"auto--color-success"}>Payé </span>
          ) : (
            <span className={"auto--color-warning"}>Payé</span>
          )
        ) : (
          <span className={"auto--color-danger"}>Non payé</span>
        ),
    },
    {
      name: "Chauffeur",
      selector: "driver",
      grow: 0.5,
      format: (bl) =>
        bl.driver == "-" ? (
          "-"
        ) : bl.driverValid ? (
          <Popconfirm
            title={`Voulez vous supprimer la validation de cette livraison?`}
            onConfirm={() => toValidateDelivery(bl.id)}
            okText="Oui"
            cancelText="Non"
            placement="bottom"
          >
            <Typography.Link className={"auto--color-success"}>
              {bl.driver}
            </Typography.Link>
          </Popconfirm>
        ) : (
          <Popconfirm
            title={`Voulez vous valider cette livraison?`}
            onConfirm={() => toValidateDelivery(bl.id)}
            okText="Oui"
            cancelText="Non"
            placement="bottom"
          >
            <Typography.Link className={"auto--color-warning"}>
              {bl.driver}
            </Typography.Link>
          </Popconfirm>
        ),
    },
    {
      name: "Note",
      selector: "note",
      grow: 0.5,
    },
    {
      name: "Facture",
      selector: "factureRef",
      sortable: true,
      grow: 0.75,

      format: (bl) =>
        bl.factureId && (
          <Typography.Link onClick={() => setFactureToInspect(bl.factureId)}>
            {bl.factureRef}
          </Typography.Link>
        ),
    },
  ];

  useEffect(() => {
    toGetCode().then((res) => {
      setRemise(res.remise);
    });
    AppStore.setFirst(1);
    AppStore.setSecond(8);

    fetchBLS(0, {
      paied: selectedMethode,
      client: selectedClient,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
    toGetClientsListIds().then((_clients) => {
      setClients(_clients);
    });
    AppStore.setFirst(1);
    AppStore.setSecond(8);

    toGetUsers().then((users) => {
      setUsers(users);
    });
  }, []);

  const actualise = (nb) => {
    setShow(true);
    fetchBLS(0, {
      paied: selectedMethode,
      client: selectedClient,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const toUpdateData = (values) => {
    setBonsLivraison(values);
    setFilterModalVisible(false);
  };

  const onSearch = (e, data) => {
    // var bons = null != data ? data : filtredData;
    // setSearchValue(e.target.value);
    // const updatedList = customFilter(e.target.value, bons, [
    //   "clientName",
    //   "total",
    //   "ref",
    //   "note",
    //   "date",
    //   "displayableName",
    //   "displayableMF",
    // ]);

    // setBonsLivraison(updatedList);
    // if (updatedList.length == 1) {
    //   toggleFactureBL(updatedList[0]);
    // } else {
    //   setBlsToFacture([]);
    // }
    setSearchValue(e);

    setLoading(true);

    fetchBLS(0, {
      paied: selectedMethode,
      client: selectedClient,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: e,
    });
  };
  const getHeader = () => {
    var result = "";
    if (selectedMethode != "2") {
      result += "-Etat: ";
      result += selectedMethode === "0" ? "Non payé" : "Payé";
    }
    if (selectedDate != "0") {
      result += " -Date: ";
      switch (selectedDate) {
        case "1":
          result += " Hier ";
          break;
        case "2":
          result += " Aujourd'hui ";
          break;
        case "3":
          result += " Ce mois ";
          break;
        case "5":
          result += " " + selectedDateValue;
          break;
        case "6":
          result += " " + selectedDateValue[0] + "->" + selectedDateValue[1];
          break;

        default:
          break;
      }
    }

    return result;
  };

  const printBl = (e) => {
    e.preventDefault();
    var titre1 = true;
    if (e.type == "contextmenu") {
      titre1 = false;
    }
    if (allSlected && blsToFacture.length > 1) {
      printAllBls({
        paied: selectedMethode,
        client: selectedClient,
        user: selectedUser,
        date: selectedDate,
        dateValue: selectedDateValue,
        ref: searchValue,
        titre1,
        header: getHeader(),
      });
    } else if (blsToFacture.length == 1) {
      toPrintBL(blsToFacture[0].id, titre1, entete).then(() => {
        var index = bonsLivraison.findIndex((a) => a.id == blsToFacture[0].id);
        let newbls = [...bonsLivraison];
        newbls[index].locked = 1;
        setBonsLivraison(newbls);
      });
    } else {
      var ids = [];
      blsToFacture.forEach((element) => {
        ids.push(element.id);
      });
      toPrintBLs(ids, titre1);
    }
  };

  const printMultipleBls = () => {
    var ids = [];
    blsToFacture.forEach((element) => {
      ids.push(element.id);
    });
    toPrintMultipleBLs(ids);
  };

  const toggleFactureBL = (bl) => {
    const blExists = blsToFacture.find((blf) => blf.id == bl.id);

    if (blExists) {
      setBlsToFacture(blsToFacture.filter((blf) => blf.id != bl.id));
      setTotalRows(total - bl.total);
      setTotalTVA(totalTVA - bl.tva);
    } else {
      setBlsToFacture(blsToFacture.concat(bl));
      setTotalRows(total + bl.total);
      setTotalTVA(totalTVA + bl.tva);
    }
  };

  const creerFacture = () => {
    //setLoading(true);
    toGetBL(blsToFacture[0].id).then((data) => {
      var index = bonsLivraison.findIndex((a) => a.id == blsToFacture[0].id);
      let newbls = [...bonsLivraison];
      newbls[index].locked = 1;
      setBonsLivraison(newbls);
      var bl = JSON.parse(data.bonlivraison);
      if (bl.json.factureId != null) {
        errorMsg("Le Bl a été déjà facturé!");
      } else {
        setCreateFactureModalVisible(true);
      }

      // toCreateFacture(blsToFacture).then(() => {
      //   setBlsToFacture([]);
      //   toGetBonsLivraison().then((data) => {
      //     history.push("/vente/factures");
      //     cookie.save("selectedMenu", 9, { path: "/" });
      //   });
      // });
    });
  };

  const toUpdateMf = (bl, value) => {
    const newBls = [...bonsLivraison];
    const index = newBls.findIndex((a) => a.id === bl.id);
    newBls[index].displayableMF = value;
    setBonsLivraison(newBls);
  };

  const toUpdateClientName = (bl, value) => {
    const newBls = [...bonsLivraison];
    const index = newBls.findIndex((a) => a.id === bl.id);

    newBls[index].displayableName = value;
    setBonsLivraison(newBls);
  };

  const toUpdateClientAndMF = (quote) => {
    var data = {
      clientName: quote.displayableName,
      mf: quote.displayableMF,
      id: quote.id,
    };
    toUpdateBlData(data).then(() => {
      successMsg("informations mis à jours avec succée! ");
    });
  };

  const updateBl = (bl) => {
    const win = window.open(
      "/vente/bl/modifier/" + bl.id,

      "_blank"
    );
    if (win != null) {
      win.focus();
    }
  };

  const toDisplayDelete = () => {
    var display = true;
    blsToFacture.forEach((element) => {
      if (element.locked || element.paied || element.factureId != null) {
        display = false;
        return;
      }
    });

    return display;
  };
  const toDisplayRemise = () => {
    var display = true;
    blsToFacture.forEach((element) => {
      if (element.discount) {
        display = false;
        return;
      }
    });

    return display;
  };
  const toDisplayFacture = () => {
    var display = true;

    for (let index = 1; index < blsToFacture.length; index++) {
      const element = blsToFacture[index];
      if (element.clientId != blsToFacture[index - 1].clientId) {
        display = false;
      }
    }

    blsToFacture.forEach((element) => {
      if (element.factureId != null) {
        display = false;
        return;
      }
    });

    return display;
  };

  const toDiplayDelevery = () => {
    var display = true;
    blsToFacture.forEach((element) => {
      if (element.delivered) {
        display = false;
      }
    });
    return display;
  };

  const toDisplayPrint = () => {
    return true;
    var display = true;
    for (let index = 1; index < blsToFacture.length; index++) {
      const element = blsToFacture[index];
      if (element.clientId != blsToFacture[index - 1].clientId) {
        display = false;
      }
    }
    return display;
  };

  const onSelectRow = (e) => {
    setAllSelected(e.allSelected);
    const list = e.selectedRows;
    list.forEach((element) => {
      // toGetBL(element.id).then((data) => {
      //   var bl = JSON.parse(data.bonlivraison);
      //   var index = bonsLivraison.findIndex((a) => a.id == element.id);
      //   let newbls = [...bonsLivraison];
      //   newbls[index] = bl.json;
      //   setBonsLivraison(newbls);
      // });
    });
    setBlsToFacture(list);
  };

  const onAddBl = (bl) => {
    blsToFacture.indexOf(bl) === -1
      ? setBlsToFacture(blsToFacture.concat(bl))
      : console.log("This item already exists");
  };

  const onRemoveBl = (bl) => {
    setBlsToFacture(blsToFacture.filter((blf) => blf.id != bl.id));
    if (blsToFacture.filter((blf) => blf.id != bl.id).length == 0) {
      setCreateFactureModalVisible(false);
    }
  };

  const toUpdate = (reglements) => {
    if (blToPay.length > 0) {
      payMultipleBls(blToPay, reglements);
    } else {
      validate(-1, reglements);
    }
  };

  const payMultipleBls = (blToPay, reglements) => {
    const ids = blToPay.map((el) => el.id);
    payBls(ids, reglements).then((res) => {
      actualise(nb);
      setBlToPay([]);
    });
  };
  const validate = (id, reglements) => {
    const bl = { ...blToPay[0] };
    bl.reglements = reglements;
    setBlToPay(bl);
    setAdminModalVisible(false);

    var totalPayé = bl.reglements.reduce((a, b) => b.montant + a, 0);
    if (
      parseFloat(totalPayé).toFixed(3) < parseFloat(bl.total).toFixed(3) &&
      !isAdminModalVisible &&
      !remiseChecked &&
      bl.client.d__threshold == 0
    ) {
      settext("Le BL n'est pas totalement payé!");

      setAdminModalVisible(true);
      return;
    }

    if (
      parseFloat(totalPayé).toFixed(3) < parseFloat(bl.total).toFixed(3) &&
      remiseChecked &&
      !warningModal
    ) {
      warningModal = true;
      Modal.warning({
        okButtonProps: { style: { display: "none" } },

        content: (
          <div>
            <p>
              Voulez vous vraiment ajouter le reste(
              {(bl.total - totalPayé).toFixed(3)} )comme étant une remise!
            </p>
            <div style={{ textAlign: "center", marginTop: "2vh" }}>
              <Button type="primary" onClick={() => toUpdateTotal(id)}>
                Valider
              </Button>
              <Button
                style={{ marginLeft: "2vh" }}
                danger
                onClick={() => {
                  Modal.destroyAll();
                  warningModal = false;
                }}
              >
                Annuler
              </Button>
            </div>
          </div>
        ),
      });
      return;
    }
    Modal.destroyAll();
    warningModal = false;

    payBl(bl.id, remiseChecked, bl.reglements, id).then((res) => {
      actualise(nb);
      setisBLModalVisible(false);
    });
  };

  const toUpdateTotal = (id) => {
    const bl = { ...blToPay };
    var totalPayé = bl.reglements.reduce((a, b) => b.montant + a, 0);
    bl.discount = (bl.total - totalPayé).toFixed(3);
    setBlToPay(bl);
    validate(id, bl.reglements);
  };

  const onChange = () => {
    setEntete(!entete);
  };
  const deleteBL = () => {
    var ids = [];
    blsToFacture.forEach((element) => {
      ids.push(element.id);
    });
    setLoading(true);
    toDeleteBL(ids).then(() => {
      fetchBLS(0, {
        paied: selectedMethode,
        client: selectedClient,
        user: selectedUser,
        date: selectedDate,
        dateValue: selectedDateValue,
        ref: searchValue,
      });
      setBlsToFacture([]);
    });
  };

  const toValidateDelivery = (id) => {
    validateDelivery(id, "bl").then((res) => {
      fetchBLS(0, {
        paied: selectedMethode,
        client: selectedClient,
        user: selectedUser,
        date: selectedDate,
        dateValue: selectedDateValue,
        ref: searchValue,
      });
    });
  };

  const onChangeCredit = (option) => {
    setSelectedMethode(option);
    fetchBLS(0, {
      paied: option,
      client: selectedClient,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const saveClientUpdate = () => {
    if (clientToSet == -1) {
      errorMsg("merci d'ajouter un client");
      return;
    }
    var ids = [];
    blsToFacture.forEach((element) => {
      ids.push(element.id);
    });

    saveClientUpdateApi(ids, clientToSet).then((res) => {
      actualise(0);
      setModalClient(false);
      setClientToSet(-1);
      successMsg("Client Modifié avec succès");
    });
  };

  const onChangeClientSet = (option) => {
    const id = clients.filter((el) => el.name == option)[0].id;
    setClientToSet(id);
  };
  const onChangeClient = (option) => {
    const id =
      option == "0" ? "0" : clients.filter((el) => el.name == option)[0].id;
    setSelectedClient(id);
    fetchBLS(0, {
      paied: selectedMethode,
      client: id,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const onChangeUsers = (option) => {
    var id = "0";
    if (option != "0") {
      id = users.filter((el) => el.username == option)[0].id;
    }
    setSelectedUser(id);
    fetchBLS(0, {
      paied: selectedMethode,
      client: selectedClient,
      user: id,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const onChangeDate = (option) => {
    if (option == "5") {
      setSelectedDate(option);
      setDisplayDate(true);
      setDisplayDateRange(false);
      return;
    }
    if (option == "6") {
      setSelectedDate(option);
      setDisplayDateRange(true);
      setDisplayDate(false);
      return;
    }
    setDisplayDate(false);

    setSelectedDate(option);

    fetchBLS(0, {
      paied: selectedMethode,
      client: selectedClient,
      user: selectedUser,
      date: option,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const sendNotification = () => {
    setNotificationModal(false);
    var bls = [];
    blsToFacture.forEach((element) => {
      bls.push(element.id);
    });
    toSendNotification(notifText, bls).then(() => {
      successMsg("Notification envoyée avec succés");
    });
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.code !== -1 && !row.paied,
      style: {
        backgroundColor: "#fff2cc",
      },
    },
    {
      when: (row) => row.blockBonus && !row.paied,
      style: {
        backgroundColor: "#edbbbb",
      },
    },
  ];

  const unlockBL = (userId) => {
    toUnlockBL(blsToFacture, userId).then(() => {
      let newBLS = [...bonsLivraison];
      blsToFacture.forEach((element) => {
        let index = bonsLivraison.findIndex((c) => c.id == element.id);
        newBLS[index].locked = 0;
        element.locked = 0;
      });

      setBonsLivraison(newBLS);
      setBonsLivraisonAll(newBLS);
    });
  };

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);
  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    searchMultiplesBls(newTags);
    setTags(newTags);
  };
  const showInput = () => {
    setInputVisible(true);
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
      searchMultiplesBls([...tags, inputValue]);
    }

    setInputVisible(false);
    setInputValue("");
  };
  const searchMultiplesBls = (i__tags) => {
    if (i__tags.length === 0) {
      fetchBLS(0, {
        paied: selectedMethode,
        client: selectedClient,
        user: selectedUser,
        date: selectedDate,
        dateValue: selectedDateValue,
        ref: searchValue,
      });
      return;
    }
    toSearcheMultiplesBls(i__tags).then((data) => {
      setBonsLivraison(data);
      setBonsLivraisonAll(data);
      setLoading(false);
      setShow(false);
    });
  };
  const forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
        }}
      >
        {tagElem}
      </span>
    );
  };

  const tagChild = tags.map(forMap);
  const tagPlusStyle = {
    borderStyle: "dashed",
  };

  const sendWhatsapp = () => {
    toSaveBLPDF(blsToFacture[0].id, true, true).then(() => {
      const api = "https://back.cody.tn/pdf/bl" + blsToFacture[0].ref + ".pdf";
      const url =
        "https://web.whatsapp.com/send?phone=+216" +
        phone +
        "&text=" +
        api +
        "&app_absent=0";
      window.open(url);
      setWhatsappModal(false);
    });
  };

  const toDisplayCoin = () => {
    var display = true;
    blsToFacture.forEach((element) => {
      if (element.factureId !== null || element.paied) {
        display = false;
      }
    });
    return (
      display &&
      blsToFacture.every((obj, _, array) => obj.clientId === array[0].clientId)
    );
  };

  return (
    <div id="vente">
      <Loading show={show} color="red" showSpinner={true} />
      <Form layout="vertical">
        <Row className="auto--custom-header">
          <Col span="4">
            <Typography.Title level={5}>
              LISTE DES BON DE LIVRAISONS
            </Typography.Title>
          </Col>
          <Col
            span={clientId ? "5" : "3"}
            style={{ textAlign: "right" }}
            justify="end"
          >
            <Form.Item label="Réf,note,client/mf affiché">
              <Input.Search onSearch={onSearch} enterButton />
            </Form.Item>
          </Col>
          <Col span="2">
            <Form.Item label="Crédit">
              <Select
                onSelect={onChangeCredit}
                style={{ width: "80%" }}
                defaultValue="2"
              >
                <Option value="2">Tout</Option>
                <Option value="0">
                  <span className={"auto--color-danger"}>Non payé</span>
                </Option>
                <Option value="1">
                  <span className={"auto--color-success"}>Payé</span>
                </Option>
                <Option value="3">
                  <span className={"auto--color-warning"}>Payé</span>
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item label="Date">
              <Select
                showSearch
                onSelect={onChangeDate}
                style={{ width: "100%" }}
                defaultValue={cookie.load("role") == "Super Admin" ? "0" : "2"}
              >
                <Option value="0">Tout</Option>

                <Option value="1">Hier</Option>
                <Option value="2">Aujourd'hui</Option>
                <Option value="3">Ce mois</Option>
                {/* <Option value="4">Cette année</Option>*/}
                <Option value="5">Date Spécifique</Option>
                <Option value="6">Période Spécifique</Option>
              </Select>
            </Form.Item>
            {displayDate && (
              <DatePicker
                style={{ width: "100%" }}
                onChange={(e, date) => {
                  setSelectedDateValue(date);
                  fetchBLS(0, {
                    paied: selectedMethode,
                    client: selectedClient,
                    user: selectedUser,
                    date: selectedDate,
                    dateValue: date,
                    ref: searchValue,
                  });
                }}
              />
            )}
            {displayDateRange && (
              <DatePicker.RangePicker
                onChange={(e, date) => {
                  setSelectedDateValue(date);
                  fetchBLS(0, {
                    paied: selectedMethode,
                    client: selectedClient,
                    user: selectedUser,
                    date: selectedDate,
                    dateValue: date,
                    ref: searchValue,
                  });
                }}
              />
            )}
          </Col>

          {!clientId && (
            <Col span="4">
              <Form.Item label="Client">
                <Select
                  showSearch
                  onSelect={onChangeClient}
                  style={{ width: "80%" }}
                  defaultValue="0"
                >
                  <Option value="0">Tout</Option>

                  {clients.map((c) => (
                    <Option value={c.name}>{c.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col span="4">
            {users.length > 0 && (
              <Form.Item label="Utilisateurs">
                <Select
                  showSearch
                  onSelect={onChangeUsers}
                  style={{ width: "80%" }}
                  defaultValue={
                    cookie.load("role") == "Super Admin"
                      ? "0"
                      : users.length > 0 &&
                        users.filter((u) => u.id == cookie.load("id"))[0]
                          .username
                  }
                >
                  <Option value="0">Tout</Option>

                  {users
                    .filter((a) => a.active)
                    .map((u) => (
                      <Option value={u.username}>{u.username}</Option>
                    ))}
                </Select>
              </Form.Item>
            )}
          </Col>

          {!clientId && (
            <Col span="2" style={{ textAlign: "right" }}>
              {/* <Button
              type="link"
              onClick={(e) => setFilterModalVisible(true)}
              icon={
                <img
                  src="/images/filterIconVente.svg"
                  style={{ width: "3vh", height: "3vh" }}
                />
              }
              style={{ marginRight: "5px" }}
            ></Button> */}
              <Link key="1" to="/vente/bl/creer">
                <Button
                  type="link"
                  size="large"
                  icon={<img src="/images/plusVente.svg" />}
                ></Button>
              </Link>
              <Tooltip title="Actualiser la liste des bls">
                <Button
                  onClick={() => actualise(nb)}
                  type="link"
                  size="large"
                  icon={<img src="/images/refresh.svg" />}
                ></Button>
              </Tooltip>
              <Tooltip title="Facture automatique">
                <Button
                  type="link"
                  onClick={() => setAutomatique(true)}
                  style={{ marginLeft: "1vh" }}
                  icon={
                    <img
                      src="/images/factureBlack.svg"
                      className="auto--icon-list"
                    ></img>
                  }
                ></Button>
              </Tooltip>
            </Col>
          )}
        </Row>
        {!clientId && (
          <Row className="auto--custom-header">
            <Col span="8">
              <>
                <div
                  style={{
                    marginBottom: 16,
                  }}
                >
                  <TweenOneGroup
                    enter={{
                      scale: 0.8,
                      opacity: 0,
                      type: "from",
                      duration: 100,
                    }}
                    onEnd={(e) => {
                      if (e.type === "appear" || e.type === "enter") {
                        e.target.style = "display: inline-block";
                      }
                    }}
                    leave={{
                      opacity: 0,
                      width: 0,
                      scale: 0,
                      duration: 200,
                    }}
                    appear={false}
                  >
                    {tagChild}
                  </TweenOneGroup>
                </div>
                {inputVisible ? (
                  <Input
                    ref={inputRef}
                    type="text"
                    size="large"
                    style={{
                      width: 150,
                    }}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                  />
                ) : (
                  <Tag onClick={showInput} style={tagPlusStyle}>
                    <PlusOutlined /> Recherche plusierurs BL(s)
                  </Tag>
                )}
              </>
            </Col>
          </Row>
        )}
      </Form>
      <Row gutter="24" style={{ marginTop: "1vh" }}>
        <Col span="23">
          <Skeleton active loading={loading} />
          {blsToFacture.length > 0 && (
            <Descriptions
              bordered
              title="Séléction"
              size="small"
              column={4}
              style={{ backgroundColor: "#e3f2fd", margin: "10px" }}
            >
              <Descriptions.Item
                label="NB bl séléctionné(s)"
                className="item-label"
              >
                {blsToFacture.length + " bl(s)"}
              </Descriptions.Item>
              <Descriptions.Item label="Total TTC" className="item-label">
                {blsToFacture.reduce((a, b) => b.total + a, 0).toFixed(3) +
                  " DT"}
              </Descriptions.Item>
              <Descriptions.Item label="Total payé" className="item-label">
                {blsToFacture
                  .reduce((a, b) => b.total - b.reste + a, 0)
                  .toFixed(3) + " DT"}
              </Descriptions.Item>
              <Descriptions.Item label="Total Reste" className="item-label">
                {blsToFacture
                  .filter((el) => el.paied == 0)
                  .reduce((a, b) => b.reste + a, 0)
                  .toFixed(3) + " DT"}
              </Descriptions.Item>
            </Descriptions>
          )}

          <DataTable
            conditionalRowStyles={conditionalRowStyles}
            selectA
            customStyles={customStyles}
            selectableRows
            noHeader
            striped
            selectableRowsHighlight
            fixedHeader
            onSelectedRowsChange={(e) => onSelectRow(e)}
            persistTableHead
            noDataComponent={<span>Pas de bon de livraisons trouvés</span>}
            contextMessage={{
              singular: "élément",
              plural: "éléments",
              message: "séléctionné(s)",
            }}
            columns={clientId ? columns.slice(1) : columns}
            data={bonsLivraison}
            pagination
            paginationRowsPerPageOptions={[10, 20, 50, 100]}
            paginationServer
            paginationTotalRows={totalRows}
            contextActions={contextActions}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
          {total != "0" && (
            <>
              <Descriptions
                bordered
                size="small"
                style={{ backgroundColor: "white", marginTop: "3px" }}
              >
                <Descriptions.Item label="Total TTC" className="item-label">
                  {total + " DT"}
                </Descriptions.Item>
                <Descriptions.Item label="Total payé" className="item-label">
                  {totalPaied + " DT"}
                </Descriptions.Item>
                <Descriptions.Item label="Total Reste" className="item-label">
                  {totalUnpaied + " DT"}
                </Descriptions.Item>
              </Descriptions>
            </>
          )}
        </Col>

        <Col span="1" className="auto--background-grey">
          {blsToFacture.length > 0 && (
            <div style={{ marginTop: "5vh" }}>
              {toDisplayPrint() && (
                <>
                  <Tooltip
                    title="Pour imprimer avec/sans entête"
                    color="#58c5cc"
                    placement="leftTop"
                  >
                    <Switch checked={entete} onChange={() => onChange()} />
                  </Tooltip>
                  <Tooltip title="Imprimer" color="#58c5cc" placement="leftTop">
                    <Button
                      type="link"
                      onClick={(e) => printBl(e)}
                      onContextMenu={(e) => printBl(e)}
                      style={{ marginTop: "2vh" }}
                      icon={
                        <img
                          src="/images/print.svg"
                          className="auto--icon-list"
                        ></img>
                      }
                    ></Button>
                  </Tooltip>
                </>
              )}
              {blsToFacture.length > 1 && (
                <>
                  <Tooltip
                    title="Imprimer les bl(s) séléctionnées"
                    color="#58c5cc"
                    placement="leftTop"
                  >
                    <Button
                      type="link"
                      onClick={(e) => printMultipleBls(e)}
                      style={{ marginTop: "2vh" }}
                      icon={<PrinterFilled />}
                    ></Button>
                  </Tooltip>
                </>
              )}

              {blsToFacture.length === 1 && (
                <>
                  <Tooltip
                    placement="leftTop"
                    title="Détails du bon de livraison"
                    color="#58c5cc"
                  >
                    <Button
                      disabled={blsToFacture.length > 1}
                      type="link"
                      style={{ marginTop: "2vh" }}
                      onClick={() => setBlToInspect(blsToFacture[0].id)}
                      icon={
                        <img
                          src="/images/info.svg"
                          className="auto--icon-list"
                        ></img>
                      }
                    ></Button>
                  </Tooltip>
                </>
              )}
              {blsToFacture.length === 1 &&
                !blsToFacture[0].paied &&
                !blsToFacture[0].locked &&
                blsToFacture[0].factureId == null && (
                  <>
                    <Tooltip
                      title="Modifier le bon de livraison"
                      color="#58c5cc"
                      placement="leftTop"
                    >
                      <Button
                        type="link"
                        disabled={blsToFacture.length > 1}
                        style={{ marginTop: "2vh" }}
                        onClick={() => updateBl(blsToFacture[0])}
                        icon={
                          <img
                            src="/images/edit.svg"
                            className="auto--icon-list"
                          ></img>
                        }
                      ></Button>
                    </Tooltip>
                  </>
                )}

              {toDisplayDelete() && (
                <>
                  <Popconfirm
                    title={`Voulez vous vraiment supprimer ce bon de livraison?`}
                    onConfirm={() => deleteBL()}
                    okText="Oui"
                    cancelText="Non"
                    placement="left"
                  >
                    <Button
                      type="link"
                      style={{ marginTop: "2vh" }}
                      icon={
                        <img
                          src="/images/trash.svg"
                          className="auto--icon-list"
                        ></img>
                      }
                    ></Button>
                  </Popconfirm>
                  {toDisplayRemise() && (
                    <Tooltip placement="leftTop" title="Remise" color="#58c5cc">
                      <Button
                        type="link"
                        style={{ marginTop: "2vh" }}
                        onClick={() => setRemiseModal(true)}
                        icon={<PercentageOutlined />}
                      ></Button>
                    </Tooltip>
                  )}
                </>
              )}
              {toDisplayFacture() && (
                <Tooltip
                  title="Pour facturer le(s) bon(s) de livraison"
                  color="#58c5cc"
                  placement="leftTop"
                >
                  <Button
                    type="link"
                    onClick={() => creerFacture()}
                    style={{ marginTop: "2vh" }}
                    icon={
                      <img
                        src="/images/factureBlack.svg"
                        className="auto--icon-list"
                      ></img>
                    }
                  ></Button>
                </Tooltip>
              )}
              {toDisplayCoin() && (
                <Tooltip
                  title="payer le bon livraison"
                  color="#58c5cc"
                  placement="leftTop"
                >
                  <Button
                    type="link"
                    onClick={() => openModalPay()}
                    style={{ marginTop: "3vh" }}
                    icon={
                      <img
                        src="/images/coin.svg"
                        className="auto--icon-list"
                      ></img>
                    }
                  ></Button>
                </Tooltip>
              )}

              {cookie.load("role") == "Super Admin" && (
                <Tooltip
                  title="Pour envoyer une notification"
                  color="#58c5cc"
                  placement="leftTop"
                >
                  <Button
                    type="link"
                    onClick={() => setNotificationModal(true)}
                    style={{ marginTop: "3vh" }}
                    size="large"
                    icon={<NotificationTwoTone />}
                  ></Button>
                </Tooltip>
              )}
              {cookie.load("removeDuplicata") == "true" &&
                blsToFacture.filter((el) => el.locked).length > 0 && (
                  <Tooltip
                    title="supprimer le duplicata"
                    color="#58c5cc"
                    placement="leftTop"
                  >
                    <Button
                      type="link"
                      onClick={() => unlockBL()}
                      style={{ marginTop: "2vh" }}
                      icon={
                        <img
                          src="/images/unlock.png"
                          className="auto--icon-list"
                        ></img>
                      }
                    ></Button>
                  </Tooltip>
                )}
              {blsToFacture.length > 0 && (
                <Tooltip
                  title="Envoyer par email"
                  color="#58c5cc"
                  placement="leftTop"
                >
                  <Button
                    type="link"
                    onClick={(e) => {
                      setEmailModal(true);
                    }}
                    size="large"
                    style={{ marginTop: "2vh" }}
                    icon={<MailOutlined />}
                  ></Button>
                </Tooltip>
              )}
              {cookie.load("role") == "Super Admin" && toDiplayDelevery() && (
                <Tooltip
                  title="Ajouter un livreur"
                  color="#58c5cc"
                  placement="leftTop"
                >
                  <Button
                    type="link"
                    onClick={(e) => {
                      setDeliveryModal(true);
                    }}
                    size="large"
                    style={{ marginTop: "2vh" }}
                    icon={<GrDeliver />}
                  ></Button>
                </Tooltip>
              )}
              {cookie.load("role") == "Super Admin" && (
                <Tooltip
                  title="Modifier le client de(s) bl(s)"
                  color="#58c5cc"
                  placement="leftTop"
                >
                  <Button
                    type="link"
                    onClick={(e) => {
                      setModalClient(true);
                    }}
                    size="large"
                    style={{ marginTop: "2vh" }}
                    icon={<TeamOutlined />}
                  ></Button>
                </Tooltip>
              )}
              {blsToFacture.length === 1 && (
                <Tooltip
                  title="Envoyer par whatsapp"
                  color="#58c5cc"
                  placement="leftTop"
                >
                  <Button
                    type="link"
                    onClick={(e) => {
                      setWhatsappModal(true);
                    }}
                    size="large"
                    style={{ marginTop: "2vh" }}
                    icon={<WhatsAppOutlined />}
                  ></Button>
                </Tooltip>
              )}
            </div>
          )}
        </Col>
      </Row>
      <Modal
        title="Envoyer par WhatsApp"
        visible={whatsappModal}
        footer={[]}
        onCancel={() => setWhatsappModal(false)}
      >
        <Input
          placeholder="Numéro WhatsApp"
          onChange={(e) => setPhone(e.target.value)}
        ></Input>
        <Button onClick={() => sendWhatsapp()}>Envoyer</Button>
      </Modal>
      {createFactureModalVisible && (
        <BlToFacture
          AppStore={AppStore}
          onAddBl={(bl) => onAddBl(bl)}
          onRemoveBl={(bl) => onRemoveBl(bl)}
          setCreateFactureModalVisible={(v) => setCreateFactureModalVisible(v)}
          visible={createFactureModalVisible}
          blsToFacture={blsToFacture}
        />
      )}
      <Modal
        title="Remise"
        visible={remiseVisible}
        width="800px"
        onCancel={() => setRemiseModal(false)}
        onOk={() => toUpdateDiscountForBl(remise, blsToFacture)}
      >
        <Descriptions bordered>
          <Descriptions.Item
            span={3}
            label="Sur les articles de type adaptable"
          >
            <input
              type="number"
              min="0"
              style={{ fontSize: "24px", fontWeight: "bold" }}
              value={remise.adaptable}
              onChange={(e) =>
                setRemise({ ...remise, adaptable: e.target.value })
              }
            />
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Sur les articles de type origine">
            <>
              <input
                type="number"
                min="0"
                style={{ fontSize: "24px", fontWeight: "bold" }}
                value={remise.origine}
                onChange={(e) =>
                  setRemise({ ...remise, origine: e.target.value })
                }
              />
            </>
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            label="Sur les articles de catégories batterie"
          >
            <>
              <input
                type="number"
                min="0"
                style={{ fontSize: "24px", fontWeight: "bold" }}
                value={remise.batterie}
                onChange={(e) =>
                  setRemise({ ...remise, batterie: e.target.value })
                }
              />
            </>
          </Descriptions.Item>
        </Descriptions>
      </Modal>
      <Modal
        className="modal-large"
        visible={blToPay.length > 0}
        onCancel={() => setBlToPay([])}
        footer={[]}
      >
        <ReglementBLList
          bls={blToPay}
          validate={toUpdate}
          setRemiseChecked={(checked) => setRemiseChecked(checked)}
        />
      </Modal>
      <BonLivraisonModal
        entete={entete}
        id={blToInspect}
        onClose={() => setBlToInspect(null)}
      />
      <FactureModal
        id={null != factureToInspect && factureToInspect}
        onClose={() => setFactureToInspect(null)}
      />
      {automatique && (
        <FactureAutomatique
          visible={automatique}
          onClose={() => setAutomatique(false)}
        />
      )}
      <Modal
        className="modal-small"
        title={"Filtrer"}
        visible={filterModalVisible}
        footer={[]}
        onCancel={(e) => setFilterModalVisible(false)}
      >
        <Search
          toUpdateData={toUpdateData}
          fields={fields}
          list={bonsLivraisonALL}
        />
      </Modal>
      <Modal
        title="Envoyer une notification"
        visible={notificationModal}
        footer={[]}
        onCancel={(e) => setNotificationModal(false)}
      >
        <Row>
          <Col span="8">Texte à envoyer</Col>
          <Col span="16">
            <Input
              value={notifText}
              onChange={(e) => setNotifText(e.target.value)}
            />
          </Col>
        </Row>

        <div style={{ marginTop: "2vh" }}>
          Le(s) bons de livraisons séléctionné(s):
        </div>
        <ul>
          {blsToFacture.map((e) => (
            <li>{e.ref}</li>
          ))}
        </ul>
        <div style={{ textAlign: "center" }}>
          <Button type="primary" onClick={() => sendNotification()}>
            Envoyer la notification
          </Button>
        </div>
      </Modal>
      <ClientDetail
        client={detailState.client}
        visible={detailState.visible}
        dismiss={() => setDetailState({ client: new Client(), visible: false })}
      />

      <ModalCode
        isAdminModalVisible={isAdminModalVisible}
        setAdminModalVisible={() => {
          setAdminModalVisible(false);
        }}
        onOk={(id) => validate(id, blToPay.reglements)}
        text={text}
      />
      <Email
        id={blsToFacture.length > 0 && blsToFacture}
        setEmailModal={() => setEmailModal(false)}
        visible={emailModal}
        type="bl"
      />
      <Delivery
        visible={deliveryModal}
        setDeliveryModal={() => {
          setDeliveryModal(false);
          actualise(0);
        }}
        type="bl"
        ids={blsToFacture}
      />
      <Modal
        title="modifier client"
        onCancel={() => setModalClient(false)}
        onOk={() => saveClientUpdate()}
        visible={modalClient}
      >
        <p>Attention les bls suivants vont être modifier</p>
        {blsToFacture.map((el) => (
          <p>
            <b>{el.ref}</b>
          </p>
        ))}
        <Select
          showSearch
          onSelect={onChangeClientSet}
          style={{ width: "80%" }}
        >
          {clients.map((c) => (
            <Option value={c.name}>{c.name}</Option>
          ))}
        </Select>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBlToUpdate: (bl) => dispatch(setItemsState(bl)),
  };
};

export default connect(null, mapDispatchToProps)(ListeBonLivraison);
