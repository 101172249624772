import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Typography,
  Skeleton,
} from "antd";
import { useState, useEffect, useRef } from "react";
import {
  toGetEmplacementList,
  toDeleteEmplacement,
  toAddOrUpdateEmplacement,
} from "./Api";
import { PlusOutlined } from "@ant-design/icons";

import { BiPencil, BiTrash } from "react-icons/bi";
import { errorMsg, successMsg, toConvertDate } from "../../Utils/Utils";
import "../../Styles/TwoTables.scss";

import Search from "../../Utils/Search";
import DataTable from "react-data-table-component";
import SearchInput from "./SearchInput";

const Emplacement = (props) => {
  const [loading, setLoading] = useState(true);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [emplacements, setEmplacements] = useState([]);
  const [emplacementsAll, setEmplacementsAll] = useState([]);
  const [title, setTitle] = useState("");
  const [id, setId] = useState(-1);
  const [form] = Form.useForm();
  const search = useRef(null);

  const fields = [
    { name: "Date de création", field: "createdDate", type: "date" },
    { name: "Titre", field: "title", type: "varchar" },
    { name: "Crée par", field: "createdBy", type: "varchar" },
  ];

  const okFilter = () => {
    search.current.getRules();
  };

  const closeCreateModal = () => {
    if (title == "") {
      errorMsg("veuillez remplir tous les champs obligatoires!");
      return;
    }
    toAddOrUpdateEmplacement(title, id).then((res) => {
      successMsg(
        id != -1
          ? "Emplacement mis à jours avec succées!"
          : "Emplacement ajoutée avec succées!"
      );
      toGetDatas();
    });
    setId(-1);
    setCreateModalVisible(false);
    setTitle("");
  };

  const columns = [
    {
      name: "Date de création",
      selector: "createdDate",
      sortable: true,
      grow: 1,
      format: (c) => toConvertDate(c.createdDate),
    },
    {
      name: "Catégorie",
      selector: "title",
      sortable: true,
      grow: 2.5,
    },

    {
      name: "Crée par",
      selector: "createdBy",
      sortable: true,
      grow: 1,
    },
    {
      name: "",
      selector: "id",
      sortable: true,
      format: (emplacement) => (
        <>
          <Button
            onClick={() => toEdit(emplacement.id, emplacement.title)}
            icon={<BiPencil />}
          />
          <Popconfirm
            title={`Voulez vous vraiment supprimer ${emplacement.title}?`}
            onConfirm={(e) => toDelete(emplacement.id)}
            okText="Oui"
            cancelText="Non"
          >
            <Button icon={<BiTrash />} />
          </Popconfirm>
        </>
      ),
    },
  ];

  useEffect(() => {
    toGetDatas();
  }, []);
  const toGetDatas = () => {
    toGetEmplacementList().then((res) => {
      setEmplacements(res);
      setEmplacementsAll(res);
      setLoading(false);
    });
  };
  const toDelete = (id) => {
    toDeleteEmplacement(id).then((res) => {
      toGetDatas();
    });
  };

  const toEdit = (id, name) => {
    setId(id);
    setTitle(title);
    form.setFieldsValue({ name: name });
    setCreateModalVisible(true);
  };
  const toAdd = () => {
    setId(-1);
    setTitle("");
    form.setFieldsValue({ name: "" });
    setCreateModalVisible(true);
  };
  const exitCreateModal = () => {
    setId(-1);
    setTitle("");

    setCreateModalVisible(false);
  };

  return (
    <>
      {props.display && (
        <>
          <Row>
            <Col span="6">
              <Typography.Title level="2">Emplacements</Typography.Title>
            </Col>
            <Col span={"6"}>
              <SearchInput
                list={emplacementsAll}
                fields={["title"]}
                setData={(list) => setEmplacements(list)}
              />
            </Col>
            <Col span="12" style={{ textAlign: "right" }}>
              <Button
                type="primary"
                style={{ marginRight: "5px" }}
                onClick={(e) => setFilterModalVisible(true)}
              >
                Filtrer
              </Button>
              <Button
                icon={<PlusOutlined />}
                onClick={(e) => toAdd()}
                type="primary"
              >
                Ajouter un emplacement
              </Button>
            </Col>
          </Row>

          <Row
            style={{
              overflowY: "hidden",
            }}
          >
            <Col span="24">
              <DataTable
                noHeader
                striped
                fixedHeader
                persistTableHead
                noDataComponent={
                  <span>Pas de bon d'emplacement(s) trouvé(s)</span>
                }
                contextMessage={{
                  singular: "élément",
                  plural: "éléments",
                  message: "séléctionné(s)",
                }}
                columns={columns}
                data={emplacements}
                pagination
                paginationTotalRows={emplacements?.length}
                paginationRowsPerPageOptions={[10, 20, 50, 100]}
              />
              <Skeleton active loading={loading} />
            </Col>
          </Row>
        </>
      )}

      <Modal
        className="modal-small"
        title={"Filtrer"}
        visible={filterModalVisible}
        onOk={okFilter}
        onCancel={(e) => setFilterModalVisible(false)}
      >
        <Search ref={search} fields={fields} list={emplacements} />
      </Modal>

      <Modal
        title={
          id != -1
            ? "Modifier un emplacement"
            : "Ajouter un nouveau emplacement"
        }
        visible={createModalVisible}
        onOk={closeCreateModal}
        onCancel={exitCreateModal}
      >
        <Form name="basic" form={form}>
          <Form.Item
            label="Nom"
            name="name"
            rules={[{ required: true, message: "Champ obligatoire" }]}
          >
            <Input value={title} onChange={(e) => setTitle(e.target.value)} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Emplacement;
