import React from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  Layout,
  message,
  Row,
  Select,
  Steps,
  Typography,
} from "antd";
import { useState } from "react";
import { errorMsg } from "../../../Utils/Utils";
const { Meta } = Card;

const { Step } = Steps;

const steps = [
  {
    title: "First",
    content: "Vos informations",
  },
  {
    title: "Second",
    content: "Second-content",
  },
  {
    title: "Last",
    content: "Last-content",
  },
];
const QuoteEnligne = () => {
  const [current, setCurrent] = useState(0);
  const [articles, setArticles] = useState([]);
  const [year, setYear] = useState("");
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const addArticle = () => {
    setArticles([...articles, ""]);
  };

  const onChange = (el, i) => {
    const l__items = [...articles];
    l__items[i] = el;
    setArticles(l__items);
  };

  const removeArticle = (i) => {
    const l__items = [...articles];
    l__items.splice(i, 1);
    setArticles(l__items);
  };

  const nextDisabled = () => {
    if (current === 0) {
      form
        .validateFields()
        .then((values) => {
          return true;
        })
        .catch((errorInfo) => {
          errorMsg("Merci de remplir les champs obligatoires");
          return false;
        });
    }

    return true;
  };

  return (
    <div id="achat" className="auto--overflow">
      {/* <div className='smtv--quoteenligne-container' style={{paddingBottom: '1vh'}}>
            <Row className='smtv--quoteenligne-header' >
                <Col span={24}>
                <img src="/images/logo-original.png" alt="smtv-logo" />
                </Col>
            </Row>
            <Row style={{margin: '1vh', marginTop:'2vh'}} >
                <Col xl={2} lg={2} md={2} xs={1}/>
                <Col xl={20} lg={20} md={20} xs={22} style={{alignItems:'center',textAlign:'center'}}>
                    <p>
                        <Typography.Title level={1} style={{ fontWeight:'bold', color:'#fff'}}>
                            Trouvez Vos Pièces Auto de Qualité chez nous
                        </Typography.Title>
                    </p>
                    <p>
                        <Typography.Title level={4} style={{alignItems:'center',textAlign:'center', color:'#fff'}}>
                            Votre destination de confiance pour toutes vos pièces auto de qualité. <br/> 
                            Nous comprenons l'importance de maintenir votre véhicule en parfait état de fonctionnement,<br/> 
                            c'est pourquoi nous vous proposons une vaste sélection de pièces auto adaptées à vos besoins.
                        </Typography.Title>
                    </p>

                    <Row justify='space-between' style={{alignItems:'center',textAlign:'center', marginTop: '5vh'}}>
                        <Col xl={2} lg={2} md={2} xs={0}/>

                        <Col xl={6} lg={6} md={6} xs={8}>
                            <Card className="smtv--quoteenligne-card">
                                <img className='smtv--quoteenligne-card-img' alt="example" src="/images/large.svg" />
                                <Meta style={{marginTop: '2vh'}} title={<Typography.Title level={4} style={{color:'#3050C3'}}>Large Sélection</Typography.Title>}/>
                            </Card>
                        </Col>

                        <Col xl={6} lg={6} md={6} xs={8}>
                            <Card className="smtv--quoteenligne-card">
                                <img className='smtv--quoteenligne-card-img' alt="example" src="/images/qualite.svg" />
                                <Meta style={{marginTop: '2vh'}} title={<Typography.Title level={4} style={{color:'#3050C3'}}>Qualité Certifiée</Typography.Title>}/>
                            </Card>
                        </Col>

                        <Col xl={6} lg={6} md={6} xs={8}>
                            <Card className="smtv--quoteenligne-card">
                                <img className='smtv--quoteenligne-card-img' alt="example" src="/images/expert.svg" />
                                <Meta style={{marginTop: '2vh'}} title={<Typography.Title level={4} style={{color:'#3050C3'}}>Conseils d'Experts</Typography.Title>}/>
                            </Card>
                        </Col>
                        <Col xl={2} lg={2} md={2} xs={1}/>

                    </Row>
                    <Row justify='space-between' style={{alignItems:'center',textAlign:'center', marginTop: '5vh'}}>
                        <Col xl={2} lg={2} md={2} xs={1}/>

                        <Col xl={20} lg={20} md={20} xs={22}>
                            <p>
                                <Typography.Title level={2} style={{ fontWeight:'bold', color:'#fff'}}>
                                    Demandez votre devis gratuit !
                                </Typography.Title>
                            </p>
                            <p>
                                <Typography.Title level={4} style={{alignItems:'center',textAlign:'center', color:'#fff'}}>
                                    Simplifiez votre expérience d'achat en demandant un devis personnalisé dès maintenant.
                                </Typography.Title>
                            </p>

                        </Col>
                        <Col xl={2} lg={2} md={2} xs={1}/>

                    </Row>

                    <Button className='smtv--devisenligne-button'>Demander votre devis</Button>

                </Col>
            </Row>
        </div>
        <Row style={{marginTop:'4vh'}}>
            <Col xl={10} md={10} xs={24}>
                <Row >
                    <Col span="3">
                        <img src="/images/location.svg"/>
                    </Col>
                    <Col>
                        <Typography.Title level={4} style={{color:'#3050C3', paddingTop: '5px'}} >23 et 59, Av.Hédi Chaker -Tunis</Typography.Title>
                    </Col>
                </Row>
                <Row>
                                        <Col span="3">
                        <img src="/images/phone.svg"/>
                    </Col>
                    <Col span="6">
                        <Typography.Title level={4} style={{color:'#3050C3', paddingTop: '5px'}} >70 316 316</Typography.Title>
                    </Col>
                    <Col span="3">
                        <img src="/images/mobile.svg"/>
                    </Col>
                    <Col span="6">
                        <Typography.Title level={4} style={{color:'#3050C3', paddingTop: '5px'}} >99 316 316</Typography.Title>
                    </Col>

                </Row>
            </Col>
            <Col xl={4} md={4} xs={0}>

            </Col>
            <Col xl={10} md={10} xs={24}></Col>
        </Row> */}
      <Row>
        <Col xl={4} md={4} xs={2} className="smtv--quoteenligne-header1" />

        <Col xl={16} md={16} xs={20} className="smtv--quoteenligne-header-blue">
          <Row className="smtv--quoteenligne-header">
            <Col xl={4} md={4} xs={2} />

            <Col xl={16} md={16} xs={20}>
              <img src="/images/logo-original.png" alt="smtv-logo" />
            </Col>
            <Col xl={4} md={4} xs={2} className="smtv--quoteenligne-header1" />
          </Row>
        </Col>
        <Col xl={4} md={4} xs={2} />
      </Row>

      <div style={{ paddingBottom: "1vh", paddingTop: "-10%" }}>
        <Row>
          <Col xl={4} md={4} xs={2} />
          <Col
            xl={16}
            md={16}
            className="smtv--quoteenligne-container-back"
            xs={20}
            style={{
              alignItems: "center",
              textAlign: "center",
              paddingTop: "-10%",
            }}
          >
            <p style={{ marginTop: "3vh" }}>
              <Typography.Title
                level={1}
                style={{ fontWeight: "bold", color: "#fff" }}
              >
                Demandez votre devis gratuit !
              </Typography.Title>
            </p>
            <p>
              <Typography.Title
                level={4}
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                Ne perdez plus de temps à chasser les prix des pièces auto !
                <br />
                Notre fonctionnalité devis en ligne vous offre la réponse ,dans
                les brefs délais,
                <br />
                dont vous avez besoin pour entretenir votre voiture sans tracas.
              </Typography.Title>
            </p>
          </Col>
          <Col xl={4} md={4} xs={2} />
        </Row>
      </div>
    </div>
  );
};
export default QuoteEnligne;
