import { Card, Col, DatePicker, Descriptions, Row, Skeleton } from "antd";
import { useEffect, useState } from "react";

import cookie from "react-cookies";
import moment from "moment";
import Avances from "./Avances";
import Absences from "./Absences";
import Demandes from "./Demandes";
import Conges from "./Conges";
import Penalities from "./Penalities";
import Credits from "./Credits";
const FicheEmployee = ({ employeeReducer, id, user }) => {
  const [loading, setLoading] = useState(false);
  const [absences, setAbsences] = useState([]);
  const [avanceTotal, setAvanceTotal] = useState(0);
  const [penalities, setPenalities] = useState(0);
  const [mois, setMois] = useState(moment().format("MM"));

  const getDaysInMonth = (month, all) => {
    month--;
    const year = new Date().getFullYear();
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
      // Exclude weekends
      var tmpDate = new Date(date);
      var weekDay = tmpDate.getDay(); // week day
      var day = tmpDate.getDate(); // day
      if (weekDay % 7) {
        // exclude 0=Sunday and 6=Saturday
        if (all) {
          days.push(day);
        } else if (day < new Date().getDate()) {
          days.push(day);
        }
      }
      date.setDate(date.getDate() + 1);
    }

    return days;
  };

  const calculateSolde = () => {
    const days = getDaysInMonth(mois, true).length;
    const taux_h = user?.salary / days / 8;

    return (
      user.solde -
      taux_h * 8 * absences.filter((el) => el.status === 1).length -
      taux_h * penalities -
      avanceTotal
    );
  };
  return (
    <>
      {user ? (
        <>
          <Card
            className="smtv--card-background"
            type="inner"
            bordered
            title={
              <h5 className="text-white">{user.prenom + " " + user.nom}</h5>
            }
          >
            <Descriptions bordered column={5}>
              <Descriptions.Item label="CIN">{user.cin}</Descriptions.Item>
              <Descriptions.Item label="Téléphone">
                {user.phone}
              </Descriptions.Item>
              {(cookie.load("id") === "8" ||
                cookie.load("id") == "18" ||
                cookie.load("id") == id) && (
                <Descriptions.Item label="Salaire">
                  {user.salary}
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Role">{user.role}</Descriptions.Item>
              <Descriptions.Item label="Entité">
                {user.entity}
              </Descriptions.Item>
              <Descriptions.Item label="Absences (j)">
                {absences.filter((el) => el.status == 1).length}
              </Descriptions.Item>
              {(cookie.load("id") === "8" || cookie.load("id") == "18") && (
                <Descriptions.Item label="Penalités (h)">
                  {penalities}
                </Descriptions.Item>
              )}
              {(cookie.load("id") === "8" ||
                cookie.load("id") == "18" ||
                cookie.load("id") == id) && (
                <Descriptions.Item label="Solde Disponible">
                  {user.solde.toFixed(3)}
                </Descriptions.Item>
              )}
            </Descriptions>
          </Card>
          <Card className="smtv--card-background">
            <Row style={{ marginTop: "10px" }}>
              <Col span="4">
                <b>Choisir le mois</b>
              </Col>
              <Col span="20">
                <DatePicker.MonthPicker
                  onChange={(e, date) => {
                    setMois(moment(e).format("MM"));
                  }}
                  defaultValue={moment()}
                ></DatePicker.MonthPicker>
              </Col>
            </Row>
          </Card>
          <Row gutter={24} style={{ marginTop: "10px" }}>
            <Col span="8">
              <Absences
                employeeReducer={employeeReducer}
                setAbsences={(list) => {
                  setAbsences(list);
                }}
                id={id}
                mois={mois}
              />
            </Col>
            {(cookie.load("id") === "8" ||
              cookie.load("id") == "18" ||
              cookie.load("id") == "1") && (
              <Col span="8">
                <Avances
                  employeeReducer={employeeReducer}
                  mois={mois}
                  id={id}
                  setAvanceTotal={(nb) => setAvanceTotal(nb)}
                />
              </Col>
            )}
            {(cookie.load("id") === "8" ||
              cookie.load("id") == "18" ||
              cookie.load("id") == "1") && (
              <Col span="8">
                <Penalities
                  employeeReducer={employeeReducer}
                  mois={mois}
                  id={id}
                  setPenalities={(nb) => setPenalities(nb)}
                />
              </Col>
            )}
            <Col span="8">
              <Demandes
                mois={mois}
                id={id}
                employeeReducer={employeeReducer}
                solde={calculateSolde()}
                salary={user?.salary}
                employee={user}
              />
            </Col>
            <Col span="8">
              <Conges mois={mois} employeeReducer={employeeReducer} id={id} />
            </Col>
            <Col span="8">
              <Credits employeeReducer={employeeReducer} id={id} />
            </Col>
          </Row>
        </>
      ) : (
        <Skeleton active loading={loading} />
      )}
    </>
  );
};

export default FicheEmployee;
