import { useEffect, useState } from "react";
import { Col, Row, Tag, Typography } from "antd";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { toGetQuotesBLEntities, toGetTotalEntities } from "../APIs";
import { colors, CustomTooltip, getUsername, toGetTotal } from "./utils";

const QuoteBLStats = ({ selected, users }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (selected?.length > 0) getDataEntities(2, selected);
  }, [selected]);

  const getDataEntities = (date, usersIds) => {
    toGetQuotesBLEntities(date, usersIds, false).then((res) => {
      setData(res);
    });
  };

  return (
    <div style={{ marginTop: "2vh" }}>
      {data.length > 0 && (
        <>
          <Row className="auto--custom-header">
            <Col span="24">
              <Typography.Title level={3}>
                Devis Convertis en BL
              </Typography.Title>

              <div style={{ height: 600, marginTop: "4vh" }}>
                <ResponsiveContainer width="100%" height="100%">
                  <ComposedChart
                    data={data}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 50,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

                    <XAxis dataKey="date" />
                    <YAxis
                      type="number"
                      dataKey={(v) => parseInt(v.valeur)}
                      domain={[0, "dataMax + 100"]}
                    />

                    <Tooltip content={<CustomTooltip />} />
                    {selected.length > 0 &&
                      selected.map((el, i) => (
                        <Bar
                          type="monotone"
                          dataKey={getUsername(el, users)}
                          stroke={colors[i]}
                          fill={colors[i]}
                          stackId={i}
                        ></Bar>
                      ))}
                    {selected.length > 0 &&
                      selected.map((el, i) => (
                        <Line
                          type="monotone"
                          dataKey={getUsername(el, users)}
                          stroke={colors[i]}
                          fill={colors[i]}
                          stackId={i}
                        ></Line>
                      ))}
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            {selected.length > 0 &&
              selected.map((el, i) => (
                <Tag color={colors[i]}>
                  {getUsername(el, users) + ": " + toGetTotal(el, data, users)}
                </Tag>
              ))}
          </Row>
        </>
      )}
    </div>
  );
};
export default QuoteBLStats;
