import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tag,
} from "antd";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { toAddDelivery, toGetAdresses, toGetAllDelivery } from "../Utils/Api";
import moment from "moment";
import { toGetUsers } from "../Pages/Compte/Api";
import { errorMsg } from "../Utils/Utils";
const DeliveryComponent = ({ display, setDisplay }) => {
  const [list, setList] = useState([]);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [users, setUsers] = useState([]);
  const [modalVisible, setIsModalVisible] = useState(false);
  const [adresses, setAdresses] = useState([]);
  const [form] = Form.useForm();
  useEffect(() => {
    getData(date);
    toGetUsers().then((users) => {
      setUsers(users);
    });
    toGetAdresses().then((res) => setAdresses(res));
  }, [display]);
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "1rem",
      },
    },
  };

  const getData = async (date) => {
    toGetAllDelivery(date).then((res) => setList(res));
  };

  const columns = [
    {
      name: "Date",
      selector: "createdDate",
      sortable: true,
      grow: 1.5,
    },
    {
      name: "Crée par",
      selector: "createdBy",
      sortable: true,
      grow: 1.5,
    },
    {
      name: "Chauffeur",
      selector: "driver",
      sortable: true,
      grow: 1.5,
    },
    {
      name: "Adresse",
      selector: "adress",
      sortable: true,
      grow: 1.5,
    },
    {
      name: "Description",
      selector: "description",
      sortable: true,
      grow: 3.5,
    },
    {
      name: "",
      selector: "status",
      sortable: true,

      format: (item) => (
        <>
          <Tag
            color={
              item.status == 0
                ? "yellow"
                : item.status === 3
                ? "blue"
                : item.status == 2
                ? "red"
                : "green"
            }
          >
            {item.status === 0
              ? "En attente"
              : item.status === 3
              ? "Accepté"
              : item.status === 2
              ? "Refusé"
              : "Terminé"}
          </Tag>
        </>
      ),
    },
  ];

  const toSaveDeliveryDriver = (force) => {
    form.validateFields().then((values) => {
      toAddDelivery({
        ...values,
        driverId: users.filter((el) => el.username == values.driver)[0].id,
        force,
      }).then((res) => {
        if (res.exist) {
          Modal.warning({
            okButtonProps: { style: { display: "none" } },

            content: (
              <div>
                <p>Il y'a une autre livraison sur le meme secteur</p>
                <p>Voulez-vous vraiement ajouter une nouvelle livraison?</p>
                <div style={{ textAlign: "center", marginTop: "2vh" }}>
                  <Button
                    type="primary"
                    onClick={() => {
                      Modal.destroyAll();

                      toSaveDeliveryDriver(true);
                    }}
                  >
                    Oui
                  </Button>
                  <Button
                    style={{ marginLeft: "2vh" }}
                    danger
                    onClick={() => {
                      Modal.destroyAll();
                    }}
                  >
                    Non
                  </Button>
                </div>
              </div>
            ),
          });
        } else {
          getData(date);
          form.resetFields();
          setIsModalVisible(false);
        }
      });
    });
  };

  useEffect(() => {
    getData(date);
  }, [date]);
  return (
    <Modal
      className="smtv-modal modal-large"
      visible={display}
      onCancel={() => setDisplay(false)}
      onOk={() => setDisplay(false)}
    >
      <Row>
        <Col span={8}>
          <DatePicker
            onChange={(e, v) => setDate(moment(v).format("YYYY-MM-DD"))}
          ></DatePicker>
        </Col>
        <Col span={15}>
          <Button
            style={{ float: "right" }}
            type="primary"
            onClick={() => setIsModalVisible(true)}
          >
            Nouvelle livraison
          </Button>
        </Col>
      </Row>

      <DataTable
        customStyles={customStyles}
        noHeader
        striped
        fixedHeader
        persistTableHead
        noDataComponent={<span>Pas de livraison(s) trouvée(s)</span>}
        contextMessage={{
          singular: "élément",
          plural: "éléments",
          message: "séléctionné(s)",
        }}
        columns={columns}
        data={list}
        pagination
        paginationTotalRows={list.length}
        paginationRowsPerPageOptions={[10, 20, 50, 100]}
      />
      <Modal
        title="Ajouter une livraison"
        visible={modalVisible}
        onOk={() => toSaveDeliveryDriver(false)}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form layout="vertical" form={form}>
          <Form.Item label="Adresse" name="adress" rules={[{ required: true }]}>
            <Select showSearch>
              {adresses.map((el) => (
                <Select.Option key={el.name} value={el.name}>
                  {el.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Chauffeur"
            name="driver"
            rules={[{ required: true }]}
          >
            <Select showSearch>
              {users
                .filter((x) => x.role === "chauffeur" && x.active)
                .map((user) => (
                  <Select.Option value={user.username}>
                    {user.username}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea></Input.TextArea>
          </Form.Item>
        </Form>
      </Modal>
    </Modal>
  );
};

export default DeliveryComponent;
