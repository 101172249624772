import {
  Typography,
  Skeleton,
  Row,
  Col,
  Form,
  DatePicker,
  Card,
  Statistic,
} from "antd";
import { useEffect, useState } from "react";
import BonLivraisonModal from "../Vente/BL/BonLivraisonModal";
import { toGetTotalHT, toGetListDelivery } from "./APIs";
import moment from "moment";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { toGetUsers } from "../Compte/Api";
const Objectif = ({ AppStore, ...props }) => {
  const [data, setData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [blToInspect, setBlToInspect] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    AppStore.setFirst(2);
    AppStore.setSecond(12);
    toGetUsers().then((users) => {
      setUsers(users.filter((x) => x.role === "chauffeur" && x.active));
      getData(
        new Date(),
        users.filter((x) => x.role === "chauffeur" && x.active)
      );
    });
  }, []);

  const toConvertDateTime = (date) => {
    const offset = getOffset(date);
    return moment(date).subtract(0, "h").format("LL H:mm");
  };

  const getOffset = (date) => {
    const zone = moment(date).zone();
    const offset = zone + 60;
    return offset;
  };

  const toVerifyDate = (cnxDate) => {
    const offset = getOffset(date) + 1;

    const cnx = moment(cnxDate);
    const selectedDate = moment(cnx.format("YYYY-MM-D")).set({
      hour: 9,
      minute: 30,
      second: 59,
    });

    if (cnx.isBefore(selectedDate)) {
      return true;
    }
    return false;
  };

  const toGetCoefficient = (cnxDate) => {
    const cnx = moment(cnxDate);
    var selectedDate = moment(cnx.format("YYYY-MM-D")).set({
      hour: 8,
      minute: 0,
      second: 59,
    });

    if (cnx.isBefore(selectedDate)) {
      return 30;
    }

    selectedDate = moment(cnx.format("YYYY-MM-D")).set({
      hour: 8,
      minute: 15,
      second: 59,
    });

    if (cnx.isBefore(selectedDate)) {
      return 20;
    }

    selectedDate = moment(cnx.format("YYYY-MM-D")).set({
      hour: 8,
      minute: 30,
      second: 59,
    });

    if (cnx.isBefore(selectedDate)) {
      return 10;
    }
    return 0;
    // selectedDate = moment(cnx.format("YYYY-MM-D")).set({
    //   hour: 9,
    //   minute: 45,
    //   second: 59,
    // });

    // if (cnx.isBefore(selectedDate)) {
    //   return -50;
    // }

    // return -100;
  };

  const getData = (date, l__users) => {
    setLoading(true);
    setData([]);
    setDate(date);
    toGetTotalHT(date).then((res) => {
      setData(res);
      setLoading(false);
    });

    toGetListDelivery(moment(date).format("YYYY-MM-DD")).then(
      ({ list, caisses }) => {
        const res = [...list];
        const unique = [...new Set(res.map((item) => item.driver))];

        var drivers = [];
        l__users.forEach((el) => {
          drivers.push({ driver: el.username, nb: 0, caisses: 0 });
        });
        unique.forEach((el) => {
          const count = res.filter((obj) => obj.driver == el).length;
          if (drivers.findIndex((x) => x.driver == el) != -1) {
            drivers[drivers.findIndex((x) => x.driver == el)].nb = count;
          }
        });
        drivers.forEach((element, i) => {
          const count = caisses.filter((obj) => element.driver === obj.recived)
            .length;
          if (drivers.findIndex((x) => x.driver === element.driver) != -1) {
            drivers[
              drivers.findIndex((x) => x.driver === element.driver)
            ].caisses = count;
          }
        });
        setDrivers(drivers);
      }
    );
  };

  const getBonus = (element) => {
    var total = element.bonus;
    var bonus = 0;
    if (total > 8500) {
      bonus = ((total - 8500) * 1) / 100 + 85;
    } else if (total > 5500) {
      bonus = ((total - 5500) * 1) / 100 + 40;
    } else if (total > 3500) {
      bonus = ((total - 3500) * 1) / 100 + 25;
    } else if (total > 2000) {
      bonus = ((total - 2000) * 1) / 100 + 10;
    }
    var l__bonus = bonus > 145 ? 145 : bonus;
    var coefficient = toGetCoefficient(element.date);

    l__bonus = (l__bonus * (100 + coefficient)) / 100;

    //var retard = toGetRetard(element.retard);
    //l__bonus = (l__bonus * (100 + retard)) / 100;

    return l__bonus.toFixed(2);
  };

  const getNewBonus = (element) => {
    var total = element.bonus;

    var bonus = 0;
    // if (total > 9775) {
    //   bonus = ((total - 9775) * 1) / 100 + 60;
    // } else if (total > 6325) {
    //   bonus = ((total - 6325) * 1) / 100 + 40;
    // } else if (total > 4025) {
    //   bonus = ((total - 4025) * 1) / 100 + 23;
    // } else

    if (total > 2300) {
      bonus = ((total - 2300) * 1) / 100 + 10;
    }
    var l__bonus = bonus > 145 ? 145 : bonus;

    var coefficient = toGetCoefficient(element.date);
    l__bonus = (l__bonus * (100 + coefficient)) / 100;
    var retard = toGetRetard(element.retard);
    l__bonus = (l__bonus * (100 + retard)) / 100;

    return l__bonus.toFixed(2);
  };

  const toGetRetard = (retard) => {
    if (retard > 11) return -50;
    if (retard > 8) return -40;
    if (retard > 5) return -30;
    if (retard > 2) return -20;
    return 0;
  };

  return (
    <>
      <Row className="auto--custom-header">
        <Col span="8">
          <Typography.Title level={3}>OBJECTIFS</Typography.Title>
        </Col>
        <Col span="4">
          <Form.Item label="Date">
            <DatePicker
              style={{ width: "100%" }}
              onChange={(e, date) => {
                getData(date, users);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ marginTop: "1vh" }}>
        <Col span="3"></Col>
        <Col span="18">
          <div className="table-wrapper auto--overflow">
            <table className="ant-table" style={{ width: "100%" }}>
              <thead className="ant-table-thead">
                <tr>
                  <th className="ant-table-cell">Utilisateur </th>
                  <th className="ant-table-cell">Total Vente HT</th>
                  <th className="ant-table-cell">Date dernière connexion</th>
                  <th className="ant-table-cell">BL(s) Non payé</th>
                  <th className="ant-table-cell">Bonus </th>
                  <th className="ant-table-cell">
                    Bonus Bloqué (une fois le BL devient payé vous pouvez le
                    récupérer)
                  </th>
                  <th className="ant-table-cell"></th>
                </tr>
              </thead>
              <Skeleton active loading={loading} />
              <tbody className="ant-table-tbody">
                {data.map((element) => (
                  <tr
                    className={
                      element.bonus > 3500 && element.bls.length > 0
                        ? "auto--objectif-pass"
                        : element.bonus > 3500 &&
                          element.bls.length == 0 &&
                          "auto--objectif-done"
                    }
                  >
                    <td className="ant-table-cell">{element.username}</td>
                    <td className="ant-table-cell">{element.bonus}</td>
                    <td className="ant-table-cell">
                      {null != element.date
                        ? toConvertDateTime(element.date)
                        : "non connecté"}
                    </td>
                    <td className="ant-table-cell">
                      {element.bls.map((e) => (
                        <Typography.Link onClick={() => setBlToInspect(e.id)}>
                          {e.ref + "  **  "}
                        </Typography.Link>
                      ))}
                    </td>
                    <td className="ant-table-cell">
                      {toVerifyDate(element.date)
                        ? element.blocked
                          ? "Le bonus a été bloqué"
                          : getBonus(element)
                        : (getBonus(element) * 0.8).toFixed(2)}
                    </td>
                    <td className="ant-table-cell">
                      {
                        <ul>
                          {element.blockedDays.map((el) => (
                            <>
                              {toVerifyDate(el.data.date) &&
                                getBonus(el.data) > 0 &&
                                (el.blocked ? (
                                  <li className="auto--color-danger">
                                    Le <u>{el.date}</u> votre bonus de{" "}
                                    <u>{getBonus(el.data)}</u> a été suspendu
                                  </li>
                                ) : (
                                  <li className="auto--color-success">
                                    Félicitations! Votre bonus de{" "}
                                    <u>{getBonus(el.data)}</u> (le{" "}
                                    <u>{el.date}</u> ) a été débloqué
                                  </li>
                                ))}
                            </>
                          ))}
                        </ul>
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
        <Col span="3"></Col>
      </Row>
      {data.length > 0 && (
        <Row>
          <Col span="24">
            <div style={{ height: 300 }}>
              <ResponsiveContainer>
                <BarChart
                  height={400}
                  data={data}
                  style={{ marginTop: "50px", marginBottom: "2vh" }}
                >
                  <XAxis dataKey="username" fontFamily="Proxima Nova"></XAxis>
                  <Tooltip cursor={{ fill: "transparent" }} />

                  <Bar
                    radius={[2, 2, 0, 0]}
                    dataKey="total"
                    barSize={200}
                    stackId="a"
                    fill="#846eb1"
                    fontFamily="Proxima Nova"
                  >
                    {data.map((entry, index) => (
                      <Cell></Cell>
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
        </Row>
      )}
      <div style={{ margin: "20px", textAlign: "center" }}>
        <b style={{ fontSize: "20px" }}>Chauffeurs</b>
      </div>
      <Row style={{ marginTop: "20px" }} justify="center">
        {drivers
          .sort((a, b) => b.nb - a.nb)
          .map((el) => (
            <Col span={4}>
              <Card bordered type="inner" title={el.driver}>
                <Statistic
                  title={"Livraison(s):"}
                  value={el.nb}
                  valueStyle={{ color: "darkorange" }}
                />

                <Statistic
                  title={"Réglement(s) apporté(s):"}
                  valueStyle={{ color: "darkorange" }}
                  value={el.caisses}
                />
                <Statistic
                  title={"Total:"}
                  value={el.nb + el.caisses * 2}
                  valueStyle={{ color: "#1b9e4d" }}
                />
              </Card>
            </Col>
          ))}
      </Row>
      <BonLivraisonModal
        entete={true}
        id={blToInspect}
        onClose={() => setBlToInspect(null)}
      />
    </>
  );
};

export default Objectif;
