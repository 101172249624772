import { Form, Input, Modal, Button, Select } from "antd";
import { successMsg } from "../../Utils/Utils";
import { useEffect, useState } from "react";
import { toGetUsers } from "../Compte/Api";
import { addDilevery } from "./APIs";

const Delivery = ({ ids, visible, type, setDeliveryModal }) => {
  const [users, setUsers] = useState([]);
  const [form] = Form.useForm();
  useEffect(() => {
    toGetUsers().then((users) => {
      setUsers(users);
    });
  }, [ids]);

  const saveDelivery = () => {
    const driverId = users.filter(
      (el) => el.username == form.getFieldValue("driver")
    )[0].id;
    const l__ids = [];
    ids.forEach((element) => {
      l__ids.push(element.id);
    });

    addDilevery(l__ids, type, driverId).then((res) => {
      successMsg("Livraison(s) enregistrée(s) avec succées");
      setDeliveryModal(null);
    });
  };

  return (
    <Modal
      visible={visible}
      title="Livraison"
      onCancel={() => setDeliveryModal(null)}
      footer={[
        <Button type="primary" onClick={() => saveDelivery()}>
          Enregistrer
        </Button>,
      ]}
    >
      <Form form={form}>
        <Form.Item
          rules={[{ required: true, message: "Champ obligatoire" }]}
          label="Chauffeur"
          name="driver"
        >
          <Select showSearch>
            {users
              .filter((x) => x.role === "chauffeur" && x.active)
              .map((user) => (
                <Select.Option value={user.username}>
                  {user.username}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Delivery;
