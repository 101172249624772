import React, { useState } from "react";

const Rating = ({ max = 5, value, onChange, size = 24, color = "#FFD700" }) => {
  const [hover, setHover] = useState(null);
  return (
    <div style={{ display: "flex", gap: 4, cursor: "pointer" }}>
      {[...Array(max)].map((_, index) => {
        const ratingValue = index + 1;
        return (
          <span
            key={index}
            onMouseEnter={() => setHover(ratingValue)}
            onMouseLeave={() => setHover(null)}
            onClick={() => onChange?.(ratingValue)}
            style={{
              fontSize: size,
              color: (hover || value) >= ratingValue ? color : "#ccc",
            }}
          >
            ★
          </span>
        );
      })}
    </div>
  );
};

export default Rating;
