import { Button, Row, Col, Skeleton, Tabs, Descriptions } from "antd";
import Modal from "antd/lib/modal/Modal";
import { deleteAccompte, toGetQuote, toPrintQuote } from "../APIs";
import { DeleteOutlined, PrinterOutlined } from "@ant-design/icons";
import { toConvertDate } from "../../../Utils/Utils";
import { useEffect, useState } from "react";
const { TabPane } = Tabs;

const QuoteModal = ({ quote, onClose, entete }) => {
  const [quoteItems, setQuoteItems] = useState(null);
  const [loading, setLoading] = useState(false);
  const [avances, setAvances] = useState([]);
  useEffect(() => {
    if (null != quote) {
      setLoading(true);
      toGetQuote(
        quote.entity && quote.entity == "devisF" ? quote.quoteId : quote.id
      ).then((res) => {
        setQuoteItems(res.quote.quote__items);
        setLoading(false);
        setAvances(res.avances);
      });
    }
  }, [quote]);
  const printQuote = (e) => {
    e.preventDefault();
    var titre1 = true;
    if (e.type == "contextmenu") {
      titre1 = false;
    }

    toPrintQuote(quote.id, titre1, entete);
  };

  const handleDeleteAdvance = (id) => {
    deleteAccompte(id).then((res) => {
      toGetQuote(
        quote.entity && quote.entity == "devisF" ? quote.quoteId : quote.id
      ).then((res) => {
        setQuoteItems(res.quote.quote__items);
        setLoading(false);
        setAvances(res.avances);
      });
    });
  };

  return (
    <Modal
      className="smtv-modal modal-large"
      visible={quote != null}
      onCancel={onClose}
      title="Details devis"
      footer={[
        <Button key="close" onClick={onClose}>
          Fermer
        </Button>,
        <Button
          key="submit"
          type="primary"
          icon={<PrinterOutlined />}
          onClick={printQuote}
          onContextMenu={(e) => printQuote(e)}
        >
          Imprimer
        </Button>,
      ]}
    >
      {quote && (
        <>
          <h1 style={{ textAlign: "center" }}>Devis</h1>
          <Row>
            <Col className="info-box" md={11}>
              <p>
                <b>Ref</b> : {quote.ref}
              </p>
              <p>
                <b>Date</b> : {toConvertDate(quote.createdDate)}
              </p>
              <p>
                <b>Chargé client</b> : {quote.createdBy && quote.createdBy}
              </p>
            </Col>
            <Col className="info-box" md={11}>
              <h1>Client</h1>
              <p>
                <b>Nom</b> :{" "}
                {quote.displayableName
                  ? quote.displayableName
                  : quote.clientName && quote.clientName}
              </p>
              <p></p>
            </Col>
          </Row>
          <Tabs defaultActiveKey="1" centered>
            <TabPane tab="Articles" key="1">
              <table className="ant-table" style={{ width: "100%" }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell" style={{ width: "8%" }}>
                      Ref
                    </th>
                    <th className="ant-table-cell">Désignation</th>
                    <th className="ant-table-cell" style={{ width: "8%" }}>
                      Prix Unitaire HT
                    </th>
                    <th className="ant-table-cell" style={{ width: "8%" }}>
                      Prix Unitaire TTC
                    </th>
                    <th className="ant-table-cell" style={{ width: "8%" }}>
                      Quantité
                    </th>
                    <th className="ant-table-cell" style={{ width: "8%" }}>
                      Remise
                    </th>
                    <th className="ant-table-cell" style={{ width: "5%" }}>
                      TVA
                    </th>
                    <th className="ant-table-cell" style={{ width: "8%" }}>
                      Prix Total
                    </th>
                  </tr>
                </thead>
                <tbody className="ant-table-tbody">
                  {quoteItems &&
                    quoteItems.map((item) =>
                      loading ? (
                        <Skeleton active loading={true} />
                      ) : (
                        <tr>
                          <td
                            className="ant-table-cell"
                            style={{ width: "8%" }}
                          >
                            {item.article.s__reference}
                          </td>
                          <td className="ant-table-cell">
                            {item.article.s__secondTitle}
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ width: "12%" }}
                          >
                            {item.d__unitPriceHT.toFixed(3)}
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ width: "12%" }}
                          >
                            {item.d__unitPriceTTC.toFixed(3)}
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ width: "10%" }}
                          >
                            {item.d__quantity}
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ width: "10%" }}
                          >
                            {item.d__discount} %
                          </td>

                          <td
                            className="ant-table-cell"
                            style={{ width: "5%" }}
                          >
                            {item.d__tva} %
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ float: "right" }}
                          >
                            {item.d__total.toFixed(3)} Dt
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </TabPane>
            <TabPane tab="Avances" key="2">
              {avances.map((el) => (
                <>
                  <Descriptions bordered size={4}>
                    <Descriptions.Item label="Date">
                      {el.date}
                    </Descriptions.Item>
                    <Descriptions.Item label="Montant">
                      {el.value}
                    </Descriptions.Item>
                    <Descriptions.Item label="Crée par">
                      {el.createdBy}
                    </Descriptions.Item>
                    <Descriptions.Item label="">
                      <DeleteOutlined
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDeleteAdvance(el.id)}
                      />
                    </Descriptions.Item>
                  </Descriptions>
                </>
              ))}
            </TabPane>
          </Tabs>

          <div style={{ textAlign: "right", marginTop: 20 }}>
            <div
              style={{
                display: "inline-block",
                textAlign: "right",
              }}
            >
              <p>
                <b>Tota HT</b> : {(quote.total - quote.tva).toFixed(3)} DT
              </p>
              <p>
                <b>TVA</b> : {quote.tva.toFixed(3)} DT
              </p>
              <p>
                <b>Remise Totale</b> : {quote.discount} dt
              </p>
              <p>
                <b>Net à Payer</b> : {quote.total.toFixed(3)} DT
              </p>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default QuoteModal;
