import { Form, Input, Modal, Button } from "antd";
import { successMsg } from "../../Utils/Utils";
import {
  sendEmailBL,
  sendEmailQuote,
  sendEmailFacture,
  sendEmailPo,
} from "./APIs";
import { useEffect } from "react";

const Email = ({ id, type, visible, setEmailModal, to }) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (type == "quoteenligne") {
      form.setFieldsValue({
        to: to,
        // eslint-disable-next-line no-multi-str
        msg:
          "Toute l'équipe SMTV vous remercie pour votre confiance ! \
         \nNous tenons à vous exprimer toute notre gratitude pour avoir choisi SMTV pour répondre à vos besoins. \
         \nNous sommes ravis que vous ayez choisi notre entreprise et nous sommes impatients de vous offrir une expérience exceptionnelle. \
         \n\nVotre demande a été pris en charge et vous trouverez  ci-joint le devis correspondant.\n \n \
         Nous sommes à votre disposition pour tout complément d'informations.",
      });
    }
  }, [id]);

  const envoyerEmail = (titre1) => {
    var ids = [];
    id.forEach((element) => {
      ids.push(element.id);
    });
    form.validateFields().then((values) => {
      if (type == "bl") {
        sendEmailBL(
          ids,
          type,
          form.getFieldValue("to"),
          form.getFieldValue("msg")
        );
      } else if (type == "quote" || type == "quoteenligne") {
        sendEmailQuote(
          ids,
          type,
          form.getFieldValue("to"),
          form.getFieldValue("msg"),
          titre1
        );
      } else if (type == "facture") {
        sendEmailFacture(
          ids,
          type,
          form.getFieldValue("to"),
          form.getFieldValue("msg")
        );
      } else if (type == "po") {
        sendEmailPo(
          id,
          type,
          form.getFieldValue("to"),
          form.getFieldValue("msg")
        );
      }

      successMsg("Email envoyé avec succé!!");
      setEmailModal(false);
    });
  };
  return (
    <Modal
      visible={visible}
      title="Email"
      onCancel={() => setEmailModal(false)}
      footer={[
        <Button
          type="primary"
          onContextMenu={(e) => envoyerEmail(false)}
          onClick={() => envoyerEmail(true)}
        >
          Envoyer
        </Button>,
      ]}
    >
      <Form form={form}>
        <Form.Item
          rules={[{ required: true, message: "Champ obligatoire" }]}
          label="A"
          name="to"
        >
          <Input></Input>
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Champ obligatoire" }]}
          label="Message"
          name="msg"
        >
          <Input.TextArea rows={20} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Email;
