import {
  Button,
  Modal,
  Skeleton,
  Popconfirm,
  Row,
  Col,
  Typography,
  Checkbox,
  Tooltip,
  Input,
  Form,
  Select,
  DatePicker,
  Descriptions,
  InputNumber,
} from "antd";
import { useEffect, useState } from "react";
import ls from "localstorage-ttl";
import {
  toGetBonsReception,
  toPrintBR,
  toPrintBRWithEntete,
  toDeleteBR,
  toPrintBRs,
  toGetTotalBrs,
  toGetFactureReception,
  toGetProviderList,
  toUpdateDiscount,
} from "../APIs";
import {
  PlusOutlined,
  PrinterOutlined,
  EditOutlined,
  InfoCircleTwoTone,
  DeleteOutlined,
  CloseOutlined,
  PercentageOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import BonReceptionModal from "./BonReceptionModal";
import {
  customFilter,
  numberFormatCurrency,
  toConvertDate,
} from "../../../Utils/Utils";
import { connect } from "react-redux";
import { setItemsState } from "../../../Actions/ItemsSelectionAtions";
import FactureReceptionModal from "../Factures/FactureReceptionModal";
import Search from "../../../Utils/Search";
import BonReceptionToFacture from "./BonReceptionToFacture";
import DataTable from "react-data-table-component";

import Loading from "react-loading-bar";
import "react-loading-bar/dist/index.css";
import { toGetUsers } from "../../Compte/Api";
import { GrDeliver } from "react-icons/gr";
import cookie from "react-cookies";
import Delivery from "../../Vente/Delivery";
import { validateDelivery } from "../../Vente/APIs";
import TotalBr from "./TotalBr";

const { Option } = Select;
const fields = [
  { name: "Référence", field: "ref", type: "varchar" },
  { name: "Total", field: "total", type: "number" },
  { name: "Fournisseur", field: "providerName", type: "varchar" },
  { name: "Remise", field: "discount", type: "number" },
  { name: "Nombre de produits", field: "numberArticle", type: "number" },
  { name: "Facture", field: "facture", type: "boolean" },
  { name: "Payé", field: "paied", type: "boolean" },
  { name: "Date de création", field: "createdDate", type: "date" },
];

const ListeBonReception = ({ AppStore, ...props }) => {
  const [selectedMethode, setSelectedMethode] = useState(2);
  const [selectedProvider, setSelectedProvider] = useState("0");
  const [selectedDate, setSelectedDate] = useState(0);
  const [displayDate, setDisplayDate] = useState(false);
  const [displayDateRange, setDisplayDateRange] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("0");
  const [selectedDateValue, setSelectedDateValue] = useState("0");
  const [providers, setProviders] = useState([]);
  const [deliveryModal, setDeliveryModal] = useState(null);

  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(true);
  const [orders, setBonsLivraison] = useState([]);
  const [ordersAll, setBonsLivraisonAll] = useState([]);
  const [brToInspect, setBrToInspect] = useState(null);
  const [brsForFacture, setbrsForFacture] = useState([]);
  const [factureToInspect, setFactureToInspect] = useState(null);
  const [totalTVA, setTotalTVA] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalAll, setTotalAll] = useState(0);
  const [checkedItems, setCheckedItems] = useState([]);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [createFactureModalVisible, setCreateFactureModalVisible] = useState(
    false
  );
  const [totalUnpaied, setTotalUnpaied] = useState(0);
  const [totalPaied, setTotalPaied] = useState(0);
  const [remiseVisible, setRemiseModal] = useState(false);
  const [remiseValue, setRemise] = useState(false);

  const confirmDelete = (id) => {
    toDeleteBR(id).then(() => {
      let newBRs = orders.filter((c) => c.id !== id);
      setBonsLivraison(newBRs);
      setBonsLivraisonAll(newBRs);
    });
  };
  const toPrintMultipleBrs = () => {
    var ids = [];
    brsForFacture.forEach((element) => {
      ids.push(element.id);
    });
    toPrintBRs(ids);
  };

  const toCheck = (br) => {
    var length = checkedItems.filter((element) => element.id == br.id).length;
    if (length === 0) {
      setCheckedItems([...checkedItems, br]);
    } else {
      let items = checkedItems.filter((element) => element.id !== br.id);
      setCheckedItems(items);
    }
  };

  const updateBR = (order) => {
    const win = window.open(
      "/achat/reception/modifier/" + order.id,

      "_blank"
    );
    if (win != null) {
      win.focus();
    }
  };

  useEffect(() => {
    toGetUsers().then((users) => {
      setUsers(users);
    });

    if (null != ls.get("providers")) {
      setProviders(ls.get("providers"));
      setLoading(false);
    }
    toGetProviderList().then((res) => {
      setProviders(res);
      setLoading(false);
    });

    AppStore.setFirst(0);
    AppStore.setSecond(2);

    if (null != ls.get("brs")) {
      setBonsLivraison(ls.get("brs"));
      setLoading(false);
    }
    fetchBRS(0, {
      paied: selectedMethode,
      provider: selectedProvider,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  }, []);

  const actualise = () => {
    setShow(true);
    fetchBRS(0, {
      paied: selectedMethode,
      provider: selectedProvider,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const onChangeCredit = (option) => {
    setSelectedMethode(option);
    fetchBRS(0, {
      paied: option,
      provider: selectedProvider,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const onChangeFournisseur = (option) => {
    var id = 0;

    if (option == "Tout") {
      id = 0;
    } else {
      id = providers.filter((el) => el.s__nom == option)[0].id;
    }
    setSelectedProvider(id);
    fetchBRS(0, {
      paied: selectedMethode,
      provider: id,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const onChangeUsers = (option) => {
    var id = "0";
    if (option != "0") {
      id = users.filter((el) => el.username == option)[0].id;
    }
    setSelectedUser(id);
    fetchBRS(0, {
      paied: selectedMethode,
      provider: selectedProvider,
      user: id,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const onChangeDate = (option) => {
    if (option == "5") {
      setSelectedDate(option);
      setDisplayDate(true);
      setDisplayDateRange(false);
      return;
    }
    if (option == "6") {
      setSelectedDate(option);
      setDisplayDateRange(true);
      setDisplayDate(false);
      return;
    }
    setDisplayDate(false);

    setSelectedDate(option);

    fetchBRS(0, {
      paied: selectedMethode,
      provider: selectedProvider,
      user: selectedUser,
      date: option,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const handlePageChange = (page) => {
    fetchBRS(page - 1, {
      paied: selectedMethode,
      provider: selectedProvider,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    });
  };

  const fetchBRS = async (page, options) => {
    setLoading(true);
    setShow(true);
    setTotal(0);
    toGetBonsReception(page, perPage, options).then((data) => {
      setBonsLivraison(data.res);
      setBonsLivraisonAll(data.res);
      setLoading(false);
      setShow(false);
    });
    toGetTotalBrs(options).then((data) => {
      setTotalRows(data.nb);
      setTotalUnpaied(data.totalUnpaied);
      setTotalPaied(data.totalPaied);
      setTotalAll(data.total);
    });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setShow(true);

    toGetBonsReception(page - 1, newPerPage, {
      paied: selectedMethode,
      provider: selectedProvider,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue,
    }).then((data) => {
      setBonsLivraison(data.res);
      setBonsLivraisonAll(data.res);
      setLoading(false);
      setShow(false);
    });

    setPerPage(newPerPage);
    setLoading(false);
  };

  const toUpdateData = (values) => {
    setBonsLivraison(values);
    setSearchValue("");
    setFilterModalVisible(false);
  };

  const onSelectRow = (e) => {
    var selectedOrders = [...brsForFacture];
    var orders = e.selectedRows;
    if (orders.length == 0) {
      selectedOrders = [];
    }
    if (selectedOrders.length == 0) {
      selectedOrders = orders;
    } else if (selectedOrders.length > orders.length) {
      selectedOrders.forEach((e, i) => {
        if (orders.findIndex((bl) => bl.id == e.id) == -1) {
          selectedOrders.splice(i, 1);
        }
      });
    } else if (orders.length > selectedOrders.length) {
      orders.forEach((e, i) => {
        if (selectedOrders.findIndex((bl) => bl.id == e.id) == -1) {
          selectedOrders.push(e);
        }
      });
    }

    var l__total = 0;
    var totalTva = 0;
    selectedOrders.forEach((element) => {
      l__total += element.total;
      totalTva += element.tva;
    });

    setTotal(l__total);
    setTotalTVA(totalTva);
    setbrsForFacture(selectedOrders);
  };

  const creerFacture = () => {
    setCreateFactureModalVisible(true);
    // toCreateFacture(brsForFacture).then(() => {
    //   setbrsForFacture([]);
    //   toGetBonsReception().then((data) => {
    //     setBonsLivraison(data);
    //     setBonsLivraisonAll(data);
    //     setLoading(false);
    //   });
    // });
  };

  const printBr = (e, order) => {
    e.preventDefault();
    var titre1 = true;
    if (e.type == "contextmenu") {
      titre1 = false;
    }

    if (toVerifyIfChecked(order)) {
      toPrintBR(order.id, titre1);
    } else {
      toPrintBRWithEntete(order.id, titre1);
    }
  };
  const inspectFacture = (idFacture) => {
    toGetFactureReception(idFacture).then(
      (facture) => facture && setFactureToInspect(facture)
    );
  };

  const onSearch = (e) => {
    setSearchValue(e);

    setLoading(true);

    fetchBRS(0, {
      paied: selectedMethode,
      provider: selectedProvider,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: e,
    });
  };

  const toVerifyIfChecked = (order) => {
    var length = checkedItems.filter((element) => element.id == order.id)
      .length;
    if (length === 0) {
      return false;
    }
    return true;
  };

  const onAddBl = (bl) => {
    if (brsForFacture.filter((el) => el.id === bl.id).length === 0) {
      setbrsForFacture(brsForFacture.concat(bl));
    }
  };

  const onRemoveBl = (bl) => {
    setbrsForFacture(brsForFacture.filter((blf) => blf.id != bl.id));
    if (brsForFacture.filter((blf) => blf.id != bl.id).length == 0) {
      setCreateFactureModalVisible(false);
    }
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "1rem",
      },
    },
  };

  const toValidateDelivery = (id) => {
    validateDelivery(id, "br").then((res) => {
      fetchBRS(0, {
        paied: selectedMethode,
        provider: selectedProvider,
        user: selectedUser,
        date: selectedDate,
        dateValue: selectedDateValue,
        ref: searchValue,
      });
    });
  };

  const displayFacture = () => {
    var res = true;
    for (let index = 1; index < brsForFacture.length; index++) {
      const element = brsForFacture[index];
      if (
        element.providerName != brsForFacture[index - 1].providerName ||
        null != element.factureRef ||
        null != brsForFacture[0].factureRef
      ) {
        res = false;
      }
    }
    return res && null == brsForFacture[0].factureRef;
  };
  const columns = [
    {
      name: "Fournisseur",
      selector: "providerName",
      sortable: true,
      grow: 1.5,
    },
    {
      name: "Date",
      selector: "createdDate",
      sortable: true,
      grow: 1.25,

      format: (row) => `${toConvertDate(row.createdDate)}`,
    },
    {
      name: "BL fournisseur",
      selector: "blProvider",
      sortable: true,
      format: (br) => (
        <Typography.Link onClick={() => setBrToInspect(br)}>
          {br.blProvider}
        </Typography.Link>
      ),
    },
    {
      name: "Demandé par",
      selector: "demand",
      sortable: true,
    },

    {
      name: "Total",
      selector: "total",
      sortable: true,
      grow: 0.75,

      format: (row) => `${numberFormatCurrency(row.total)}`,
    },
    {
      name: "Chauffeur",
      selector: "driver",
      grow: 0.5,
      format: (bl) =>
        bl.driver == "-" ? (
          "-"
        ) : bl.driverValid ? (
          <Popconfirm
            title={`Voulez vous supprimer la validation de cette livraison?`}
            onConfirm={() => toValidateDelivery(bl.id)}
            okText="Oui"
            cancelText="Non"
            placement="bottom"
          >
            <Typography.Link className={"auto--color-success"}>
              {bl.driver}
            </Typography.Link>
          </Popconfirm>
        ) : (
          <Popconfirm
            title={`Voulez vous valider cette livraison?`}
            onConfirm={() => toValidateDelivery(bl.id)}
            okText="Oui"
            cancelText="Non"
            placement="bottom"
          >
            <Typography.Link className={"auto--color-warning"}>
              {bl.driver}
            </Typography.Link>
          </Popconfirm>
        ),
    },
    {
      name: "Facture",
      selector: "factureRef",
      sortable: true,
    },

    {
      name: "Payé",
      selector: "paied",
      sortable: true,
      grow: 0.75,

      format: (row) =>
        row.paied ? (
          <span className={"auto--color-success"}>Payé</span>
        ) : (
          <span className={"auto--color-danger"}>Non payé</span>
        ),
    },

    {
      name: "Actions",
      selector: "id",

      grow: 1.5,

      format: (order) => (
        <td className="ant-table-cell" style={{ float: "right" }}>
          <Tooltip title="Pour imprimer sans entête" color="#2db7f5">
            <Checkbox onChange={() => toCheck(order)}></Checkbox>
          </Tooltip>
          <Button
            icon={<PrinterOutlined />}
            style={{ marginRight: 1, marginLeft: 5 }}
            onClick={(e) => printBr(e, order)}
            onContextMenu={(e) => printBr(e, order)}
          ></Button>
          <Button
            icon={<InfoCircleTwoTone />}
            style={{ marginRight: 1 }}
            onClick={() => setBrToInspect(order)}
          ></Button>
          <Button
            style={{ marginRight: 1 }}
            disabled={order.facture__reception}
            icon={<EditOutlined />}
            onClick={() => updateBR(order)}
          ></Button>
          <Popconfirm
            title={`Voulez vous vraiment supprimer ce bon de réception?`}
            onConfirm={() => confirmDelete(order.id)}
            okText="Oui"
            cancelText="Non"
          >
            <Button
              disabled={order.facture__reception}
              style={{ marginRight: 10 }}
              icon={<DeleteOutlined />}
            />
          </Popconfirm>

          <Tooltip
            title="Ajouter un livreur"
            color="#58c5cc"
            placement="leftTop"
          >
            <Button
              disabled={
                cookie.load("role") !== "Super Admin" || order.delivered
              }
              type="link"
              onClick={(e) => setDeliveryModal(order)}
              size="large"
              style={{ marginTop: "2vh" }}
              icon={<GrDeliver />}
            ></Button>
          </Tooltip>
        </td>
      ),
    },
  ];

  return (
    <div id="achat">
      <Loading show={show} color="red" showSpinner={true} />
      <Form layout="vertical">
        <Row className="auto--custom-header">
          <Col span="4">
            <Typography.Title level={5}>
              LISTE DES BONS DE RÉCEPTIONS
            </Typography.Title>
          </Col>
          <Col span="3" style={{ textAlign: "right" }} justify="end">
            <Form.Item label="Réf,note,fournisseur...">
              <Input.Search onSearch={onSearch} enterButton />
            </Form.Item>
          </Col>
          <Col span="2">
            <Form.Item label="Crédit">
              <Select
                disabled={show}
                onSelect={onChangeCredit}
                style={{ width: "80%" }}
                defaultValue="2"
              >
                <Option value="2">Tout</Option>
                <Option value="0">Non payé</Option>
                <Option value="1">Payé</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item label="Date">
              <Select
                disabled={show}
                showSearch
                onSelect={onChangeDate}
                style={{ width: "100%" }}
                defaultValue="0"
              >
                <Option value="0">Tout</Option>

                <Option value="1">Hier</Option>
                <Option value="2">Aujourd'hui</Option>
                <Option value="3">Ce mois</Option>
                {/* <Option value="4">Cette année</Option>*/}
                <Option value="5">Date Spécifique</Option>
                <Option value="6">Période Spécifique</Option>
              </Select>
            </Form.Item>
            {displayDate && (
              <DatePicker
                disabled={show}
                style={{ width: "100%" }}
                onChange={(e, date) => {
                  setSelectedDateValue(date);
                  fetchBRS(0, {
                    paied: selectedMethode,
                    provider: selectedProvider,
                    user: selectedUser,
                    date: selectedDate,
                    dateValue: date,
                    ref: searchValue,
                  });
                }}
              />
            )}
            {displayDateRange && (
              <DatePicker.RangePicker
                disabled={show}
                onChange={(e, date) => {
                  setSelectedDateValue(date);
                  fetchBRS(0, {
                    paied: selectedMethode,
                    provider: selectedProvider,
                    user: selectedUser,
                    date: selectedDate,
                    dateValue: date,
                    ref: searchValue,
                  });
                }}
              />
            )}
          </Col>

          <Col span="4">
            <Form.Item label="Fournisseur">
              <Select
                disabled={show}
                showSearch
                onSelect={onChangeFournisseur}
                style={{ width: "80%" }}
                defaultValue="Tout"
              >
                <Option value="Tout">Tout</Option>

                {providers.map((c) => (
                  <Option value={c.s__nom}>{c.s__nom}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item label="Utilisateurs">
              <Select
                disabled={show}
                showSearch
                onSelect={onChangeUsers}
                style={{ width: "80%" }}
                defaultValue="0"
              >
                <Option value="0">Tout</Option>

                {users.map((u) => (
                  <Option value={u.username}>{u.username}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col span="3" style={{ textAlign: "right" }} justify="end">
            <Input.Search onSearch={onSearch} enterButton />
          </Col> */}
          <Col span="2" style={{ textAlign: "right" }}>
            {/* <Button
              type="link"
              onClick={(e) => setFilterModalVisible(true)}
              icon={
                <img
                  src="/images/filterIconVente.svg"
                  style={{ width: "3vh", height: "3vh" }}
                />
              }
              style={{ marginRight: "5px" }}
            ></Button> */}
            <Link key="20" to="/achat/reception/creer">
              <Button
                type="link"
                size="large"
                icon={<img src="/images/plusVente.svg" />}
              ></Button>
            </Link>
            <Tooltip title="Actualiser la liste des brs">
              <Button
                onClick={() => actualise()}
                type="link"
                size="large"
                icon={<img src="/images/refresh.svg" />}
              ></Button>
            </Tooltip>
          </Col>
        </Row>
      </Form>{" "}
      <Row>
        <Col span="24">
          <DataTable
            customStyles={customStyles}
            selectableRows
            noHeader
            striped
            selectableRowsHighlight
            fixedHeader
            onSelectedRowsChange={(e) => onSelectRow(e)}
            persistTableHead
            noDataComponent={<span>Pas de bon de livraisons trouvés</span>}
            contextMessage={{
              singular: "élément",
              plural: "éléments",
              message: "séléctionné(s)",
            }}
            columns={columns}
            data={orders}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
          {total != "0" && (
            <Descriptions
              bordered
              size="small"
              style={{ backgroundColor: "white", marginTop: "3px" }}
            >
              <Descriptions.Item label="Total TTC" className="item-label">
                {totalAll + " DT"}
              </Descriptions.Item>
              <Descriptions.Item label="Total payé" className="item-label">
                {totalPaied + " DT"}
              </Descriptions.Item>
              <Descriptions.Item label="Total Reste" className="item-label">
                {totalUnpaied + " DT"}
              </Descriptions.Item>
            </Descriptions>
          )}
          <TotalBr />
        </Col>
        <FactureReceptionModal
          facture={factureToInspect}
          onClose={() => setFactureToInspect(null)}
        />
        {brsForFacture.length > 0 && (
          <div
            style={{
              position: "fixed",
              width: "100vw",
              height: "60px",
              backgroundColor: "white",
              bottom: 0,
              left: 0,
            }}
          >
            <Row justify="end" style={{ height: 30, margin: "15px 0" }}>
              <Col span={3} style={{ lineHeight: "30px" }}>
                <Tooltip placement="leftTop" title="Remise" color="#58c5cc">
                  <Button
                    type="ghost"
                    onClick={() => setRemiseModal(true)}
                    icon={<PercentageOutlined />}
                  >
                    Remise
                  </Button>
                </Tooltip>
              </Col>

              <Col span={3} style={{ lineHeight: "30px" }}>
                <Button
                  key="creer-print"
                  size="large"
                  type="primary"
                  icon={<PrinterOutlined />}
                  onClick={() => toPrintMultipleBrs()}
                >
                  Imprimer
                </Button>
              </Col>

              <Col span={3} style={{ lineHeight: "30px" }}>
                {displayFacture() && (
                  <Button
                    key="creer-facture"
                    size="large"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => creerFacture()}
                  >
                    Créer facture
                  </Button>
                )}
              </Col>
              <Col span={3} style={{ lineHeight: "30px" }}>
                <Button
                  key="cancel"
                  size="large"
                  type="primary"
                  danger
                  icon={<CloseOutlined />}
                  onClick={() => setbrsForFacture([])}
                >
                  Annuler
                </Button>
              </Col>
              <Col span={4} style={{ lineHeight: "30px" }}>
                {brsForFacture.length + "  bon de réception(s) séléctionné(s)"}
              </Col>
              <Col span={3} style={{ lineHeight: "30px" }}>
                TVA : {totalTVA.toFixed(3)} DT
              </Col>
              <Col span={3} style={{ lineHeight: "30px" }}>
                <span>Total : {total.toFixed(3)} Dt</span>
              </Col>
            </Row>
          </div>
        )}
      </Row>
      <Modal
        className="modal-small"
        title={"Filtrer"}
        visible={filterModalVisible}
        footer={[]}
        onCancel={(e) => setFilterModalVisible(false)}
      >
        <Search toUpdateData={toUpdateData} fields={fields} list={ordersAll} />
      </Modal>
      <BonReceptionModal
        bonreception={brToInspect}
        onClose={() => setBrToInspect(null)}
      />
      <BonReceptionToFacture
        AppStore={AppStore}
        onAddBl={(bl) => onAddBl(bl)}
        onRemoveBl={(bl) => onRemoveBl(bl)}
        setCreateFactureModalVisible={(v) => setCreateFactureModalVisible(v)}
        visible={createFactureModalVisible}
        blsToFacture={brsForFacture}
        bls={ordersAll}
      />
      <Delivery
        visible={null != deliveryModal}
        setDeliveryModal={() => {
          setDeliveryModal(null);
          actualise();
        }}
        type="br"
        ids={deliveryModal != null && [deliveryModal]}
      />
      <Modal
        title="Remise"
        visible={remiseVisible}
        onCancel={() => setRemiseModal(false)}
        onOk={() =>
          toUpdateDiscount(remiseValue, brsForFacture).then((res) => {
            setRemiseModal(false);
            actualise();
          })
        }
      >
        <p>
          Ce remise va etre appliquable sur tout les articles les{" "}
          <b>{brsForFacture.length}</b> bon(s) séléctionné(s)
        </p>
        <InputNumber onChange={(e) => setRemise(e)} />
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBRToUpdate: (PurshaseOrder) => dispatch(setItemsState(PurshaseOrder)),
  };
};

export default connect(null, mapDispatchToProps)(ListeBonReception);
