import { Button, Col, Input, Row, Switch } from "antd";
import React, { useEffect, useState } from "react";
import CAxios from "../Utils/CAxios";
import axios from "axios";

const ArticleSearch = ({ type, placeholder, onFocus, focused, onClick }) => {
  const [positif, setPositif] = useState(false);
  const [perPage, setPerPage] = useState(25);
  const [nb, setNb] = useState(50);
  const [word, setWord] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [toDisplay, setToDisplay] = useState(true);

  const onInputChange = (word) => {
    setWord(word);
  };

  //     useEffect(() => {
  //         //const data =   customFilter(word, articlesNamesAll, ['title', 'ref'], nb);
  //     var first = [];
  //     var others = [];
  //     for (var i = 0; i < articlesNamesAll.length; i++) {
  //       if (articlesNamesAll[i].title.toUpperCase().indexOf(word.toUpperCase()) >= 0) {
  //         if (articlesNamesAll[i].title.toUpperCase().indexOf(word.toUpperCase()) == 0) {
  //           first.push(articlesNamesAll[i]);
  //         } else {
  //           others.push(articlesNamesAll[i]);
  //         }
  //       }

  //       if (articlesNamesAll[i].ref.trim().toUpperCase().indexOf(word.trim().toUpperCase()) >= 0) {
  //         if (articlesNamesAll[i].ref.trim().toUpperCase().indexOf(word.trim().toUpperCase()) == 0) {
  //           first.push(articlesNamesAll[i]);
  //         } else {
  //           others.push(articlesNamesAll[i]);
  //         }
  //       }

  //       if(first.length + others.length >= nb){
  //         break;
  //       }
  //     }
  //     first.sort();
  //     others.sort();

  //     setArticleNames(first.concat(others));

  //   }, [word]);

  useEffect(() => {
    const search = async () => {
      setLoading(true);
      setError(null);

      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      try {
        const { data } = await CAxios.post(
          `/api/articles/all/search`,
          { word, perPage, positif, type },
          {
            cancelToken: source.token,
          }
        );
        setResults(data.success.articles);
        setNb(data.success.nb);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          setError(error.message);
          setLoading(false);
        }
      }

      return () => {
        source.cancel("Request canceled by the user");
      };
    };

    if (word.trim() !== "" && word.length > 2) {
      setToDisplay(true);

      search();
    } else {
      setToDisplay(false);
      setResults([]);
    }
  }, [word, positif, type, perPage]);

  return (
    <>
      <Row gutter={24}>
        <Col span={24}>
          {type === 1 ? (
            <Input.Search
              placeholder={placeholder}
              onInput={(e) => onInputChange(e.target.value)}
              onFocus={() => {
                setToDisplay(true);
                onFocus(type);
              }}
              onSearch={() => {
                onClick(word);
                setToDisplay(false);
              }}
            />
          ) : (
            <Input.Search
              placeholder={placeholder}
              onInput={(e) => onInputChange(e.target.value)}
              onFocus={() => {
                setToDisplay(true);
                onFocus(type);
              }}
            />
          )}
        </Col>
      </Row>
      {focused == type && toDisplay && word !== "" && (
        <div
          className="list--search-overflow"
          style={{
            backgroundColor: "#fff",
            border: "1px solid black",
            position: "fixed",
            zIndex: 2,
            width: "900px",
            maxHeight: "400px",
            overflowY: "auto",
          }}
        >
          <Row gutter={24} justify="center" style={{ marginTop: "5px" }}>
            <Col span={5} justify="center">
              <b> {nb} </b> article(s)
            </Col>
            <Col span={6}>
              <Switch
                style={{ textAlign: "center", display: "flex" }}
                checked={positif}
                onChange={(e) => {
                  setPositif(!positif);
                }}
                checkedChildren={<>S. Positif</>}
                unCheckedChildren={<>S. Tout</>}
                title=""
              ></Switch>
            </Col>

            <Col span={5}>
              {perPage < nb && (
                <Button
                  type="primary"
                  style={{ marginBottom: "20px" }}
                  onClick={() => setPerPage(perPage + 25)}
                >
                  Plus de résultats
                </Button>
              )}
            </Col>
            <Col span={6}>
              <Button
                type="primary"
                style={{ marginLeft: "10px" }}
                onClick={() => setToDisplay(false)}
              >
                Fermer
              </Button>
            </Col>
          </Row>

          <table className="ant-table" style={{ width: "100%", height: "3vh" }}>
            <tbody className="ant-table-tbody">
              {results.map((article, i) => (
                <tr
                  key={i}
                  className={i % 2 != 0 && "auto--background-grey "}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    onClick(article.ref);
                    setToDisplay(false);
                  }}
                >
                  <td className="ant-table-cell">
                    <b style={{ color: "#2696d3", fontSize: "0.75rem" }}>
                      {article.ref}
                    </b>
                  </td>
                  <td className="ant-table-cell">
                    <b style={{ fontSize: "1rem" }}> {article.title}</b>
                  </td>
                  <td className="ant-table-cell">
                    <b style={{ fontSize: "1rem" }}>
                      {" "}
                      {parseFloat(article.prix).toFixed(3)}
                    </b>
                  </td>
                  <td className="ant-table-cell">
                    <span
                      className={
                        article.stock <= 0
                          ? "auto--color-danger"
                          : article.stock < article.seuil
                          ? "auto--color-warning"
                          : "auto--color-success"
                      }
                    >
                      {article.stock ?? 0}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default ArticleSearch;
