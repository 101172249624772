import { Col, Descriptions, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";

import "../../../Styles/TwoTables.scss";
import { toGetPerYear } from "../APIs";

const TotalBr = ({ AppStore, ...props }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    toGetPerYear().then((res) => {
      setData(res);
    });
  };
  return (
    <table className="ant-table" style={{ width: "100%", marginTop: "1rem" }}>
      <thead className="ant-table-thead">
        <tr>
          <th className="ant-table-cell">Année</th>
          <th className="ant-table-cell">Total TTC</th>
          <th className="ant-table-cell">Payé</th>
          <th className="ant-table-cell">Reste</th>
          <th className="ant-table-cell">Nombre BL</th>
        </tr>
      </thead>
      <tbody className="ant-table-tbody">
        {data.map((el, index) => (
          <tr>
            <td className="ant-table-cell">{el.year}</td>
            <td className="ant-table-cell">{el.total + " DT"} </td>
            <td className="ant-table-cell">{el.totalPaied + " DT"}</td>
            <td className="ant-table-cell">{el.totalUnpaied + " DT"}</td>
            <td className="ant-table-cell">{el.nb}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TotalBr;
