import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Switch,
  Table,
} from "antd";
import { useEffect, useState } from "react";
import { getListOfCredit, toAddCredit } from "./Apis";
import cookie from "react-cookies";
import moment from "moment";
import dayjs from "dayjs";

const Credits = ({ id }) => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [montant, setMontant] = useState(0);
  const [monthNumber, setMonthNumber] = useState(1);
  const [echeances, setEchenaces] = useState([
    {
      montant: 0,
      month: moment().add(1, "M").startOf("month").format("YYYY-MM"),
    },
  ]);
  const [automatic, setIsAutomatic] = useState(true);
  const [selected, setSelected] = useState(null);
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    setLoading(true);

    getListOfCredit(id).then((res) => {
      setLoading(false);
      setData(res);
    });
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Montant",
      dataIndex: "montant",
      key: "montant",
    },
    {
      title: "Reste",
      dataIndex: "",
      key: "y",
      render: (text, selected) => (
        <>
          {selected?.montant -
            selected?.echeances
              .filter((el) => el.status == 1)
              .reduce((a, b) => a + b.montant, 0)}
        </>
      ),
    },
    {
      title: "Période",
      dataIndex: "period",
      key: "period",
    },

    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (el) => (
        <Popconfirm
          title={`Voulez vous vraiment supprimer cet enregistrement?`}
          //onConfirm={() => deleteCongé(el.id)}
          okText="Oui"
          cancelText="Non"
          placement="left"
        >
          <Button
            type="link"
            icon={
              <img src="/images/trash.svg" className="auto--icon-list"></img>
            }
          ></Button>
        </Popconfirm>
      ),
    },
  ];

  const rowClassName = (selected) => {
    // Conditional styling based on the 'status' field
    if (
      selected?.montant -
        selected?.echeances
          .filter((el) => el.status == 1)
          .reduce((a, b) => a + b.montant, 0) ==
      0
    ) {
      return "selected-line-item-vente";
    }
    return "inactive-row";
  };

  const onChangeMontant = (e) => {
    setMontant(e);
    var list = [...echeances];
    for (var i = 0; i < list.length; i++) {
      list[i].montant = e / monthNumber;
    }
    setEchenaces(list);
  };
  const onChangeMonth = (e) => {
    setMonthNumber(e);
    var date = moment(new Date());
    var list = [];
    for (var i = 1; i <= e; i++) {
      date.add(1, "M").startOf("month");
      list.push({ month: date.format("YYYY-MM"), montant: montant / e });
    }
    setEchenaces(list);
  };

  const toSaveData = () => {
    var json = {
      montant,
      echeances,
      monthNumber,
      id: id,
    };

    toAddCredit(json).then((res) => {
      setOpen(false);
      setMonthNumber(0);
      setMontant(0);
      setEchenaces([
        {
          montant: 0,
          month: moment().add(1, "M").startOf("month").format("YYYY-MM"),
        },
      ]);
      getData();
    });
  };
  const onChangeEcheanceItem = (month, montant, i) => {
    const list = [...echeances];
    list[i].month = month;
    list[i].montant = montant;
    setEchenaces(list);
  };

  const onChangeAutomatic = (e) => {
    if (e) {
      var date = moment(new Date());
      var list = [];
      for (var i = 1; i <= monthNumber; i++) {
        date.add(1, "M").startOf("month");
        list.push({
          month: date.format("YYYY-MM"),
          montant: montant / monthNumber,
        });
      }
      setEchenaces(list);
    }

    setIsAutomatic(e);
  };
  return (
    <>
      <Card
        className="smtv--card-background"
        type="inner"
        bordered
        title={<h4 className="text-white">Crédits</h4>}
        extra={
          (cookie.load("id") === "8" ||
            cookie.load("id") == "18" ||
            cookie.load("id") == "1") && (
            <Button
              onClick={() => setOpen(true)}
              type="link"
              icon={
                <img src="/images/add.svg" className="auto--icon-list"></img>
              }
            ></Button>
          )
        }
      >
        <Table
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                setSelected(record);
              }, // click row
            };
          }}
          rowClassName={rowClassName}
          loading={loading}
          columns={columns}
          dataSource={data}
        />
      </Card>
      <Modal
        title="Ajouter un crédit"
        visible={open}
        onCancel={() => setOpen(false)}
        onOk={() => toSaveData()}
      >
        <Form>
          <table>
            <tr>
              <td>
                <Form.Item label="Montant">
                  <InputNumber
                    onChange={(e) => onChangeMontant(e)}
                    placeholder="montant"
                    value={montant}
                  />
                </Form.Item>
              </td>
              <td>
                <Form.Item label="Automatique">
                  <Switch
                    checked={automatic}
                    onChange={(e) => onChangeAutomatic(e)}
                  />
                </Form.Item>
              </td>
              <td>
                <Form.Item label="Nombre de mois">
                  <InputNumber
                    onChange={(e) => onChangeMonth(e)}
                    placeholder="mois"
                    value={monthNumber}
                  />
                </Form.Item>
              </td>
              <td>
                <Form.Item label="Reste">
                  <b>
                    {montant -
                      echeances.reduce((a, b) => a + b.montant, 0).toFixed(3)}
                  </b>
                </Form.Item>
              </td>
            </tr>
          </table>
        </Form>
        {echeances.map((el, i) => (
          <Row>
            <Col span={12}>
              <DatePicker
                defaultValue={dayjs(el.month + "-01", "YYYY-MM-DD")}
                picker="month"
                onChange={(e, date) =>
                  onChangeEcheanceItem(date, el.montant, i)
                }
              ></DatePicker>
            </Col>
            <Col span={12}>
              <InputNumber
                value={el.montant}
                onChange={(e) => onChangeEcheanceItem(el.month, e, i)}
                disabled={automatic}
              />
            </Col>
          </Row>
        ))}
      </Modal>

      <Modal
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
        title="Liste des échéances"
        visible={selected != null}
        onCancel={() => setSelected(null)}
        onOk={() => setSelected(null)}
      >
        <h5>
          <b>
            Reste:{" "}
            {(
              selected?.montant -
              selected?.echeances
                .filter((el) => el.status == 1)
                .reduce((a, b) => a + b.montant, 0)
            ).toFixed(3)}
          </b>
        </h5>
        <table className="ant-table">
          <tbody className="ant-table-tbody">
            {selected?.echeances.map((el) => (
              <tr
                className={
                  el.status == 1
                    ? "ant-table-cell auto--background-rembourssement"
                    : "ant-table-cell"
                }
              >
                <td>{el.date}</td>
                <td>{el.montant}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal>
    </>
  );
};

export default Credits;
